import { useMediaQuery } from "react-responsive";

const MapIcon = () => {
  const isTablet = useMediaQuery({ query: "(min-width:768px)" });
  const isDesktop = useMediaQuery({ query: "(min-width:992px)" });
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={isDesktop ? "36" : isTablet ? "30" : "25"}
      height={isDesktop ? "36" : isTablet ? "30" : "25"}
      fill="currentColor"
      className="bi bi-geo-alt-fill"
      viewBox="0 0 16 16"
    >
      <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
    </svg>
  );
};
export default MapIcon;
