import { Comment } from "src/interfaces";
import apiWebify from "../api.webify";
import authHeader from "./auth-header";

export const apiCommentMap = (c: Comment) => ({
  id: c.id,
  fullname: c.fullname,
  email: c.email,
  contentUrl: c.contentUrl,
  content: c.content,
  skill: c.skill,
  online: c.online,
  createdAt: c.createdAt,
  lang: c.lang,
});

const getComments = (page: number, per_page: number, lang: string) => {
  return apiWebify
    .get(`comments?page=${page}&itemsPerPage=${per_page}&lang=${lang}`)
    .then((res) => res.data)
    .then((comments) => {
      return {
        data: comments["hydra:member"].map(apiCommentMap),
        totalRows: comments["hydra:totalItems"],
      };
    });
};

const getPublishComments = (page: number, per_page: number, lang: string) => {
  return apiWebify
    .get(
      `comments?page=${page}&itemsPerPage=${per_page}&online=true&lang=${lang}`
    )
    .then((res) => res.data["hydra:member"].map(apiCommentMap));
};

const addComment = (comment: any) => {
  console.log(comment);
  return apiWebify
    .post("comments", comment)
    .then((res) => apiCommentMap(res.data));
};

const removeComment = (id: number) => {
  return apiWebify.delete(`comments/${id}`, {
    headers: authHeader(),
  });
};

const removeSelectedComments = (selected: number[]) => {
  return Promise.all(selected.map((id) => removeComment(id)));
};

const editComment = (comment: Comment, id: number) => {
  return apiWebify
    .put(`comments/${id}`, comment, { headers: authHeader() })
    .then((res) => res.data);
};

const getCountComments = (period: string) => {
  return apiWebify
    .get(`comments/count?period=${period}`)
    .then((res) => res.data);
};

const CommentService = {
  getComments,
  getPublishComments,
  addComment,
  removeComment,
  removeSelectedComments,
  editComment,
  getCountComments,
};

export default CommentService;
