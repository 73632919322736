import { I18n } from "react-redux-i18n";
import styledComponents from "styled-components";
import { CancelButton, DangerButton, Head3, Text } from ".";
import CloseIcon from "../assets/icons/CloseIcon";

interface ModalDeleteProps {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  onConfirm: () => void;
}
const Modal = styledComponents.div`
  z-index: 1000;
  height: 100vh;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.2);
  overflow-y: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ModalContent = styledComponents.div`
background: white;
max-width:400px;
margin: 1rem;
border: 2px solid rgb(245, 245, 245);
border-radius: 5px;
display: flex;
flex-direction: column;
gap: 2rem;
position:relative;
padding:0.5rem 3rem;
`;
const ModalActions = styledComponents.div`
padding:3rem 0;
display:flex;
flex-direction:column;
align-items:center;
gap:2rem;
`;
const BtnClose = styledComponents.button`
max-width:15px;
position:absolute;
right:0;
border:0;
background:transparent;
cursor:pointer;
`;
const ModalFooter = styledComponents.div`
display: flex;
justify-content: flex-end;
gap: 1rem;
`;
const ModalDelete: React.FC<ModalDeleteProps> = ({
  setShowModal,
  onConfirm,
}) => {
  return (
    <Modal>
      <ModalContent>
        <BtnClose onClick={() => setShowModal(false)}>
          <CloseIcon />
        </BtnClose>
        <ModalActions>
          <Head3>{I18n.t("modalDelete.title")}</Head3>
          <Text>{I18n.t("modalDelete.msg")}</Text>
          <ModalFooter>
            <CancelButton onClick={() => setShowModal(false)}>
              {I18n.t("modalDelete.cancel")}
            </CancelButton>
            <DangerButton onClick={onConfirm}>
              {I18n.t("modalDelete.delete")}
            </DangerButton>
          </ModalFooter>
        </ModalActions>
      </ModalContent>
    </Modal>
  );
};
export default ModalDelete;
