import styled from "styled-components";
import { ResponsiveProps } from "src/interfaces";

const Title = styled.h1<ResponsiveProps>`
  font-family: "be_vietnamextrabold";
  text-transform: uppercase;
  font-size: 4.6rem;
  ${({ theme }) => theme.device.tablet} {
    ${({ theme, responsive }) => responsive && theme.title.tabletStyle}
  }
  ${({ theme }) => theme.device.desktop} {
    ${({ theme, responsive }) => responsive && theme.title.desktopStyle}
  }
  ${({ theme }) => theme.title.style};
`;
export default Title;
