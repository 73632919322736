import merge from "deepmerge";
import { Theme } from "./interfaces";
type ThemeMapping = {
  [propertyName: string]: Theme;
};
export const defaultThemes: ThemeMapping = {
  default: {
    breakpoints: {
      tablet: "768px",
      desktop: "992px",
    },
    background: {
      default: "#FFFFFF",
      primary: "rgba(249, 250, 251, 0.87)",
      secondary: "rgb(18, 140, 205)",
      tertiary: "rgba(245, 245, 245, 0.87)",
      quaternary: "rgba(240, 240, 240, 1)",
      quinary: "#e5eefa",
      sidebar: "rgba(226, 236, 250, 0.87)",
    },
    primary: "rgba(250, 171, 0, 0.87)",
    primarydashboard: "rgba(4, 108, 205, 0.87)",
    text: {
      default: "rgba(11, 39, 81, 0.87)",
      primary: "rgba(219, 218, 218,.87)",
      secondary: "rgba(8, 140, 203, 0.87)",
      tertiary: "rgba(4, 108, 205, .78)",
      quinary: "rgba(4,108,205,0.87)",
      quaternary: "rgba(245, 245, 245, 1)",
      danger: "rgba(168, 50, 50, 0.87)",
      input: "rgba(115, 119, 125, 1)",
      sidebar: "rgba(145, 169, 201, 0.87)",
      hover: "rgba(250, 171, 0, 0.87)",
      focus: "#FFFFFF",
      disabled: "rgba(0, 0, 0, 0.8)",
    },
    shadow: {
      menu: "5px 5px 25px -18px #000000",
      box: "2px 2px 12px -18px #000000",
      card: "2px 1px 25px -20px #000000",
    },
    button: {
      default: "rgba(250, 171, 0, .87)",
      light: "rgba(255,255,255,.12)",
      focus: "rgba(255,255,255,.12)",
      hover: "rgba(255,255,255,.12)",
      disabled: "rgba(0, 0, 0, .18)",
      secondary: "rgba(4, 108, 205, 0.87)",
    },
    dangerbutton: {
      default: "rgba(168, 50, 50, 0.87)",
      focus: "rgba(255,255,255,.12)",
      hover: "rgba(255,255,255,.12)",
      disabled: "rgba(0, 0, 0, .18)",
    },
    input: {
      default: "#FFFFFF",
      primary: "rgba(245, 245, 245, 1)",
      secondary: "rgba(219, 218, 218, 0.87)",
      error: "rgba(168, 50, 50, .87)",
      errorServer: "rgba(250, 171, 0, .87)",
    },
    inputButton: {
      default: "rgba(245, 245, 245, 1)",
      focus: "rgba(4, 108, 205, 0.87)",
    },
    divider: {
      default: "rgba(250, 171, 0, 0.87)",
    },
    loader: {
      borderColor: "rgba(219, 218, 218, .87)",
      borderLeftColor: "rgba(11, 39, 81, 0.87)",
    },
    filter: {
      default: "rgba(249, 250, 251, 0.87)",
      focus: "rgba(4, 108, 205, 0.87)",
    },
    icon: {
      default: "rgba(219, 218, 218, 0.87)",
      primary: "rgba(249, 250, 251, 0.87)",
      secondary: "rgba(115, 119, 125, 1)",
      active: "rgba(250, 171, 0, 0.87)",
      disabled: "#f6f6f6",
    },
    socialMedia: {
      default: "rgba(7, 133, 204, 0.87)",
      primary: "rgba(219, 218, 218, 0.87)",
    },

    context: {
      background: "#e3f2fd",
      text: "rgba(0, 0, 0, 0.87)",
    },
    sortFocus: {
      default: "rgba(0, 0, 0, .54)",
    },
    selected: {
      default: "#e3f2fd",
      text: "rgba(0, 0, 0, 0.87)",
    },
    highlightOnHover: {
      default: "#EEEEEE",
      text: "rgba(0, 0, 0, 0.87)",
    },
    striped: {
      default: "#FAFAFA",
      text: "rgba(0, 0, 0, 0.87)",
    },
  },
  dark: {
    breakpoints: {
      tablet: "768px",
      desktop: "992px",
    },
    background: {
      default: "#FFFFFF",
      primary: "rgba(249, 250, 251, 0.87)",
      secondary: "rgb(18, 140, 205)",
      tertiary: "rgb(245, 245, 245)",
      quaternary: "rgba(245, 245, 245, 1)",
      quinary: "rgba(4, 108, 205, 0.87)",
      sidebar: "rgba(226, 236, 250, 0.87)",
    },
    primary: "rgba(250, 171, 0, 0.87)",
    primarydashboard: "rgba(4, 108, 205, 0.87)",
    text: {
      default: "rgba(11, 39, 81, 0.87)",
      primary: "rgba(219, 218, 218,.87)",
      secondary: "rgba(8, 140, 203, 0.87)",
      tertiary: "rgba(4, 108, 205, .78)",
      quinary: "rgba(4,108,205,0.87)",
      quaternary: "",
      danger: "rgba(168, 50, 50, 0.87)",
      input: "rgba(115, 119, 125, 1)",
      sidebar: "rgba(145, 169, 201, 0.87)",
      hover: "rgba(250, 171, 0, 0.87)",
      focus: "#FFFFFF",
      disabled: "rgba(0, 0, 0, 0.8)",
    },
    shadow: {
      menu: "5px 5px 25px -18px #000000",
      box: "2px 2px 12px -18px #000000",
      card: "2px 1px 25px -20px #000000",
    },
    button: {
      default: "rgba(250, 171, 0, .87)",
      light: "rgba(255,255,255,.12)",
      focus: "rgba(255,255,255,.12)",
      hover: "rgba(255,255,255,.12)",
      disabled: "rgba(0, 0, 0, .18)",
      secondary: "rgba(4, 108, 205, 0.87)",
    },
    dangerbutton: {
      default: "rgba(168, 50, 50, 0.87)",
      focus: "rgba(255,255,255,.12)",
      hover: "rgba(255,255,255,.12)",
      disabled: "rgba(0, 0, 0, .18)",
    },
    input: {
      default: "#FFFFFF",
      primary: "rgba(245, 245, 245, 1)",
      secondary: "rgba(219, 218, 218, 0.87)",
      error: "rgba(168, 50, 50, .87)",
      errorServer: "rgba(250, 171, 0, .87)",
    },
    inputButton: {
      default: "rgba(245, 245, 245, 1)",
      focus: "rgba(4, 108, 205, 0.87)",
    },
    divider: {
      default: "rgba(250, 171, 0, 0.87)",
    },
    loader: {
      borderColor: "rgba(219, 218, 218, .87)",
      borderLeftColor: "rgba(11, 39, 81, 0.87)",
    },
    filter: {
      default: "rgba(249, 250, 251, 0.87)",
      focus: "rgba(4, 108, 205, 0.87)",
    },
    icon: {
      default: "rgba(219, 218, 218, 0.87)",
      primary: "rgba(249, 250, 251, 0.87)",
      secondary: "rgba(115, 119, 125, 1)",
      active: "rgba(250, 171, 0, 0.87)",
      disabled: "#f6f6f6",
    },
    socialMedia: {
      default: "rgba(7, 133, 204, 0.87)",
      primary: "rgba(219, 218, 218, 0.87)",
    },

    context: {
      background: "#e3f2fd",
      text: "rgba(0, 0, 0, 0.87)",
    },
    sortFocus: {
      default: "rgba(0, 0, 0, .54)",
    },
    selected: {
      default: "#e3f2fd",
      text: "rgba(0, 0, 0, 0.87)",
    },
    highlightOnHover: {
      default: "#EEEEEE",
      text: "rgba(0, 0, 0, 0.87)",
    },
    striped: {
      default: "#FAFAFA",
      text: "rgba(0, 0, 0, 0.87)",
    },
  },
};
export function createTheme(name = "default", customTheme: any): Theme {
  defaultThemes[name] = merge(defaultThemes.default, customTheme || {});

  return defaultThemes[name];
}
