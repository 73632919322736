import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import environment from "src/environment/environment";

export const rootDirectory: string = environment.apiUrl;

const apiWebify: AxiosInstance = axios.create({
  baseURL: `${rootDirectory}/api/`,
});

apiWebify.interceptors.request.use(
  (req: AxiosRequestConfig) => {
    if (req.headers) {
      req.headers["Accept"] = "application/ld+json";
      req.headers["Content-Type"] = "application/ld+json";
    } else {
      req.headers = {
        Accept: "application/ld+json",
        ContentType: "application/ld+json",
      };
    }
    return req;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default apiWebify;

/* export const apiInputMap = (c) => ({
  id: c.id,
  name: c.name,
  value: c["@id"],
}); */
