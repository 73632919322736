import { useDispatch, useSelector } from "react-redux";
import { supportedLocales } from "../i18next";
import { setLocaleWithFallback } from "../store/actions";
import styled, { css } from "styled-components";
import { Translate } from "react-redux-i18n";
import { RootState } from "src/interfaces";
import { ToggleButton } from "./header-dashboard/ToggleButton";
import { DropdownList } from "./header-dashboard/DropdownList";
import { DropdownItem } from "./header-dashboard/DropdownItem";
import { DropdownItemContent } from "./header-dashboard/DropdownItemContent";
import { FlagIcon } from "src/assets/icons";
import TunisieFlag from "../assets/images/tunisie.png";
import FrenchFlag from "../assets/images/france.png";
import EnglishFlag from "../assets/images/royaume-uni.png";
interface InputButtonProps {
  active: boolean;
}

const Switcher = styled.div`
  position: relative;
  display: inline-block;
`;
const InputButton = styled.button<InputButtonProps>`
  display: block;
  font-family: "be_vietnammedium";
  font-size: 1.4rem;
  border: 0;
  transition: all 100ms linear;
  cursor: pointer;
  width: 100%;
  background: transparent;
  color: #91a9c9;
  padding: 1rem;
  &:hover {
    background: rgb(245, 245, 245);
  }
  ${({ theme }) => theme.languageSwitcherMenuItemLink.style}
  ${({ theme }) => theme.device.tablet} {
    ${({ theme }) => theme.languageSwitcherMenuItemLink.tabletStyle}
  }
  ${({ theme }) => theme.device.desktop} {
    ${({ theme }) => theme.languageSwitcherMenuItemLink.desktopStyle}
  }
  ${({ active }) =>
    active &&
    css`
      ${({ theme }) => theme.inputButton.styleActive}
      color: rgba(4, 108, 205, 0.87);
      background: #e5eefa;
    `}
`;
interface LanguageSwitcherProps {
  open: boolean;
  toggleShowMenu: () => void;
}

const LanguageSwitcher = ({ open, toggleShowMenu }: LanguageSwitcherProps) => {
  const locale = useSelector((state: RootState) => state?.i18n?.locale);
  const Flags = [FrenchFlag, EnglishFlag, TunisieFlag];
  const dispatch = useDispatch();
  const handleChangeLanguage = (e: React.MouseEvent, code: string) => {
    e.preventDefault();
    dispatch(setLocaleWithFallback(code));
    toggleShowMenu();
  };
  return (
    <Switcher>
      <ToggleButton active={open} onClick={toggleShowMenu}>
        {locale}
      </ToggleButton>
      <DropdownList active={open} lang={locale}>
        {Object.keys(supportedLocales).map((code, index) => (
          <DropdownItem
            key={code}
            active={code === locale}
            onClick={(e) => handleChangeLanguage(e, code)}
          >
            <DropdownItemContent>
              <ToggleButton active={false}>
                <FlagIcon src={Flags[index]} alt="tunisie" />
              </ToggleButton>
              <Translate value={supportedLocales[code]} />
            </DropdownItemContent>
          </DropdownItem>
        ))}
      </DropdownList>
    </Switcher>
  );
};
export default LanguageSwitcher;
