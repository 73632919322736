import { ResponsiveProps } from "src/interfaces";
import styledComponents from "styled-components";

const Question = styledComponents.p<ResponsiveProps>`
font-family: "open_sanssemibold";
font-size: 1.6rem;
line-height: 1;
margin: 0;
display:flex;
gap:.5rem;
${({ theme }) => theme.question.style}
${({ theme }) => theme.device.tablet}{
  ${({ theme, responsive }) => responsive && theme.question.tabletStyle}
}
${({ theme }) => theme.device.desktop}{
  ${({ theme, responsive }) => responsive && theme.question.desktopStyle}
}
`;
export default Question;
