import { useEffect } from "react";
import styledComponents, { css } from "styled-components";

interface InputToButtonProps {
  field: any;
  errors?: string;
  touched?: boolean;
  handleToggleDisableNextBtn: (value: boolean) => void;
  responsive?: string;
}

export const StyledInput = styledComponents.input<InputToButtonProps>`
font-family: "robotoregular";
font-size: 1.6rem;
padding: .6rem;
border: 2.56px solid transparent;
border-radius: 0.64rem;
width: 100%;
height: 100%;
appearance: none;
outline: 0;
cursor: pointer;
&::before {
  content: attr(label);
  display: inline-block;
  width: 100%;
}
${({ theme }) => theme.inputButton.style}
${({ theme }) => theme.device.tablet}{
${({ theme, responsive }) => responsive && theme.inputButton.tabletStyle}
}
${({ theme }) => theme.device.desktop}{
  ${({ theme, responsive }) => responsive && theme.inputButton.desktopStyle}
}

${({ field, errors, touched }) => {
  return field.checked
    ? css`
        ${({ theme }) => theme.inputButton.styleActive}
      `
    : errors && touched
    ? css`
        ${({ theme }) => theme.inputButton.styleError}
      `
    : css`
        ${({ theme }) => theme.inputButton.styleDefault}
      `;
}}

`;

const InputToButton = ({
  field,
  form: { errors, touched },
  responsive,
  ...props
}: InputToButtonProps & { field: any; form: any }) => {
  return (
    <>
      <StyledInput
        field={field}
        errors={errors[field.name]}
        touched={touched[field.name]}
        responsive={responsive}
        {...field}
        {...props}
      />
    </>
  );
};
export default InputToButton;
