import styled, { css } from "styled-components";

interface TextareaProps {
  error?: string;
  touched?: boolean;
  responsive?: boolean;
}
const StyledTextarea = styled.textarea<TextareaProps>`
  position: relative;
  font-family: "robotoregular";
  color: rgba(115, 119, 125, 1);
  font-size: 1.6rem;
  line-height: 1;
  width: 100%;
  padding: 1rem 2.5rem;
  border-radius: 50px;
  border: 2px solid transparent;
  outline: 0;

  ${({ theme }) => theme.textarea.style}
  ${({ theme }) => theme.device.tablet} {
    ${({ theme, responsive }) => responsive && theme.textarea.tabletStyle}
  }
  ${({ theme }) => theme.device.desktop} {
    ${({ theme, responsive }) => responsive && theme.textarea.desktopStyle}
  }
  ${({ error, touched }) =>
    error && touched
      ? css`
          ${({ theme }) => theme.textarea.errorStyle};
        `
      : null}
`;

const Textarea = ({
  field,
  form: { touched, errors },
  ...props
}: TextareaProps & { field: any; form: any }) => {
  return (
    <StyledTextarea
      type="textarea"
      error={errors[field.name]}
      touched={touched[field.name]}
      {...field}
      {...props}
    />
  );
};
export default Textarea;
