import { createSelector } from "reselect";
import { RootState } from "src/interfaces";
import { VisibilityFilter } from "./actions";

export const filterTypeQuestionsData = (state: RootState) =>
  state.questionsDevis.filter;

export const typeQuestionSelector = (state: RootState) => state.questionsType;

export const typeQuestionListSelector = createSelector(
  [typeQuestionSelector],
  (questionsType) => (questionsType ? questionsType.data : null)
);

export const filteredTypeQuestionsSelector = createSelector(
  [filterTypeQuestionsData, typeQuestionListSelector],
  (filter, questionsType) => {
    if (filter && questionsType) {
      return questionsType.filter((q: any) => q.slug === filter);
    }
  }
);

export const filterPublishProjectsSelector = (state: RootState) => state.filter;
export const publishProjectsSelector = (state: RootState) =>
  state.publishProjects;

export const publishProjectsListSelector = createSelector(
  [publishProjectsSelector],
  (publishProjects) => (publishProjects ? publishProjects.data : null)
);

export const filteredPublishProjectsDataSelector = createSelector(
  [filterPublishProjectsSelector, publishProjectsListSelector],
  (filter, publishProjects) => {
    if (filter && publishProjects) {
      switch (filter) {
        case VisibilityFilter.SHOW_SHOWCASE: {
          return publishProjects.filter(
            (p) => p.projectCategory.target === VisibilityFilter.SHOW_SHOWCASE
          );
        }
        case VisibilityFilter.SHOW_ECOMMERCE: {
          return publishProjects.filter(
            (p) => p.projectCategory.target === VisibilityFilter.SHOW_ECOMMERCE
          );
        }
        case VisibilityFilter.SHOW_MOBILE_APPS: {
          return publishProjects.filter(
            (p) =>
              p.projectCategory.target === VisibilityFilter.SHOW_MOBILE_APPS
          );
        }
        case VisibilityFilter.SHOW_TAILOR: {
          return publishProjects.filter(
            (p) => p.projectCategory.target === VisibilityFilter.SHOW_TAILOR
          );
        }
        default: {
          const filter = [4, 3, 2, 1, 0];
          return publishProjects.filter((p, i) => filter.includes(i));
        }
      }
    }
  }
);
