import { Compagny, Input } from "src/interfaces";
import apiWebify from "../api.webify";

export const apiCompagnyMap = (c: Compagny) => ({
  id: c.id,
  name: c.name,
});
const getCompagnies = () => {
  return apiWebify
    .get("compagnies")
    .then((res) => res.data["hydra:member"].map(apiCompagnyMap));
};

export const apiInputCompagnyMap = (c: Compagny): Input => ({
  id: c.id,
  name: c.name,
  value: c["@id"],
  type: "radio",
  inputName: "compagny",
});
const getCompagniesInput = (lang: string) => {
  return apiWebify
    .get("compagnies?lang=" + lang)
    .then((res) => res.data["hydra:member"].map(apiInputCompagnyMap));
};

const CompagnyService = { getCompagnies, getCompagniesInput };
export default CompagnyService;
