import { Media } from "src/interfaces";
import apiWebify from "../api.webify";
import authHeader from "./auth-header";

export const apiMediasMap = (m: Media) => ({
  contentUrl: m.contentUrl,
});

const getMedias = () => {
  return apiWebify.get("media_objects").then((res) => res.data["hydra:member"]);
};

const getMediasByIds = (ids: number[]) => {
  const filter = ids.map((id, i) =>
    i === ids.length - 1 ? "id=" + id : "id=" + id + "&"
  );
  return apiWebify
    .get("media_objects?" + filter)
    .then((res) => res.data["hydra:member"].map(apiMediasMap));
};

const addMedia = (media: any) => {
  return apiWebify.post(`media_objects`, media, { headers: authHeader() });
};

const removeMedia = (id: number) => {
  return apiWebify.delete(`media_objects/${id}`, { headers: authHeader() });
};
const MediasService = { getMedias, getMediasByIds, addMedia, removeMedia };
export default MediasService;
