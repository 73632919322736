import { useDispatch, useSelector } from "react-redux";
import { I18n } from "react-redux-i18n";
import { useHistory } from "react-router-dom";
import PeopleIcon from "src/assets/icons/PeopleIcon";
import { RootState } from "src/interfaces";
import styled, { css } from "styled-components";
import { Text } from ".";
import LogoutIcon from "../assets/icons/LogoutIcon";
import { logoutUserAction } from "../store/actions";
import { DropdownItem } from "./header-dashboard/DropdownItem";
import { DropdownItemContent } from "./header-dashboard/DropdownItemContent";
import { DropdownList } from "./header-dashboard/DropdownList";
import { ToggleButton } from "./header-dashboard/ToggleButton";

const DropdownProfilContainer = styled.div`
  position: relative;
  display: inline-block;
`;

type DropdownProfilButtonProps = {
  open: boolean;
};
const DropdownProfilButton = styled.button<DropdownProfilButtonProps>`
  font-family: "be_vietnamextrabold";
  width: 40px;
  height: 40px;
  background: #f3f6fb;
  font-size: 1.2rem;
  border: none;
  border-radius: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  cursor: pointer;
  ${({ open }) =>
    open
      ? css`
          ${({ theme }) => theme.dropdownProfilButton.styleActive}
        `
      : css`
          ${({ theme }) => theme.dropdownProfilButton.styleInactive}
        `}
  ${({ theme }) => theme.dropdownProfilButton.style};
`;

const DropdownProfilListItem = styled.li`
  padding: 1rem;
  cursor: pointer;
  border: 1px solid;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  &:last-child {
    border-bottom: none;
  }
  ${({ theme }) => theme.dropdownProfilListItem.style}
  ${({ theme }) => theme.device.tablet} {
    ${({ theme }) => theme.dropdownProfilListItem.tabletStyle}
  }
  ${({ theme }) => theme.device.desktop} {
    ${({ theme }) => theme.dropdownProfilListItem.desktopStyle}
  }
`;
const TextDropdown = styled.p`
  font-size: 1.4rem;
`;
const LogoutButton = styled.a`
  background: transparent;
  border: 0;
`;

type DropdownProfilProps = {
  open: boolean;
  handleOpen: () => void;
  username: string;
};

const DropdownProfil = ({
  open,
  handleOpen,
  username,
}: DropdownProfilProps) => {
  const lang = useSelector((state: RootState) => state?.i18n?.locale);
  const dispatch = useDispatch();
  const history = useHistory();
  const handleLogout = () => {
    dispatch(logoutUserAction());
    history.push("/home");
  };
  const firstLetter = username ? username.charAt(0) : "";
  return (
    <DropdownProfilContainer>
      <ToggleButton onClick={handleOpen} active={open}>
        {firstLetter}
      </ToggleButton>
      <DropdownList active={open} lang={lang}>
        <DropdownItem active={false}>
          <DropdownItemContent>
            <ToggleButton active={false}>
              <PeopleIcon />
            </ToggleButton>
            <TextDropdown>{username}</TextDropdown>
          </DropdownItemContent>
        </DropdownItem>
        <DropdownItem onClick={handleLogout} active={false}>
          <DropdownItemContent>
            <ToggleButton active={false}>
              <LogoutButton>
                <LogoutIcon />
              </LogoutButton>
            </ToggleButton>
            <TextDropdown> {I18n.t("signout")} </TextDropdown>
          </DropdownItemContent>
        </DropdownItem>
      </DropdownList>
    </DropdownProfilContainer>
  );
};
export default DropdownProfil;
