import { Input, ProjectCategory } from "src/interfaces";
import apiWebify from "../api.webify";

export const apiProjectCategoriesMap = (c: ProjectCategory) => ({
  id: c.id,
  name: c.name,
  value: c["@id"],
  target: c.target,
});
export const apiInputProjectCategoriesMap = (c: ProjectCategory): Input => ({
  id: c.id,
  value: c["@id"],
  name: c.name,
  type: "radio",
  inputName: "projectCategory",
});
export const getProjectCategories = (lang: string) => {
  return apiWebify
    .get("project_categories?lang=" + lang)
    .then((res) => res.data["hydra:member"].map(apiProjectCategoriesMap));
};
export const getProjectCategoriesInput = (lang: string) => {
  return apiWebify
    .get("project_categories?lang=" + lang)
    .then((res) => res.data["hydra:member"].map(apiInputProjectCategoriesMap));
};

const CategoriesProjectService = {
  getProjectCategories,
  getProjectCategoriesInput,
};

export default CategoriesProjectService;
