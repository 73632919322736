import styled from "styled-components";

const Section = styled.section`
  ${({ theme }) => theme.sectionSpacer.style};
  ${({ theme }) => theme.device.tablet} {
    ${({ theme }) => theme.sectionSpacer.tabletStyle}
  }
  ${({ theme }) => theme.device.desktop} {
    ${({ theme }) => theme.sectionSpacer.desktopStyle}
  }
`;
export default Section;
