import { Field, Formik } from "formik";
import { ButtonSecondary } from "../../components";
import FileInput from "./FileInput";
import ModalContent from "./modal-upoad/ModalContent";
import ModalFooter from "./modal-upoad/ModalFooter";
import ModalHeader from "./modal-upoad/ModalHeader";
import WrapperModal from "./modal-upoad/WrapperModal";
type UploadModalProps = {
  onUpload: (id: any, formData: any) => void;
  id: any;
};
const UploadModal = ({ onUpload, id }: UploadModalProps) => {
  const submit = (values: any) => {
    values.imageAlt = values.file.name.split(".")[0];
    let formData = new FormData();
    for (let value in values) {
      formData.append(value, values[value]);
    }
    onUpload(id, formData);
  };
  return (
    <WrapperModal>
      <ModalContent>
        <ModalHeader>IMPORTER UNE IMAGE</ModalHeader>
        <Formik
          onSubmit={submit}
          initialValues={{
            file: "",
            imageAlt: "",
          }}
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            values,
          }) => (
            <form onSubmit={handleSubmit}>
              <Field
                name="file"
                id="file"
                label="PARCOURIR VOS FICHIERS"
                component={FileInput}
              />
              <ModalFooter>
                <ButtonSecondary type="submit" disabled={!values.file}>
                  Ajouter
                </ButtonSecondary>
              </ModalFooter>
            </form>
          )}
        </Formik>
      </ModalContent>
    </WrapperModal>
  );
};
export default UploadModal;
