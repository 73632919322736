import styledComponents, { css } from "styled-components";
import Button from "./Button";

const ButtonSecondary = styledComponents(Button)`
${({ theme }) => theme.buttonSecondary.style}
${(props) =>
  props.disabled === true
    ? css`
        opacity: 0.6;
      `
    : css`
        opacity: 1;
      `}
`;
export default ButtonSecondary;
