import apiWebify from "../api.webify";
import { TypeDevis, Question, Option, Devis } from "../../interfaces";
import authHeader from "./auth-header";

const apiQuestionMap = (q: Question) => ({
  id: q.id,
  "@id": q["@id"],
  title: q.title,
  name: q.name,
  sort: q.sort,
  type: q.type,
  lang: q.lang,
  options: q.options.map((o: Option) => ({
    id: o.id,
    name: o.name,
    title: o.title,
    type: o.type,
    sort: o.sort,
    "@id": o["@id"],
    question: o.question,
  })),
});
const apiTypeQuestionMap = (t: TypeDevis) => ({
  id: t.id,
  "@id": t["@id"],
  name: t.name,
  description: t.description,
  slug: t.slug,
  contentUrl: t.contentUrl,
  lang: t.lang,
});
const apiOptionMap = (o: any) => ({
  id: o.id,
  "@id": o["@id"],
  name: o.name,
  title: o.title,
  question: o.question,
});
const apiDevisMap = (d: Devis) => ({
  id: d.id,
  fullname: d.fullname,
  email: d.email,
  phone: d.phone,
  compagnyname: d.compagnyname,
  compagny: {
    id: d.Compagny.id,
    name: d.Compagny.name,
  },
  postal: d.postal,
  description: d.description,
  done: d.done,
  createdAt: d.createdAt,
  typequestions: {
    id: d.typequestions.id,
    name: d.typequestions.name,
    slug: d.typequestions.slug,
    "@id": d.typequestions["@id"],
    description: d.typequestions.description,
  },
  options: d.options.map((o) => ({
    id: o.id,
    "@id": o["@id"],
    name: o.name,
    title: o.title,
    question: o.question,
  })),
});

const getQuestionsDevis = (filter: string, lang: string, sort: string) => {
  return apiWebify
    .get(
      `questions?typequestion.slug=${filter}&lang=${lang}&order[sort]=${sort}&order[options.sort]=${sort}`
    )
    .then((res) => res.data)
    .then((questions) => {
      return {
        data: questions["hydra:member"].map(apiQuestionMap),
        totalRows: questions["hydra:totalItems"],
      };
    });
};

const getTypeQuestions = (lang: string) => {
  return apiWebify
    .get(`typequestions?lang=${lang}`)
    .then((res) => res.data["hydra:member"].map(apiTypeQuestionMap));
};
const addTypeQuestion = (values: any) => {
  return apiWebify
    .post(`typequestions`, values, { headers: authHeader() })
    .then((res) => res.data)
    .then(apiTypeQuestionMap);
};

const editTypeQuestionImg = (id: number, values: any) => {
  return apiWebify
    .post(`typequestions/${id}/image`, values, {
      headers: authHeader(),
    })
    .then((res) => res.data)
    .then(apiTypeQuestionMap);
};

const removeTypeQuestion = (id: number) => {
  return apiWebify.delete(`typequestions/${id}`, { headers: authHeader() });
};

const editTypeQuestion = (id: number, values: any) => {
  return apiWebify
    .put(`typequestions/${id}`, values, { headers: authHeader() })
    .then((res) => res.data)
    .then(apiTypeQuestionMap);
};

const addQuestion = (question: any) => {
  return apiWebify
    .post(`questions`, question, { headers: authHeader() })
    .then((res) => res.data)
    .then(apiQuestionMap);
};
const editQuestion = (id: number, values: any) => {
  return apiWebify
    .put(`questions/${id}`, values, { headers: authHeader() })
    .then((res) => res.data)
    .then(apiQuestionMap);
};
const removeQuestion = (id: number) => {
  return apiWebify.delete(`questions/${id}`, { headers: authHeader() });
};
const editOption = (id: number, values: any) => {
  return apiWebify
    .put(`options/${id}`, values, { headers: authHeader() })
    .then((res) => res.data)
    .then(apiOptionMap);
};
const addOption = (option: Option) => {
  return apiWebify
    .post(`options`, option)
    .then((res) => res.data)
    .then(apiOptionMap);
};
const removeOption = (id: number) => {
  return apiWebify.delete(`options/${id}`, {
    headers: authHeader(),
  });
};

const addDevis = (devis: Devis) => {
  return apiWebify
    .post("devis", devis)
    .then((res) => res.data)
    .then(apiDevisMap);
};
const getAllDevis = (
  page: number,
  per_page: number,
  lang: string,
  filter: string
) => {
  return apiWebify
    .get(`devis?page=${page}&itemsPerPage=${per_page}&lang=${lang}&${filter}`, {
      headers: authHeader(),
    })
    .then((res) => {
      return res.data;
    })
    .then((devis) => {
      return {
        data: devis["hydra:member"].map(apiDevisMap),
        totalRows: devis["hydra:totalItems"],
      };
    });
};
const removeDevis = (id: number) => {
  return apiWebify.delete(`devis/${id}`, {
    headers: authHeader(),
  });
};
const removeSelectedDevis = (selected: number[]) => {
  return Promise.all(selected.map((id) => removeDevis(id)));
};

const editDevis = (id: number, data: any) => {
  return apiWebify
    .put(`devis/${id}`, data, { headers: authHeader() })
    .then((res) => res.data)
    .then(apiDevisMap);
};

const DevisService = {
  getTypeQuestions,
  editTypeQuestionImg,
  editTypeQuestion,
  addTypeQuestion,
  removeTypeQuestion,
  getQuestionsDevis,
  addDevis,
  getAllDevis,
  removeDevis,
  removeSelectedDevis,
  editDevis,
  editQuestion,
  editOption,
  addOption,
  removeOption,
  addQuestion,
  removeQuestion,
};

export default DevisService;
