import { Project } from "src/interfaces";
import apiWebify from "../api.webify";
import authHeader from "./auth-header";

export const apiProjectMap = (p: Project) => ({
  id: p.id,
  idd: p["@id"],
  "@id": p["@id"],
  name: p.name,
  description: p.description,
  online: p.online,
  contentUrl: p.contentUrl ? p.contentUrl : "",
  createdAt: p.createdAt,
  mediaObjects:
    p.mediaObjects.length > 0
      ? p.mediaObjects.map((m) => ({
          id: m.id,
          filePath: m.filePath,
        }))
      : [],
  projectCategory: {
    id: p.projectCategory.id,
    name: p.projectCategory.name,
    value: p.projectCategory["@id"],
    "@id": p.projectCategory["@id"],
    target: p.projectCategory.name,
  },
});
export const getProjects = (
  page: number,
  per_page: number,
  filter: string,
  lang: string
) => {
  return apiWebify
    .get(
      `projects?page=${page}&itemsPerPage=${per_page}&${filter}&lang=${lang}`
    )
    .then((res) => res.data)
    .then((projects) => {
      return {
        data: projects["hydra:member"].map(apiProjectMap),
        totalRows: projects["hydra:totalItems"],
      };
    });
};

export const getPublishProjects = (
  page: number,
  per_page: number,
  lang: string
) => {
  return apiWebify
    .get(
      `projects?page=${page}&itemsPerPage=${per_page}&online=true&lang=${lang}`
    )
    .then((res) => res.data["hydra:member"]);
};

export const addProject = (values: any) => {
  return apiWebify
    .post("projects", values, { headers: authHeader() })
    .then((res) => res.data)
    .then(apiProjectMap);
};
export const removeProject = (id: number) => {
  return apiWebify.delete(`projects/${id}`, { headers: authHeader() });
};
export const removeSelectedProjects = (selected: number[]) => {
  return Promise.all(selected.map((id) => removeProject(id)));
};
export const editProject = (id: number, values: any) => {
  return apiWebify
    .put(`projects/${id}`, values, { headers: authHeader() })
    .then((res) => res.data)
    .then(apiProjectMap);
};

export const editProjectFeatureImg = (id: number, values: any) => {
  return apiWebify
    .post(`projects/${id}/image`, values, { headers: authHeader() })
    .then((res) => res.data)
    .then(apiProjectMap);
};

export const getCountOnlineProjects = (period: string) => {
  return apiWebify
    .get(`projects/count?online=1&period=${period}`)
    .then((res) => res.data);
};
export const getCountOfflineProjects = (period: string) => {
  return apiWebify
    .get(`projects/count?online=0&period=${period}`)
    .then((res) => res.data);
};

const ProjectService = {
  getProjects,
  getPublishProjects,
  addProject,
  removeProject,
  removeSelectedProjects,
  editProject,
  editProjectFeatureImg,
  getCountOnlineProjects,
  getCountOfflineProjects,
};

export default ProjectService;
