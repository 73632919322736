import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import BellIcon from "src/assets/icons/BellIcon";
import { ContactState, RootState } from "src/interfaces";
import styled, { css } from "styled-components";
import { Head3 } from "../components";
import { DropdownItem } from "./header-dashboard/DropdownItem";
import { DropdownList } from "./header-dashboard/DropdownList";
import { ToggleButton } from "./header-dashboard/ToggleButton";
const NotificationWrapper = styled.div`
  position: relative;
`;
const Strong = styled.strong`
  font-weight: bold;
  color: ${({ theme }) => theme.btnToggler.styleActive.color};
`;
const CountNotif = styled.span`
  font-size: 1rem;
  color: #fff;
  position: absolute;
  top: 0;
  right: -5px;
  background: red;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NotifTitle = styled.div`
  padding: 2rem 1rem;
`;
type NotificationProps = {
  handleOpen: () => void;
  open: boolean;
  count: number;
  handleSetCount: () => void;
};
const Notification = ({
  handleOpen,
  open,
  count,
  handleSetCount,
}: NotificationProps) => {
  const [notifications, setNotifications] = useState<any[]>([]);
  const [activeNotifications, setActiveNotifications] = useState<boolean[]>([]);
  const lang = useSelector((state: RootState) => state?.i18n?.locale);

  useEffect(() => {
    const storedNotifications = localStorage.getItem("notifications");
    if (storedNotifications) {
      setNotifications(JSON.parse(storedNotifications));
    }

    const sourceContact = new EventSource(
      `https://localhost/.well-known/mercure?topic=https://127.0.0.1:8000/api/contacts/{id}`
    );
    const sourceDevis = new EventSource(
      `https://localhost/.well-known/mercure?topic=https://127.0.0.1:8000/api/devis/{id}`
    );

    sourceContact.onmessage = (e) => {
      const newNotification: any = JSON.parse(e.data);
      setNotifications((prevNotifications) => [
        ...prevNotifications,
        newNotification,
      ]);
      setActiveNotifications((prevActiveNotifications) => [
        ...prevActiveNotifications,
        true,
      ]);
      handleSetCount();
    };

    sourceDevis.onmessage = (e) => {
      const newNotification: any = JSON.parse(e.data);
      setNotifications((prevNotifications) => {
        const updatedNotifications = [...prevNotifications, newNotification];
        localStorage.setItem(
          "notifications",
          JSON.stringify(updatedNotifications)
        );
        return updatedNotifications;
      });
      setActiveNotifications((prevActiveNotifications) => [
        ...prevActiveNotifications,
        true,
      ]);
      handleSetCount();
    };

    return () => {
      sourceContact.close();
      sourceDevis.close();
    };
  }, [count]);

  const handleClick = (index: number) => {
    setActiveNotifications((prevActiveNotifications) => {
      const updatedActiveNotifications = [...prevActiveNotifications];
      updatedActiveNotifications[index] = false;
      return updatedActiveNotifications;
    });
  };
  return (
    <NotificationWrapper>
      <ToggleButton onClick={handleOpen} active={open}>
        <BellIcon />
        {count > 0 ? <CountNotif>{count}</CountNotif> : null}
      </ToggleButton>
      <DropdownList active={open} lang={lang}>
        <NotifTitle>
          <Head3> Notifications </Head3>
        </NotifTitle>
        {notifications.map((notification, index) =>
          notification["@type"] === "Contact" ? (
            <DropdownItem
              key={index}
              active={activeNotifications[index]}
              onClick={() => handleClick(index)}
            >
              <Strong>{notification.name}</Strong> a envoyé un nouveau message
            </DropdownItem>
          ) : (
            <DropdownItem
              key={index}
              active={activeNotifications[index]}
              onClick={() => handleClick(index)}
            >
              <Strong>{notification.fullname}</Strong> a envoyé une nouvelle
              demande de devis
            </DropdownItem>
          )
        )}
      </DropdownList>
    </NotificationWrapper>
  );
};

export default Notification;
