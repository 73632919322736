import styled from "styled-components";
const TitlePage = styled.h1`
  font-family: "be_vietnamextrabold";
  text-transform: uppercase;
  font-size: 4.6rem;
  text-align: center;
  ${({ theme }) => theme.title.style}
  ${({ theme }) => theme.device.tablet} {
    ${({ theme }) => theme.title.tabletStyle}
    text-align: start;
  }
  ${({ theme }) => theme.device.desktop} {
    ${({ theme }) => theme.title.desktopStyle}
  }
`;
export default TitlePage;
