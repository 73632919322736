import styled from "styled-components";

const Head4 = styled.h4`
  font-size: 1.92rem;
  font-family: "robotoregular";
  padding: 2rem 1rem 2rem 1rem;
  ${({ theme }) => theme.head4.style}
`;

export default Head4;
