import styled, { css } from "styled-components";

export const NavbarBrand = styled.div`
  width: 80px;
  ${(props) =>
    props.lang === "ar"
      ? css`
          margin-left: auto;
        `
      : css`
          margin-right: auto;
        `}
  ${({ theme }) => theme.navbarBrandDashboard.style}
`;
