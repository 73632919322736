import styled from "styled-components";

const TitleSection = styled.h2`
  font-family: "be_vietnamextrabold";
  text-transform: uppercase;
  ${({ theme }) => theme.title.style};
  text-align: center;
  ${({ theme }) => theme.device.tablet} {
    ${({ theme }) => theme.title.tabletStyle}
  }
  ${({ theme }) => theme.device.desktop} {
    ${({ theme }) => theme.title.desktopStyle}
  }
`;
export default TitleSection;
