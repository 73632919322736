import styled from "styled-components";
const Form = styled.form<{ responsive: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: 768px) {
    align-items: flex-start;
  }
  ${({ theme }) => theme.form.style}
  ${({ theme }) => theme.device.tablet} {
    ${({ theme, responsive }) => responsive && theme.form.tabletStyle}
  }
  ${({ theme }) => theme.device.desktop} {
    ${({ theme, responsive }) => responsive && theme.form.desktopStyle}
  }
`;
export default Form;
