import { ReactNode } from "react";
import styledComponents from "styled-components";

const StyledBadge = styledComponents.span`
background: #1ac194;
color: white;
text-transform:uppercase;
padding: .5rem 1rem;
border-radius: .5rem;
`;

type BadgeProps = {
  children: ReactNode;
};
const Badge = ({ children }: BadgeProps) => {
  return <StyledBadge>{children}</StyledBadge>;
};
export default Badge;
