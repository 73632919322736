import React from "react";
import styled, { css } from "styled-components";

interface BarProps {
  show: boolean;
}
const Bar = styled.span`
  transition: all ease 0.2s;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background: rgb(11, 39, 81);
  }
`;
const Bar1 = styled(Bar)<BarProps>`
  ${(props) =>
    props.show
      ? css`
          display: none;
        `
      : css`
          display: block;
        `}
`;
const Bar2 = styled(Bar)<BarProps>`
  ${(props) =>
    props.show
      ? css`
          display: flex;
          align-items: center;
          transform: rotate(45deg);
        `
      : css`
          display: flex;
          align-items: center;
        `}
`;

const Bar3 = styled(Bar)<BarProps>`
  ${(props) =>
    props.show
      ? css`
          display: flex;
          align-items: center;
          transform: rotate(-45deg);
        `
      : css`
          display: flex;
          align-items: flex-end;
        `}
`;
const TogglerIcon = styled.div`
  display: block;
  height: 16px;
  width: 16px;
  position: relative;
`;
const TogglerText = styled.p`
  color: rgb(11, 39, 81);
  font-size: 1.3rem;
  margin: 0;
`;
const TogglerButton = styled.button`
  font-family: "be_vietnammedium";
  border: 0;
  background: transparent;
  display: flex;
  align-items: center;
  gap: 1rem;
  @media (min-width: 992px) {
    display: none;
  }
`;
type NavbarTogglerProps = {
  handleToggle: (e: React.MouseEvent) => void;
  show: boolean;
};
const NavbarToggler = ({ handleToggle, show }: NavbarTogglerProps) => {
  return (
    <TogglerButton>
      <TogglerIcon onClick={handleToggle}>
        <Bar1 show={show} />
        <Bar2 show={show} />
        <Bar3 show={show} />
      </TogglerIcon>

      <TogglerText>Menu</TogglerText>
    </TogglerButton>
  );
};
export default NavbarToggler;
