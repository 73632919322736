import { Route } from "react-router-dom";
import { Redirect } from "react-router-dom";
import AuthService from "./conf/services/auth.service";
import { HeaderDashboard } from "./components";
import SideBarDashboard from "./components/SideBarDashboard";
import { useState } from "react";
import styledComponents from "styled-components";
import { useSelector } from "react-redux";
import { RootState } from "./interfaces";

const Dashboard = styledComponents.div`
  font-family: "robotoregular";
  flex: 1;
`;
const DashboardContent = styledComponents.div`
display:flex;
`;
const DashboardPage = styledComponents.div`
flex:1;
overflow:scroll;
`;
const ProtectedRoute = ({
  component: Component,
  ...rest
}: {
  component: any;
  path: string;
}) => {
  const isAuthenticated = AuthService.getToken();
  const [showFilter, setShowFilter] = useState(false);
  const pages = useSelector((state: RootState) => state.dashboardPages.data);
  return (
    <Dashboard>
      <HeaderDashboard />
      <DashboardContent>
        <SideBarDashboard showFilter={showFilter} pages={pages} />
        <DashboardPage>
          <Route
            {...rest}
            render={(props) =>
              isAuthenticated ? (
                <Component
                  {...props}
                  setShowFilter={setShowFilter}
                  showFilter={showFilter}
                />
              ) : (
                <Redirect
                  to={{ pathname: "/login", state: { from: props.location } }}
                />
              )
            }
          />
        </DashboardPage>
      </DashboardContent>
    </Dashboard>
  );
};
export default ProtectedRoute;
