import {
  combineReducers,
  createStore,
  applyMiddleware,
  Middleware,
  Store,
  Reducer,
  CombinedState,
  AnyAction,
} from "redux";
import * as reducers from "./reducers";
import logger from "redux-logger";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import thunkMiddelware, { ThunkDispatch } from "redux-thunk";
import { i18nReducer, I18nState } from "react-redux-i18n";
import translations from "../translations";
import initialState from "../initialState";
import {
  setLocale,
  loadTranslations,
  syncTranslationWithStore,
} from "react-redux-i18n";

type MyStore = Store & {
  asyncReducers: { [key: string]: Reducer };
  dispatch: ThunkDispatch<
    CombinedState<{
      i18n: I18nState;
      initialState: typeof initialState;
    }>,
    undefined,
    AnyAction
  >;
};

const AppReducer = combineReducers({ i18n: i18nReducer, ...reducers });
const middelwares: Middleware[] = [thunkMiddelware];

if (process.env.NODE_ENV === "development") {
  middelwares.push(logger);
}

export const store: MyStore = createStore(
  AppReducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middelwares))
) as MyStore;

store.asyncReducers = {};
syncTranslationWithStore(store);
store.dispatch(loadTranslations(translations));
store.dispatch(setLocale("fr"));

const createInjectReducer =
  (store: MyStore) => (key: string, reducer: Reducer) => {
    store.asyncReducers[key] = reducer;
    store.replaceReducer(
      combineReducers({
        ...reducers,
        ...store.asyncReducers,
        i18n: i18nReducer,
      })
    );
  };

export const injectReducer = createInjectReducer(store);

export default store;
