import styledComponents, { css } from "styled-components";

interface InputUnderlineProps {
  errors?: string;
  touched?: boolean;
  responsive?: string;
}
const StyledInput = styledComponents.input<InputUnderlineProps>`
font-family: "robotoregular";
color:rgba(115, 119, 125, 1);
font-size: 1.6rem;
border: 0;
background:transparent;
border-bottom: 0.2em solid rgba(219, 218, 218, 0.87);
padding-bottom: .8em;
margin-bottom: 1.5em;
width: 100%;
outline: 0;
${({ theme }) => theme.inputUnderline.style}
${({ theme }) => theme.device.tablet}{
${({ theme, responsive }) => responsive && theme.inputUnderline.tabletStyle}
}
${({ theme }) => theme.device.desktop}{
  ${({ theme, responsive }) => responsive && theme.inputUnderline.desktopStyle}
}
${({ errors, touched }) => {
  return errors && touched
    ? css`
        ${({ theme }) => theme.inputUnderline.styleError}
      `
    : css`
        ${({ theme }) => theme.inputUnderline.styleDefault}
      `;
}}

`;

const InputUnderline = ({
  field,
  form: { touched, errors },
  ...props
}: InputUnderlineProps & { field: any; form: any }) => {
  return (
    <StyledInput
      type="text"
      errors={errors[field.name]}
      touched={touched[field.name]}
      {...field}
      {...props}
    />
  );
};

export default InputUnderline;
