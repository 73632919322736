import { NavLink } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import { ButtonSecondary, Card, Container } from ".";
import Text from "./Text";

const translate = keyframes`
0% {
  transform: translateY(-100vh);
}
100% {
  transform: translateY(0vh);
}
`;
const Wrapper = styled.div`
  flex: 1;
  padding: 2rem 0;
  ${({ theme }) => theme.successPage.style}
  ${({ theme }) => theme.device.tablet} {
    ${({ theme }) => theme.successPage.tabletStyle}
  }
  ${({ theme }) => theme.device.desktop} {
    ${({ theme }) => theme.successPage.desktopStyle}
  }
`;
const SuccessPageContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  justify-content: center;
  align-items: center;
  animation: ${translate} 0.3s ease-in-out;
  ${({ theme }) => theme.successPageContent.style}
  ${({ theme }) => theme.device.tablet} {
    ${({ theme }) => theme.successPageContent.tabletStyle}
  }
  ${({ theme }) => theme.device.desktop} {
    ${({ theme }) => theme.successPageContent.desktopStyle}
  }
`;

export const Alert = styled(Text)<{ responsive: string }>`
  max-width: 600px;
  text-align: center;
  padding: 1rem;
  border-radius: 10px;
  ${({ theme }) => theme.successPageAlert.style}
  ${({ theme }) => theme.device.tablet} {
    ${({ theme }) => theme.successPageAlert.tabletStyle}
  }
  ${({ theme }) => theme.device.desktop} {
    ${({ theme }) => theme.successPageAlert.desktopStyle}
  }
`;
type SuccessPageProps = {
  data: any;
  message: string;
  responsive: string;
  //onClose: () => void;
};
const SuccessPage = ({ data, message, responsive }: SuccessPageProps) => {
  return (
    <Wrapper>
      <Container>
        <SuccessPageContent>
          <Card data={data} type="" responsive={responsive} />
          <Alert responsive="true">{message}</Alert>
          <ButtonSecondary as={NavLink} to="/home" responsive="true">
            Valider
          </ButtonSecondary>
        </SuccessPageContent>
      </Container>
    </Wrapper>
  );
};
export default SuccessPage;
