import React from "react";

function Underline({ size = "lg" }) {
  const customstyle = {
    background: "rgb(250, 171, 0)",
    borderRadius: "20px",
    height: "2px",
    margin: 0,
    width: size === "sm" ? "30%" : "60%",
  };
  return <p style={customstyle} />;
}

export default Underline;
