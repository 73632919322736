import { defaultThemes } from "./defaultTheme";
import merge from "deepmerge";
import { Theme } from "./interfaces";

export const defaultStyles = (theme: Theme) => ({
  device: {
    tablet: `@media (min-width: ${theme.breakpoints.tablet})`,
    desktop: `@media (min-width: ${theme.breakpoints.desktop})`,
  },
  /* global */
  app: {
    style: {
      background: theme.background.default,
    },
  },
  title: {
    style: {
      color: theme.text.primary,
      fontSize: "4.6rem",
      lineHeight: 1,
    },
    tabletStyle: {
      fontSize: "5.76rem",
    },
    desktopStyle: {
      fontSize: "7.2rem",
    },
  },
  head3: {
    style: {
      color: theme.text.default,
      fontSize: "2.3rem",
      lineHeight: 1,
    },
    tabletStyle: {
      fontSize: "2.88rem",
    },
    desktopStyle: {
      fontSize: "3.6rem",
    },
  },
  head4: {
    style: {
      color: theme.text.default,
      fontSize: "1.92rem",
      lineHeight: 1,
    },
  },
  head6: {
    style: {
      fontSize: "1.7rem",
      lineHeight: 1,
    },
  },
  text: {
    style: {
      color: theme.text.default,
      lineHeight: 1.4,
      fontSize: "1.6rem",
      textAlign: "center",
      display: "-webkit-box",
      WebkitLineClamp: 5,
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
    },
    tabletStyle: {
      fontSize: "1.92rem",
      textAlign: "start",
    },
    desktopStyle: {
      fontSize: "2.4rem",
    },
  },
  loader: {
    style: {
      border: "0.5em solid",
      borderColor: theme.loader.borderColor,
      borderLeftColor: theme.loader.borderLeftColor,
      borderRadius: "50%",
      height: "4em",
      width: "4em",
    },
    animation: "0.6s cubic-bezier(0.5, 0, 0.5, 1) infinite",
  },
  divider: {
    style: {
      background: theme.divider.default,
      borderRadius: "20px",
      height: "2px",
      width: "60%",
      border: 0,
    },
    tabletStyle: {
      height: "4px",
      borderRadius: "24px",
    },
    desktopStyle: {
      borderRadius: "30px",
    },
  },
  button: {
    style: {
      border: "1.5px solid",
      borderColor: "transparent",
      padding: ".8rem 2.6rem",
      fontSize: "1.6rem",
      lineHeight: 1,
      borderRadius: "0.38rem",
      transition: "all 0.2s",
    },
    tabletStyle: {
      padding: "1.2rem 3.2rem",
      fontSize: "1.92rem",
      borderRadius: "0.5rem",
    },
    desktopStyle: {
      padding: "1.4rem 4rem",
      fontSize: "2.4rem",
      borderRadius: "0.6rem",
    },
  },
  buttonPrimary: {
    style: {
      background: theme.button.default,
      color: "#FFFFFF",
      "&:hover": {
        background: theme.button.hover,
        color: theme.button.default,
        borderColor: theme.button.default,
      },
      "&:focus": {
        outline: 0,
        borderColor: theme.button.default,
        background: theme.button.hover,
        color: theme.button.default,
      },
      "&:disabled": {
        color: theme.button.disabled,
      },
    },
  },
  buttonSecondary: {
    style: {
      background: theme.button.secondary,
      color: theme.text.focus,
      "&:hover": {
        background: theme.text.focus,
        color: theme.button.secondary,
        borderColor: theme.button.secondary,
      },
    },
  },
  CancelButton: {
    style: {
      color: theme.text.default,
      background: theme.button.light,
      border: "1px solid",
      borderColor: theme.text.default,
      "&:hover": {
        color: "#FFFFFF",
        background: theme.text.default,
        borderColor: theme.text.default,
      },
    },
  },
  dangerButton: {
    style: {
      color: theme.text.danger,
      background: theme.dangerbutton.focus,
      border: "1px solid",
      borderColor: theme.dangerbutton.default,
      "&:hover": {
        color: "#FFFFFF",
        background: theme.dangerbutton.default,
        borderColor: theme.dangerbutton.default,
      },
    },
  },
  image: {
    style: {
      maxWidth: "100%",
    },
  },
  sectionSpacer: {
    style: {
      padding: "2.56rem 0",
    },
    tabletStyle: {
      padding: "3.2rem 0",
    },
    desktopStyle: {
      padding: "4rem 0",
    },
  },
  filters: {
    style: {
      borderRadius: "6px",
    },
    tabletStyle: {
      borderRadius: "7.2px",
    },
    desktopStyle: {
      borderRadius: "8.5px",
    },
  },
  filterBtn: {
    style: {
      fontSize: "1.6rem",
      padding: "1rem 2rem",
    },
    styleActive: {
      color: theme.text.focus,
      background: theme.filter.focus,
    },
    styleInactive: {
      color: theme.text.tertiary,
      background: theme.filter.default,
    },
    tabletStyle: {
      padding: "1.2rem 1.7rem",
      fontSize: "1.8rem",
    },
    desktopStyle: {
      padding: "1.44rem 2.88rem",
      fontSize: "2.1rem",
    },
  },
  form: {
    style: {
      gap: "3.2rem",
    },
    tabletStyle: {
      gap: "3.2rem",
    },
    desktopStyle: {
      gap: "5rem",
    },
  },
  input: {
    style: {
      color: theme.text.input,
      background: theme.input.primary,
      fontSize: "1.6rem",
      padding: "1rem 2.5rem",
      borderRadius: "50px",
      border: "2px solid",
      borderColor: theme.input.default,
    },
    tabletStyle: {
      fontSize: "1.92rem",
      padding: "1.2rem 2.4rem",
    },
    desktopStyle: {
      fontSize: "2rem",
      padding: "1.6rem 2.88rem",
    },
    errorStyle: {
      borderColor: theme.input.error,
    },
    errorServerStyle: {
      borderColor: theme.input.errorServer,
    },
  },
  inputFile: {
    style: {
      color: theme.text.input,
      background: theme.input.primary,
      fontSize: "1.6rem",
      padding: "1.275rem 2.5rem",
      borderRadius: "50px",
      border: "2px solid",
      borderColor: theme.input.default,
    },
    tabletStyle: {
      fontSize: "1.92rem",
      padding: "1.52rem 2.4rem",
    },
    desktopStyle: {
      fontSize: "2rem",
      padding: "1.95rem 2.88rem",
    },
  },
  textarea: {
    style: {
      color: theme.text.input,
      background: theme.input.primary,
      fontSize: "1.6rem",
      padding: "1rem 2.5rem",
      borderRadius: "30px",
      border: "2px solid",
      borderColor: theme.input.default,
    },
    tabletStyle: {
      fontSize: "1.92rem",
      padding: "1.2rem 2.4rem",
    },
    desktopStyle: {
      fontSize: "2rem",
      padding: "1.6rem 2.88rem",
    },
    errorStyle: {
      borderColor: theme.input.error,
    },
  },
  card: {
    style: {
      background: theme.background.default,
      display: "grid",
      gridTemplateAreas: ` "imgCard"
      "titleCard"
      "textCard" `,
      gridTemplateColumns: "minmax(auto, 380px)",
      gridTemplateRows: "auto auto auto",
      gridAutoFlow: "dense",
      rowGap: "2.56rem",
      justifyContent: "center",
      alignItems: "center",
      justifyItems: "center",
    },
    tabletStyle: {
      gridTemplateAreas: ` "imgCard ."
      "imgCard titleCard"
      "imgCard textCard"
      "imgCard ." `,
      gridTemplateColumns: "320px 1fr",
      gridTemplateRows: "25px auto auto 25px",
      justifyItems: "start",
      rowGap: 0,
      columnGap: "2.56rem",
    },
    desktopStyle: {
      gridTemplateColumns: "400px 1fr",
      gridTemplateRows: "50px auto auto 50px",
      columnGap: "5rem",
    },
  },
  cardTitle: {
    style: {
      color: theme.text.default,
    },
    tabletStyle: {
      marginBottom: "1rem",
      textAlign: "start",
    },
    desktopStyle: {
      marginBottom: "3rem",
    },
  },
  cardText: {
    style: {
      color: theme.text.default,
    },
  },
  alert: {
    style: {
      gridTemplateColumns: "1fr 16px",
      fontSize: "1.6rem",
      padding: "1rem",
      borderRadius: "0",
    },
    tabletStyle: {
      gridTemplateColumns: "1fr 20px",
      fontSize: "1.92rem",
      padding: "1.2rem",
      borderRadius: "0",
    },
    desktopStyle: {
      gridTemplateColumns: "1fr 25px",
      fontSize: "2.4rem",
      padding: "1.5rem",
      borderRadius: "0",
    },
    success: {
      background: theme.primary,
      color: theme.text.focus,
    },
  },
  successPage: {
    style: {
      padding: "2rem  0",
    },
    tabletStyle: {
      padding: "3rem  0",
    },
    desktopStyle: {
      padding: "4rem  0",
    },
  },
  successPageContent: {
    style: {
      rowGap: "2rem",
    },
    tabletStyle: {
      rowGap: "3rem",
    },
    desktopStyle: {
      rowGap: "4rem",
    },
  },
  successPageAlert: {
    style: {
      color: theme.text.input,
      background: theme.background.tertiary,
      border: theme.text.input,
      borderRadius: "10px",
      padding: "1rem",
    },
    tabletStyle: {
      padding: "2rem",
      borderRadius: "12px",
    },
    desktopStyle: {
      maxWidth: "800px",
      padding: "3rem",
      borderRadius: "15px",
    },
  },
  ball: {
    style: {
      background: theme.primary,
      color: theme.text.focus,
      fontSize: "1.6rem",
      width: "30px",
      height: "30px",
    },
    tabletStyle: {
      fontSize: "1.92rem",
      width: "32px",
      height: "32px",
    },
    desktopStyle: {
      fontSize: "2.4rem",
      width: "40px",
      height: "40px",
    },
  },
  question: {
    style: {
      color: theme.primary,
      fontSize: "1.6rem",
    },
    tabletStyle: {
      fontSize: "1.92rem",
    },
    desktopStyle: {
      fontSize: "2.4rem",
    },
  },
  inputButton: {
    style: {
      fontSize: "1.6rem",
      padding: ".4rem",
    },
    tabletStyle: {
      fontSize: "1.92rem",
      padding: ".8rem",
    },
    desktopStyle: {
      fontSize: "2.4rem",
      padding: "1rem",
    },
    styleDefault: {
      background: theme.inputButton.default,
      color: theme.text.input,
    },
    styleActive: {
      background: theme.inputButton.focus,
      color: theme.text.focus,
    },
    styleError: {
      background: theme.inputButton.default,
      color: theme.text.input,
      borderColor: theme.input.error,
    },
  },
  inputUnderline: {
    style: {
      color: theme.text.input,
      fontSize: "1.6rem",
    },
    tabletStyle: {
      fontSize: "1.92rem",
    },
    desktopStyle: {
      fontSize: "2.4rem",
    },
    styleDefault: {
      borderColor: theme.input.secondary,
    },
    styleError: {
      borderColor: theme.input.error,
    },
  },
  selectInput: {
    style: {
      color: theme.text.input,
      fontSize: "1.6rem",
    },
    tabletStyle: {
      fontSize: "1.92rem",
    },
    desktopStyle: {
      fontSize: "2.4rem",
    },
    styleDefault: {
      borderColor: theme.input.secondary,
    },
    styleActive: {
      background: theme.inputButton.focus,
      color: theme.text.focus,
    },
    styleError: {
      borderColor: theme.input.error,
    },
  },
  selectOptions: {
    style: {
      background: theme.input.default,
      border: "2px solid",
      borderColor: theme.input.primary,
      top: "3em",
    },
  },
  selectOption: {
    style: {
      fontSize: "1em",
      padding: "0.3em",
    },
  },
  selectToggleBtn: {
    style: {
      width: "12px",
      height: "12px",
    },
    tabletStyle: {
      width: "18px",
      height: "18px",
    },
    desktopStyle: {
      width: "20px",
      height: "20px",
    },
  },
  /* login */
  loginContent: {
    style: {
      gridTemplateColumns: "minmax(auto, 400px)",
      gridTemplateRows: "60px auto auto auto",
      rowGap: "2rem",
    },
    tabletStyle: {
      gridTemplateColumns: "minmax(auto, 500px)",
      gridTemplateRows: "80px auto auto auto",
      rowGap: "4rem",
    },
    desktopStyle: {
      gridTemplateColumns: "minmax(auto, 600px)",
      gridTemplateRows: "100px auto auto auto",
      rowGap: "5rem",
    },
  },
  loginForm: {
    style: {
      gap: "2rem",
    },
    tabletStyle: {
      gap: "4rem",
    },
    desktopStyle: {
      gap: "5rem",
    },
  },
  loginIcon: {
    style: {
      color: theme.icon.default,
    },
  },
  /* banner */
  sectionBanner: {
    style: {},
  },
  banner: {
    style: {
      background: theme.background.default,
      gridTemplateColumns: "minmax(auto, 340px)",
      rowGap: "2.56rem",
      columnGap: 0,
    },
    tabletStyle: {
      gridTemplateColumns: "350px minmax(auto, 500px)",
      rowGap: 0,
      columnGap: "2.56rem",
    },
    desktopStyle: {
      gridTemplateColumns: "450px minmax(auto, 550px)",
    },
  },
  titleBanner: {
    style: {
      color: theme.text.default,
      letterSpacing: "2px",
      fontSize: "2rem",
      textAlign: "center",
    },
    tabletStyle: {
      fontSize: "2.4rem",
      textAlign: "start",
      letterSpacing: "3px",
    },
    desktopStyle: {
      fontSize: "3rem",
    },
  },
  bannerImg: {
    tabletStyle: {
      gridRow: "span 4",
      gridColumn: "2 / 3",
    },
  },
  /* Expertises */
  sectionExpertises: {
    style: {
      background: theme.background.primary,
    },
  },
  expertisesContent: {
    style: {
      gap: "5rem",
    },
    tabletStyle: {
      gap: "6rem",
    },
    desktopStyle: {
      gap: "7.2rem",
    },
  },
  expertisesList: {
    style: {
      gap: "1rem",
      padding: "1rem 0.5rem",
    },
    tabletStyle: {},
    desktopStyle: {
      justifyContent: "space-between",
    },
  },
  expertisesBox: {
    style: {
      background: theme.background.default,
      color: theme.text.tertiary,
      fontSize: "1.4rem",
      display: "grid",
      gridTemplateColumns: "158px",
      gridTemplateRows: "100px 40px",
      alignItems: "center",
      justifyItems: "center",
      borderRadius: "32px",
      boxShadow: theme.shadow.box,
    },
    tabletStyle: {
      gridTemplateColumns: "190px",
      gridTemplateRows: "120px 48px",
      borderRadius: "38.4px",
    },
    desktopStyle: {
      fontSize: "1.6rem",
      gridTemplateColumns: "195px",
      gridTemplateRows: "120px 48px",
      borderRadius: "46px",
    },
  },
  /* packs */
  sectionPacks: {
    style: {},
  },
  packsList: {
    style: {
      gridTemplateColumns: "minmax(auto, 340px)",
      gap: "6rem",
    },
    tabletStyle: {
      gridTemplateColumns: "1fr",
      gap: "8rem",
    },
    desktopStyle: {
      gap: "10rem",
    },
  },
  pack: {
    style: {
      gap: "2.56rem",
    },
    tabletStyle: {
      gap: "3.2rem",
    },
  },
  packContent: {
    style: {
      gap: "2.56rem",
    },
    tabletStyle: {
      alignItems: "start",
      flex: 1,
    },
    desktopStyle: {},
  },
  packLink: {
    style: {
      paddingLeft: "5.6rem",
      paddingRight: "5.6rem",
    },
    tabletStyle: {
      paddingLeft: "7rem",
      paddingRight: "7rem",
    },
    desktopStyle: {
      paddingLeft: "8.5rem",
      paddingRight: "8.5rem",
    },
  },
  /* portfolio */
  sectionPortfolio: {
    style: {},
  },
  portfolioContent: {
    style: {
      gap: "3rem",
    },
    tabletStyle: {
      gap: "3.6rem",
    },
    desktopStyle: {
      gap: "4.2rem",
    },
  },
  portfolioTitleSecondary: {
    style: {},
  },
  portfolioList: {
    style: {
      gridTemplateColumns: "repeat(3, 1fr)",
      gap: "0.5rem",
    },
    tabletStyle: {
      gap: "1rem",
    },
    desktopStyle: {
      gap: "2rem",
    },
  },
  portfolioItem: {
    style: {
      transition: "all 0.2s ease-in-out",
      "&:hover": {
        filter: "brightness(0.8)",
        transform: "scale(1.01)",
      },
    },
  },
  /* testimonials */
  sectionTestimonials: {
    style: {},
  },
  testimonialContent: {
    style: {
      gap: "3rem",
    },
    tabletStyle: {
      gap: "3.2rem",
    },
    desktopStyle: {
      gap: "4rem",
    },
  },
  testimonialList: {
    style: {
      /*       gap: "2rem",
      padding: "2rem 1rem", */
    },
  },
  cardTestimonial: {
    style: {
      background: theme.background.default,
      boxShadow: theme.shadow.card,
      display: "grid",
      gridTemplateColumns: "60px 1fr 80px",
      gridTemplateRows: "auto 60px",
      alignItems: "center",
      gap: "1rem",
      padding: "0 2rem 1rem 2rem",
      cursor: "pointer",
      transition: "all ease 0.1s",
      "&:hover": {
        transition: "all ease 0.2s",
        transform: "scale(1.03)",
      },
    },
  },
  quoteText: {
    style: {
      fontSize: "1.2rem",
      lineHeight: "1.8",
      wordSpacing: "1rem",
      color: theme.text.default,
    },
  },
  starUnfilled: {
    color: theme.icon.secondary,
  },
  starFilled: {
    color: theme.icon.active,
  },
  authorName: {
    style: {
      color: theme.text.default,
      fontSize: "1.4rem",
      lineHeight: 1.5,
    },
  },
  quoteFilled: {
    style: {
      color: theme.icon.active,
    },
  },
  quoteUnFilled: {
    style: {
      color: theme.icon.disabled,
    },
  },
  /* Newsletter */
  newsletter: {
    style: {
      color: theme.text.focus,
      backgroundSize: "cover",
      backgroundPosition: "50% 0",
      marginTop: "2.56rem",
    },
  },
  newsletterContent: {
    style: {
      gap: "2.56rem",
      padding: "5rem 0",
    },
    tabletStyle: {
      gap: "3.2rem",
      padding: "6.4rem 0",
    },
    desktopStyle: {
      gap: "4rem",
      padding: "8rem 0",
    },
  },
  toggleBtnNewsletter: {
    style: {
      color: theme.text.focus,
      background: theme.primary,
      top: "-20px",
      width: "40px",
      height: "40px",
      padding: "1.2rem",
    },
    tabletStyle: {
      top: "-25px",
      width: "50px",
      height: "50px",
      padding: "1.5rem",
    },
    desktopStyle: {
      top: "-30px",
      width: "60px",
      height: "60px",
      padding: "2rem",
    },
  },
  titleNewsletter: {
    style: {
      fontSize: "3.84rem",
      letterSpacing: "0.5px",
    },
    tabletStyle: {
      fontSize: "4.8rem",
      letterSpacing: "0.6px",
    },
    desktopStyle: {
      fontSize: "5.7rem",
      letterSpacing: "0.7px",
    },
  },
  titleSecondaryNewsletter: {
    style: {
      color: theme.text.focus,
    },
  },
  formNewsletter: {
    style: {
      background: theme.button.default,
      minWidth: "350px",
    },
    tabletStyle: {
      minWidth: "520px",
    },
    desktopStyle: {
      minWidth: "670px",
    },
  },
  inputNewsletter: {
    style: {
      borderColor: theme.input.default,
      background: theme.input.default,
    },
  },
  btnSubmitNewsletter: {
    style: {
      color: theme.text.focus,
      fontSize: "1.92rem",
      padding: "0.5rem 2rem",
    },
    tabletStyle: {
      fontSize: "2.4rem",
      padding: "0.6rem 2.4rem",
    },
    desktopStyle: {
      fontSize: "2.88rem",
      padding: "0.7rem 2.88rem",
    },
  },
  successAddNewsletter: {
    style: {
      background: theme.primary,
      margin: "0 1rem",
      padding: ".5rem 1.4rem",
      gap: "2rem",
      borderRadius: "30px",
    },
    tabletStyle: {
      padding: "1.2rem 1.7rem",
      gap: "2.4rem",
    },
    desktopStyle: {
      padding: "1.2rem 2rem",
      gap: "2.88rem",
    },
    text: {
      fontSize: "1.6rem",
    },
  },
  /* footer home */
  footerHome: {
    style: {
      background: theme.background.default,
      fontSize: "1.1rem",
      lineHeight: 1,
    },
    tabletStyle: {
      fontSize: "1.3rem",
    },
    desktopStyle: {
      fontSize: "1.6rem",
    },
  },
  footerHomeContent: {
    style: {
      gridTemplateRows: "30px",
      gap: "1rem",
    },
    tabletStyle: {
      gridTemplateRows: "50px",
    },
    desktopStyle: {
      gridTemplateRows: "70px",
    },
  },
  footerHomeMenu: {
    style: {
      display: "flex",
      justifyContent: "space-between",
    },
    tabletStyle: {
      gap: "1rem",
    },
    desktopStyle: {
      gap: "6rem",
    },
  },
  footerNavLink: {
    style: {
      color: theme.text.secondary,
      fontWeight: 800,
      cursor: "pointer",
      textDecoration: "none",
    },
    activeStyle: {
      color: theme.primary,
    },
  },
  copyright: {
    style: {
      color: theme.text.focus,
      background: theme.background.secondary,
      fontSize: ".7rem",
      padding: "0.5rem",
    },
    tabletStyle: {
      fontSize: "1rem",
    },
    desktopStyle: {
      fontSize: "1.1rem",
    },
  },
  /* header */
  header: {
    style: {
      background: theme.background.primary,
      color: theme.text.default,
      fontSize: "1.6rem",
      gridTemplateRows: "60px auto auto auto",
      columnGap: "2rem",
    },
    tabletStyle: {
      gridTemplateRows: "80px auto auto auto",
    },
    desktopStyle: {
      gridTemplateColumns: "1fr auto auto 1px auto",
      gridTemplateRows: "90px auto auto auto",
    },
  },
  logo: {
    style: {
      maxWidth: "100px",
    },
    tabletStyle: {
      maxWidth: "130px",
    },
    desktopStyle: {
      maxWidth: "160px",
    },
  },
  headerNavLink: {
    style: {
      padding: ".8rem 2rem",
      borderRadius: "10px",
      color: theme.text.default,
    },
    styleActive: {
      background: "transparent",
      color: theme.primary,
    },
    desktopStyleActive: {
      background: theme.primary,
      color: theme.background.default,
    },
  },
  headerMenuProfil: {
    style: {
      background: theme.background.default,
      boxShadow: theme.shadow.menu,
      top: "60px",
      width: "280px",
      padding: "2rem 1rem",
      gap: "1rem",
      borderRadius: "20px",
    },
    desktopStyle: {
      top: "80px",
    },
  },
  headerSeparator: {
    style: {
      width: "1px",
      height: "16px",
      background: theme.text.default,
    },
  },
  btnToggler: {
    style: {
      fontSize: "1.2rem",
      background: theme.background.tertiary,
    },
    styleHover: {
      background: theme.background.quinary,
    },
    styleActive: {
      background: theme.background.quinary,
      color: theme.text.quinary,
    },
    styleInactive: {
      background: theme.background.tertiary,
      color: theme.text.default,
    },
  },
  languageSwitcherMenuList: {
    style: {
      background: theme.background.default,
      boxShadow: theme.shadow.menu,
      top: "50px",
    },
    tabletStyle: {},
    desktopStyle: {},
  },
  languageSwitcherMenuItem: {
    style: {
      borderBottom: "1px solid",
      borderColor: theme.background.tertiary,
      "&:hover": {
        background: theme.background.tertiary,
      },
    },
  },
  languageSwitcherMenuItemLink: {
    style: {
      padding: "",
    },
    tabletStyle: {},
    desktopStyle: {},
    styleActive: {
      background: theme.inputButton.focus,
      color: theme.text.focus,
    },
  },
  dropdownProfilList: {
    style: {
      background: theme.background.default,
      boxShadow: theme.shadow.menu,
      top: "50px",
    },
    styleActive: {},
    styleInactive: {},
  },
  dropdownProfilListItem: {
    style: {
      borderBottom: "1px solid",
      borderColor: theme.background.tertiary,
      gap: "0.5rem",
      "&:hover": {
        background: theme.background.tertiary,
      },
    },
    styleActive: {},
    styleInactive: {},
  },

  /* portfolio page */
  portfolioPageContent: {
    style: {
      gridTemplateColumns: "minmax(0, 500px)",
      rowGap: "2.56rem",
    },
    tabletStyle: {
      rowGap: "6.4rem",
    },
    desktopStyle: {
      rowGap: "8rem",
    },
  },
  portfolioProject: {
    style: {
      gap: "2.56rem",
    },
    tabletStyle: {
      gap: "3.2rem",
    },
    desktopStyle: {
      gap: "6.2rem",
    },
  },
  portfolioProjectImage: {
    style: {
      borderRadius: 0,
    },
    tabletStyle: {
      borderRadius: "16px",
    },
    desktopStyle: {
      borderRadius: "20px",
    },
  },
  /* contact page */
  contactPage: {
    style: {
      gridTemplateColumns: "minmax(auto, 400px)",
      rowGap: "4rem",
    },
    tabletStyle: {
      gridTemplateColumns: "1fr 360px",
      rowGap: "4rem",
    },
    desktopStyle: {
      gridTemplateColumns: "1fr 500px",
      rowGap: "5rem",
    },
  },
  cardContact: {
    style: {
      maxWidth: "480px",
      display: "grid",
      gridTemplateColumns: "40px 25px 25px 25px 25px auto 40px",
      gridTemplateRows: "auto auto auto auto 25px",
      rowGap: "2rem",
      columnGap: ".8rem",
    },
    tabletStyle: {
      gridTemplateColumns: "20px 30px 35px 35px 35px auto 20px",
      gridTemplateRows: "auto auto auto auto 35px",
      rowGap: "2.2rem",
      columnGap: "1rem",
    },
    desktopStyle: {
      gridTemplateColumns: "50px 36px 40px 40px 40px auto 50px",
      gridTemplateRows: "auto auto auto auto 40px",
      rowGap: "2.4rem",
      columnGap: "1rem",
    },
  },
  iconCardContact: {
    style: {
      color: theme.icon.default,
    },
  },
  socialMediaCardContact: {
    style: {
      color: theme.socialMedia.default,
    },
  },
  textCardContact: {
    style: {},
  },
  /* testimonials page */
  testimonialsPage: {
    style: {
      gridTemplateColumns: "minmax(auto, 350px)",
      justifyContent: "center",
      justifyItems: "center",
      rowGap: "2.56rem",
    },
    tabletStyle: {
      gridTemplateColumns: "1fr 1fr",
      justifyContent: "start",
      justifyItems: "stretch",
      rowGap: "2.4rem",
    },
    desktopStyle: {
      gridTemplateColumns: "1fr 1fr 1fr",
      rowGap: "2.8rem",
    },
  },
  /* add comment page */
  star: {
    style: {
      unfilledColor: theme.text.input,
      width: "25px",
      height: "25px",
      marginRight: ".2rem",
    },
    tabletStyle: {
      width: "30px",
      height: "30px",
      marginRight: ".3rem",
    },
    desktopStyle: {
      width: "35px",
      height: "35px",
      marginRight: ".4rem",
    },
  },
  labelFeedback: {
    style: {
      color: theme.text.input,
      fontSize: "1.6rem",
    },
    tabletStyle: {
      fontSize: "1.92rem",
      marginBottom: "2rem",
    },
    desktopStyle: {
      fontSize: "2rem",
      marginBottom: "2.4rem",
    },
  },
  addCommentPage: {
    style: {
      gridTemplateColumns: "minmax(auto, 350px)",
      justifyContent: "center",
      rowGap: "2.56rem",
    },
    tabletStyle: {
      gridTemplateColumns: "minmax(auto, 780px)",
      justifyContent: "stretch",
      rowGap: "3.2rem",
    },
    desktopStyle: {
      gridTemplateColumns: "1fr 350px",
      alignItems: "end",
      rowGap: "4rem",
    },
  },
  lastCommentSection: {
    style: {
      gap: "2rem",
    },
  },
  BtnLastCommentSection: {
    style: {
      padding: "0.8rem 2rem",
      fontSize: "1.6rem",
    },
  },
  /* page services */
  pageServices: {
    style: {
      gap: "4rem",
    },
    tabletStyle: {
      gap: "5rem",
    },
    desktopStyle: {
      gap: "6rem",
    },
  },

  stepper: {
    style: {
      gap: "3.8rem",
    },
    tabletStyle: {
      gap: "4.8rem",
      padding: "0 6rem",
    },
    desktopStyle: {
      gap: "6rem",
      padding: "0 12rem",
    },
  },
  stepperContent: {
    style: {
      gap: "2rem",
    },
    tabletStyle: {
      gap: "2.4",
    },
    desktopStyle: {
      gap: "3rem",
    },
  },
  titleStepper: {
    style: {
      color: theme.text.default,
      fontSize: "3.2rem",
    },
    tabletStyle: {
      fontSize: "4rem",
    },
    desktopStyle: {
      fontSize: "5rem",
    },
  },
  progressBall: {
    style: {
      background: theme.text.primary,
      width: "16px",
      height: "16px",
      padding: "0.4rem",
    },
    activeStyle: {
      background: theme.primary,
    },
    tabletStyle: {
      width: "20px",
      height: "20px",
    },
    desktopStyle: {
      width: "30px",
      height: "30px",
    },
  },
  progressBar: {
    style: {
      background: theme.text.primary,
      height: "4.48px",
    },
    tabletStyle: {
      height: "5.6px",
    },
    desktopStyle: {
      height: "6px",
    },
  },
  stepperBtn: {
    style: {
      color: theme.text.default,
      gridTemplateColumns: "25px 25px",
      gridTemplateRows: "25px",
    },
    tabletStyle: {
      gridTemplateColumns: "30px 30px",
      gridTemplateRows: "30px",
    },
    desktopStyle: {
      gridTemplateColumns: "36px 36px",
      gridTemplateRows: "36px",
    },
  },
  /* footer */
  footer: {
    style: {
      gridTemplateColumns: "20px 20px 20px 1fr",
      gap: ".5rem",
      padding: "1.5rem 0",
      borderTop: "1px solid",
      borderColor: theme.icon.default,
      fontSize: "1rem",
    },
    tabletStyle: {
      gridTemplateColumns: "25px 25px 25px 1fr",
      gap: ".8rem",
      padding: "1.5rem 0",
      borderColor: theme.icon.default,
      fontSize: "1.2rem",
    },
    desktopStyle: {
      fontSize: "1.4rem",
    },
  },
  footerIcon: {
    style: {
      color: theme.socialMedia.primary,
    },
  },
  /* Dashboard */
  headerDashboard: {
    style: {
      background: theme.background.default,
      minHeight: "70px",
      padding: "0 2rem",
      fontSize: "1.6rem",
    },
  },
  navbarBrandDashboard: {
    style: {
      width: "80px",
    },
  },
  sidebarDashboard: {
    style: {
      background: theme.background.default,
      height: "calc(100vh - 60px)",
      top: "60px",
    },
  },
  sidebarDashboardNavLink: {
    style: {
      fontSize: "1.6rem",
      gap: "1rem",
      color: theme.text.sidebar,
      padding: "1rem 2rem",
    },
    styleActive: {
      background: theme.background.sidebar,
      color: theme.primarydashboard,
      borderColor: theme.primarydashboard,
    },
  },
  sidebarDashboardIcon: {
    style: {
      maxWidth: "25px",
    },
  },
});

export const createStyles = (customStyles = {}, themeName = "default") => {
  const themeType = defaultThemes[themeName] ? themeName : "default";

  return merge(defaultStyles(defaultThemes[themeType]), customStyles);
};
