import { ResponsiveProps } from "src/interfaces";
import styled from "styled-components";

const Text = styled.p<ResponsiveProps>`
  ${({ theme }) => theme.text.style};
  ${({ theme }) => theme.device.tablet} {
    ${({ theme, responsive }) => responsive && theme.text.tabletStyle}
  }
  ${({ theme }) => theme.device.desktop} {
    ${({ theme, responsive }) => responsive && theme.text.desktopStyle}
  }
`;

export default Text;
