import { ResponsiveProps } from "src/interfaces";
import styledComponents from "styled-components";

const Ball = styledComponents.span<ResponsiveProps>`
font-family:"open_sanssemibold";
font-size:1.6rem;
width:30px;
height:30px;
flex-shrink:0;
border-radius:100%;
display:flex;
align-items:center;
justify-content:center;
${({ theme }) => theme.ball.style}
${({ theme }) => theme.device.tablet}{
  ${({ theme, responsive }) => responsive && theme.ball.tabletStyle}
}
${({ theme }) => theme.device.desktop}{
  ${({ theme, responsive }) => responsive && theme.ball.desktopStyle}
}
`;
export default Ball;
