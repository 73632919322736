import styled from "styled-components";
import { ResponsiveProps } from "src/interfaces";
const Head3 = styled.h3<ResponsiveProps>`
  font-family: "robotoregular";
  font-size: 2.3rem;
  ${({ theme }) => theme.head3.style}
  ${({ theme }) => theme.device.tablet} {
    ${({ theme, responsive }) => responsive && theme.head3.tabletStyle}
  }

  ${({ theme }) => theme.device.desktop} {
    ${({ theme, responsive }) => responsive && theme.head3.desktopStyle}
  }
`;

export default Head3;
