import React, { useState } from "react";
import { Loading } from ".";
import styled, { css } from "styled-components";

const FilterWrapper = styled.div`
  font-family: "be_vietnammedium";
  border-radius: 6px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  overflow: scroll;
  &::-webkit-scrollbar{
    display: none;
  },
  overflowStyle: none;
  scrollbar-width: none;
  ${({ theme }) => theme.filters.style};
  ${({ theme }) => theme.device.tablet} {
    ${({ theme }) => theme.filters.tabletStyle};
  }
  ${({ theme }) => theme.device.dsktop} {
    ${({ theme }) => theme.filters.desktopStyle};
  }
`;
const FilterBtn = styled.button<{ active?: boolean }>`
  font-size: 1.6rem;
  flex: 1;
  border: 0;
  white-space: nowrap;
  padding: 1rem 2rem;
  cursor: pointer;
  ${({ theme }) => theme.filterBtn.style};
  ${({ theme }) => theme.device.tablet} {
    ${({ theme }) => theme.filterBtn.tabletStyle};
  }
  ${({ theme }) => theme.device.desktop} {
    ${({ theme }) => theme.filterBtn.desktopStyle};
  }
  ${(props) =>
    props.active
      ? css`
          ${({ theme }) => theme.filterBtn.styleActive}
        `
      : css`
          ${({ theme }) => theme.filterBtn.styleInactive}
        `};
`;
type FilterProps = {
  data: any;
  isLoading?: boolean;
  handleFilter: (target: string) => void;
};
const Filter = ({ data, isLoading, handleFilter }: FilterProps) => {
  const [active, setActive] = useState<number>(0);
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setActive(parseInt(e.currentTarget.id));
    handleFilter(e.currentTarget.name);
  };
  return data && !isLoading ? (
    <FilterWrapper>
      {data &&
        data.map((btn: any, i: number) => (
          <FilterBtn
            key={i}
            active={active === i}
            id={i.toString()}
            name={btn.target}
            onClick={handleClick}
          >
            {btn.name.toUpperCase()}
          </FilterBtn>
        ))}
    </FilterWrapper>
  ) : (
    <Loading />
  );
};
export default Filter;
