import styledComponents from "styled-components";
const ModalContent = styledComponents.div`
min-width:600px;
max-height: 90%;
padding:2rem;
overflow:scroll;
scrollbar-width: thin;
scrollbar-color: rgba(225,225,225,1);
border-radius: 5px;
display: flex;
flex-direction: column;
position:relative;
background: white;

::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(225,225,225,1);
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

`;
export default ModalContent;
