import styledComponents from "styled-components";
const WrapperModal = styledComponents.div`
  z-index: 1000;
  height: 100vh;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
  overflow-y: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export default WrapperModal;
