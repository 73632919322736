import styled from "styled-components";

const WrapperFooter = styled.footer`
  font-family: "be_vietnamextrabold";
  font-size: 1.1rem;
  line-height: 1;
  text-transform: uppercase;
  ${({ theme }) => theme.footerHome.style}
  ${({ theme }) => theme.device.tablet} {
    ${({ theme }) => theme.footerHome.tabletStyle}
  }
  ${({ theme }) => theme.device.desktop} {
    ${({ theme }) => theme.footerHome.desktopStyle}
  }
`;
export default WrapperFooter;
