import { NavLink } from "react-router-dom";
import styled from "styled-components";

const StyledNavbarBrand = styled(NavLink)`
  max-width: 100px;
  ${({ theme }) => theme.logo.style}
  ${({ theme }) => theme.device.tablet} {
    ${({ theme }) => theme.logo.tabletStyle}
  }
  ${({ theme }) => theme.device.desktop} {
    ${({ theme }) => theme.logo.desktopStyle}
  }
`;

const NavbarBrand = ({ children }: { children: React.ReactNode }) => {
  return <StyledNavbarBrand to="/home">{children}</StyledNavbarBrand>;
};
export default NavbarBrand;
