import { useSelector } from "react-redux";
import { useState } from "react";
import styledComponents, { css } from "styled-components";
import LogoImg from "../../assets/images/LogoImg";
import LanguageSwitcher from "../LanguageSwitcher";
import Separator from "../Header/Separator";
import DropdownProfil from "../DropdownProfil";
import LoginButton from "../Header/LoginButton";
import { AuthState, RootState } from "src/interfaces";
import Notification from "../Notification";
import { NavbarBrand } from "./NavbarBrand";
const Navbar = styledComponents.nav`
  position: sticky;
  top: 0;
  background: #FFFFFF;
  min-height: 70px;
  font-family: "be_vietnamextrabold";
  display: flex;
  align-items: center;
  gap:1rem;
  z-index: 1000;
  padding: 0 2rem;
  box-shadow:0 0 15px #0000000d;
  ${({ theme }) => theme.headerDashboard.style}
`;

const HeaderDashboard = () => {
  const [openProfil, setOpenProfil] = useState(false);
  const [openInternationalMenu, setOpenInternationalMenu] = useState(false);
  const [openNotif, setOpenNotif] = useState(false);
  const [countNotif, setCountNotif] = useState(0);
  const isLoggedin = useSelector(
    (state: { auth: AuthState }) => state.auth.isLoggedin
  );
  const userString = localStorage.getItem("user");
  const username =
    isLoggedin && userString ? JSON.parse(userString).username : "";
  const lang = useSelector((state: RootState) => state?.i18n?.locale);

  const handleOpenProfil = () => {
    setOpenProfil(!openProfil);
    setOpenInternationalMenu(false);
    setOpenNotif(false);
  };
  const handleOpenNotif = () => {
    setOpenNotif(!openNotif);
    setOpenInternationalMenu(false);
    setOpenProfil(false);
    resetCountNotif();
  };
  const handleOpenInternational = () => {
    setOpenInternationalMenu(!openInternationalMenu);
    setOpenProfil(false);
    setOpenNotif(false);
  };
  const handleSetCountNotif = () => {
    setCountNotif(countNotif + 1);
  };
  const resetCountNotif = () => {
    setCountNotif(0);
  };

  return (
    <Navbar>
      <NavbarBrand lang={lang}>
        <LogoImg />
      </NavbarBrand>
      <Notification
        handleOpen={handleOpenNotif}
        open={openNotif}
        handleSetCount={handleSetCountNotif}
        count={countNotif}
      />
      <LanguageSwitcher
        open={openInternationalMenu}
        toggleShowMenu={handleOpenInternational}
      />
      {isLoggedin ? (
        <DropdownProfil
          username={username}
          open={openProfil}
          handleOpen={handleOpenProfil}
        />
      ) : (
        <LoginButton />
      )}
    </Navbar>
  );
};

export default HeaderDashboard;
