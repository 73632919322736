import { Field, Formik } from "formik";
import * as Yup from "yup";

import { Form, InputText, Textarea } from "src/components";
import { useDispatch } from "react-redux";
import { tryEditTypeQuestionsAction } from "src/store/actions";
import ButtonEditMode from "./ButtonditMode";

const EditCard = ({ data }: { data: any }) => {
  const dispatch = useDispatch();
  const userSchema = Yup.object().shape({
    name: Yup.string().required().min(4),
  });
  const submit = (values: any) => {
    dispatch(tryEditTypeQuestionsAction(data.id, values));
  };
  return (
    <Formik
      onSubmit={submit}
      initialValues={{
        name: data.name,
        description: data.description,
      }}
      validationSchema={userSchema}
    >
      {({
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        values,
      }) => (
        <Form onSubmit={handleSubmit} responsive="">
          <Field
            type="text"
            name="name"
            onChange={handleChange}
            placeholder={data.name}
            component={InputText}
          />
          <Field
            type="text"
            name="description"
            onChange={handleChange}
            placeholder={data.description}
            component={Textarea}
            rows={8}
          />
          <ButtonEditMode type="submit">Ajouter</ButtonEditMode>
        </Form>
      )}
    </Formik>
  );
};

export default EditCard;
