import { useSelector } from "react-redux";
import { I18n } from "react-redux-i18n";
import { useMediaQuery } from "react-responsive";
import { NavLink, Route } from "react-router-dom";
import { RootState } from "src/interfaces";
import styledComponents from "styled-components";

type ListItemLinkProps = {
  to: string;
  name: string;
  icon: JSX.Element;
  showFilter: boolean;
  isDesktop: boolean;
  lang?: string | undefined;
  [key: string]: any;
};
type Page = {
  path: string;
  name: string;
  icon: JSX.Element;
};
const Sidebar = styledComponents.ul`
background: #ffffff;
font-family: "be_vietnammedium";
height: calc(100vh - 60px);
position: sticky;
top: 60px;
${({ theme }) => theme.sidebarDashboard.style}
`;
const StyledIcon = styledComponents.span`
display:inline-block;
max-width:25px;
color:inherit;
${({ theme }) => theme.sidebarDashboardIcon.style}

`;
const StyledNavLink = styledComponents(NavLink)`
  font-size: 1.6rem;
  line-height: 1;
  display: flex;
  align-items: center;
  gap: 1rem;
  color: #91a9c9;
  text-decoration: none;
  padding: 1rem 2rem;
  &.active {
    background:#e2ecfa;
    color:blue;
    ${({ theme }) => theme.sidebarDashboardNavLink.styleActive}
  }
` as any;

const ListItemLink = ({
  to,
  name,
  icon,
  showFilter,
  isDesktop,
  lang,
  ...rest
}: ListItemLinkProps) => (
  <Route
    path={to}
    children={() => (
      <StyledNavLink to={to} {...rest} activeClassName="active" lang={lang}>
        <StyledIcon> {icon} </StyledIcon>
        {showFilter || !isDesktop ? "" : I18n.t("sidebardashboard." + name)}
      </StyledNavLink>
    )}
  />
);

type SideBarDashboardProps = {
  showFilter: boolean;
  pages: Page[];
};
const SideBarDashboard = ({ showFilter, pages }: SideBarDashboardProps) => {
  const isDesktop = useMediaQuery({ query: "(min-width:992px)" });
  const lang = useSelector((state: RootState) => state?.i18n?.locale);
  return (
    <Sidebar>
      {pages.map((p, i) => (
        <ListItemLink
          key={i}
          to={p.path}
          name={p.name}
          icon={p.icon}
          showFilter={showFilter}
          isDesktop={isDesktop}
          lang={lang}
        ></ListItemLink>
      ))}
    </Sidebar>
  );
};

export default SideBarDashboard;
