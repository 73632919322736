import { ReactNode } from "react";
import styled from "styled-components";
import QuoteIcon from "../../assets/icons/QuoteIcon";

const QuoteStyle = styled.blockquote`
  grid-column: span 3;
  font-size: 1.2rem;
  line-height: 1.8;
  word-spacing: 1rem;
  text-align: start;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
  ${({ theme }) => theme.quoteText.style}
`;
const MyQuoteIcon = styled.span`
  ${({ theme }) => theme.quoteFilled.style}
`;
type QuoteTextProps = { children: ReactNode };

function QuoteText({ children }: QuoteTextProps) {
  return (
    <QuoteStyle>
      <MyQuoteIcon>
        <QuoteIcon />
      </MyQuoteIcon>
      {children}
    </QuoteStyle>
  );
}

export default QuoteText;
