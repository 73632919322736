import styled, { css } from "styled-components";

export const DropdownItem = styled.li<{ active: boolean }>`
  ${({ theme }) => theme.languageSwitcherMenuItem.style}
  cursor: pointer;
  padding: 1rem 4rem 1rem 1rem;
  gap: 1px;
  font-size: 1.4rem;
  &:hover {
    background: rgb(245, 245, 245);
  }
  &:last-child {
    border-bottom: none;
  }
  ${({ active }) =>
    active
      ? css`
          color: rgba(4, 108, 205, 0.87);
          background: #e5eefa;
        `
      : css`
          background: transparent;
          color: #91a9c9;
        `}
`;
