import { lazy, Suspense, useEffect, useState } from "react";
import { Switch, Redirect } from "react-router-dom";
import Loader from "./components/utils/loader/Loader";
import ProtectedRoute from "./ProtectedRoutes";
import PublicRoutes from "./PublicRoutes";
import { useDispatch, useSelector } from "react-redux";
import {
  loginUserSuccessAction,
  fetchCompagniesInput,
  fetchQuestionsDevisAction,
  fetchTypeQuestionsAction,
} from "./store/actions";
import { filteredTypeQuestionsSelector } from "./store/selectors";
import openSansWoff2 from "./assets/fonts/opensans-semi-bold/opensans-semibold-webfont.woff";
import robotoWoff2 from "./assets/fonts/roboto-regular/roboto-regular-webfont.woff";
import bevietnamExtraBoldWoff2 from "./assets/fonts/bevietnam-extra-bold/bevietnam-extrabold-webfont.woff";
import bevietnamMediumWoff2 from "./assets/fonts/bevietnam-medium/bevietnam-medium-webfont.woff";
import bevietnamSemiBoldWoff2 from "./assets/fonts/bevietnam-semi-bold/bevietnam-semibold-webfont.woff";

import { defaultStyles } from "./defaultStyles";
//import { defaultThemes } from "./defaultTheme";
import { useMemo } from "react";
import { createStyles } from "./defaultStyles";
import { ThemeProvider } from "styled-components";
import GlobalStyles from "./GlobalStyles";
import FontStyles from "./FontStyles";
import styled from "styled-components";
import { RootState } from "./interfaces";
import SuccessPage from "./components/SuccessPage";
import { I18n } from "react-redux-i18n";

const MyApp = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;
const LazyDashboardHome = lazy(
  () => import("./features/dashboard/dashboard-home/DashboardHome")
);
const LazyContactPage = lazy(
  () => import("./features/contact-page/ContactPage")
);
const LazyTestimonials = lazy(
  () => import("./features/testimonial-page/Testimonials")
);
const LazyPortfolioPage = lazy(
  () => import("./features/portfolio-page/PortfolioPage")
);
const LazyHome = lazy(() => import("./features/home/Home"));
const LazyServices = lazy(() => import("./features/sevices-page/ServicePage"));

const LazyDevis = lazy(() => import("./features/dashboard/devis/Devis"));
const LazyPortfolio = lazy(
  () => import("./features/dashboard/portfolio/Portfolio")
);
const LazyComments = lazy(
  () => import("./features/dashboard/comments/Comments")
);
const LazyNewsletters = lazy(
  () => import("./features/dashboard/newsletters/Newsletters")
);
const LazyContact = lazy(() => import("./features/dashboard/contact/Contact"));
const LazyAccounts = lazy(
  () => import("./features/dashboard/accounts/Accounts")
);
const LazyLogin = lazy(() => import("./features/auth/login/Login"));

const LazySettings = lazy(
  () => import("./features/dashboard/settings/Settings")
);

const App = () => {
  const dispatch = useDispatch();
  const currentlang = useSelector((state: RootState) => state?.i18n?.locale);
  const [fontsLoaded, setFontsLoaded] = useState(false);
  const filterQuestion = useSelector(
    (state: RootState) => state.questionsDevis.filter
  );
  const sort = useSelector((state: RootState) => state.questionsDevis.sort);
  const totalRows = useSelector(
    (state: RootState) => state.questionsDevis.totalRows
  );

  const currentTypeDevis = useSelector(filteredTypeQuestionsSelector);
  useEffect(() => {
    const openSansSemibold = new FontFace(
      "open_sanssemibold",
      `url(${openSansWoff2})`
    );
    const bevietnamExtraBold = new FontFace(
      "be_vietnamextrabold",
      `url(${bevietnamExtraBoldWoff2})`
    );
    const robotoRegular = new FontFace("robotoregular", `url(${robotoWoff2})`);
    const bevietnamMedium = new FontFace(
      "be_vietnammedium",
      `url(${bevietnamMediumWoff2})`
    );

    const bevietnamSemiBold = new FontFace(
      "be_vietnamsemibold",
      `url(${bevietnamSemiBoldWoff2})`
    );
    Promise.all([
      openSansSemibold.load(),
      bevietnamExtraBold.load(),
      robotoRegular.load(),
      bevietnamMedium.load(),

      bevietnamSemiBold.load(),
    ]).then(() => {
      document.fonts.add(openSansSemibold);
      document.fonts.add(robotoRegular);
      document.fonts.add(bevietnamMedium);
      document.fonts.add(bevietnamExtraBold);
      document.fonts.add(bevietnamSemiBold);
      setFontsLoaded(true);
    });
  }, []);
  useEffect(() => {
    const isLoggedin = localStorage.getItem("user");
    if (isLoggedin) {
      const foundUser = JSON.parse(isLoggedin);
      dispatch(loginUserSuccessAction(foundUser));
    }
  }, [dispatch]);

  useEffect(() => {
    if (currentlang) {
      dispatch(fetchCompagniesInput(currentlang));
      dispatch(fetchTypeQuestionsAction(currentlang));
      dispatch(fetchQuestionsDevisAction(filterQuestion, currentlang, sort));
      document.dir = currentlang === "ar" ? "rtl" : "ltr";
    }
  }, [currentlang, sort, filterQuestion, totalRows, dispatch]);

  const currentTheme = useMemo(
    () => createStyles(defaultStyles, "default"),
    []
  );
  return (
    <ThemeProvider theme={currentTheme}>
      <MyApp>
        {fontsLoaded ? <FontStyles /> : null}
        <GlobalStyles />
        <Suspense fallback={<Loader />}>
          <Switch>
            <PublicRoutes path="/home" component={LazyHome} />
            <PublicRoutes path="/service" component={LazyServices} exact />
            <PublicRoutes
              path="/service/merci-demande-envoyer-avec-success"
              component={() => (
                <SuccessPage
                  data={currentTypeDevis[0]}
                  message={I18n.t("services.successSend.msg")}
                  responsive="true"
                />
              )}
            />
            <PublicRoutes path="/portfolio" component={LazyPortfolioPage} />
            <PublicRoutes path="/testimonial" component={LazyTestimonials} />

            <PublicRoutes path="/contact" component={LazyContactPage} exact />
            <PublicRoutes
              path="/contact/merci-de-nous-contacter"
              component={() => (
                <SuccessPage
                  data={currentTypeDevis[0]}
                  message={I18n.t("contact.successSend.msg")}
                  responsive="true"
                />
              )}
            />
            <PublicRoutes path="/login" component={LazyLogin} />
            <ProtectedRoute
              path="/dashboard/index"
              component={LazyDashboardHome}
            />
            <ProtectedRoute path="/dashboard/devis" component={LazyDevis} />
            <ProtectedRoute
              path="/dashboard/portfolio"
              component={LazyPortfolio}
            />
            <ProtectedRoute
              path="/dashboard/comments"
              component={LazyComments}
            />
            <ProtectedRoute
              path="/dashboard/newsletters"
              component={LazyNewsletters}
            />
            <ProtectedRoute
              path="/dashboard/contacts"
              component={LazyContact}
            />
            <ProtectedRoute
              path="/dashboard/accounts"
              component={LazyAccounts}
            />
            <ProtectedRoute
              path="/dashboard/settings"
              component={LazySettings}
            />
            <Redirect to="/home" />
          </Switch>
        </Suspense>
      </MyApp>
    </ThemeProvider>
  );
};
export default App;
