const style = {
  width: "100%",
  height: "100%",
  borderRadius: "100%",
  ObjectFit: "cover",
};
type AuthorImgProps = {
  src: string;
  alt: string;
};
const AuthorImg = ({ src, alt }: AuthorImgProps) => {
  return <img src={src} alt={alt} style={style} />;
};
export default AuthorImg;
