import { I18n } from "react-redux-i18n";
import { NavLink, Route } from "react-router-dom";
import { Page } from "src/interfaces";
import styled from "styled-components";

const StyledMenu = styled.div`
  display: flex;
  justify-content: space-between;
  @media (min-width: 768px) {
    justify-content: center;
    gap: 4rem;
  }
  @media (min-width: 992px) {
    gap: 6rem;
  }

  ${({ theme }) => theme.footerHomeMenu.style}
  ${({ theme }) => theme.device.tablet} {
    ${({ theme }) => theme.footerHomeMenu.tabletStyle}
  }
  ${({ theme }) => theme.device.desktop} {
    ${({ theme }) => theme.footerHomeMenu.desktopStyle}
  }
`;

const MyNavLink = styled(NavLink)`
  ${({ theme }) => theme.footerNavLink.style}

  &.${(props) => props.activeClassName} {
    ${({ theme }) => theme.footerNavLink.activeStyle}
  }
`;
type ItemLinkProps = {
  to: string;
  name: string;
};
const ItemLink = ({ to, name, ...rest }: ItemLinkProps) => {
  return (
    <Route
      path={to}
      children={() => (
        <MyNavLink to={to} {...rest} activeClassName="active">
          {name}
        </MyNavLink>
      )}
    />
  );
};

const FooterMenu = ({ pages }: { pages: Page[] }) => {
  return (
    <StyledMenu>
      {pages.map((p, i) => (
        <ItemLink
          key={i}
          to={p.path}
          name={I18n.t(`pages.${p.name}`)}
        ></ItemLink>
      ))}
    </StyledMenu>
  );
};
export default FooterMenu;
