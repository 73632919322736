import styled, { css } from "styled-components";

interface DropdownListProps {
  active: boolean;
  lang: any;
}
export const DropdownList = styled.ul<DropdownListProps>`
  background: white;
  box-shadow: 5px 5px 25px -18px #000000;
  font-family: "open_sanssemibold";
  position: fixed;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  width: 360px;
  overflow-x: hidden;
  max-height: 80vh;
  overflow-y: scroll;
  color: #91a9c9;

  ${({ active, lang }) =>
    active && lang === "ar"
      ? css`
          left: 2rem;
        `
      : active && lang !== "ar"
      ? css`
          right: 2rem;
        `
      : css`
          display: none;
        `};

  ${({ theme }) => theme.languageSwitcherMenuList.style}
  ${({ theme }) => theme.device.tablet} {
    ${({ theme }) => theme.languageSwitcherMenuList.tabletStyle}
  }
  ${({ theme }) => theme.device.desktop} {
    ${({ theme }) => theme.languageSwitcherMenuList.desktopStyle}
  }
`;
