import { Route, RouteProps } from "react-router-dom";
import { Header } from "./components";
import Footer from "./components/Footer";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { I18n } from "react-redux-i18n";
import { ComponentType } from "react";
import { FunctionComponent } from "react";
import { RootState } from "./interfaces";

interface PublicRoutesProps extends RouteProps {
  component: ComponentType<any>;
}

const PublicRoutes: FunctionComponent<PublicRoutesProps> = ({
  component: Component,
  ...rest
}) => {
  const location = useLocation();
  const pages = useSelector((state: RootState) => state.pages.data);

  const linkedin = useSelector(
    (state: RootState) => state.compagnyDetails.data.linkedin
  );
  const instagram = useSelector(
    (state: RootState) => state.compagnyDetails?.data.instagram
  );
  const facebook = useSelector(
    (state: RootState) => state.compagnyDetails.data.facebook
  );

  return (
    <>
      {location.pathname !== "/login" && <Header pages={pages} />}

      <Route {...rest} render={(props: any) => <Component {...props} />} />
      {location.pathname !== "/home" && location.pathname !== "/login" ? (
        <Footer
          linkedin={linkedin}
          instagram={instagram}
          facebook={facebook}
          copyright={I18n.t("COPYRIGHT")}
        />
      ) : null}
    </>
  );
};

export default PublicRoutes;
