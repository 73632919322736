import styled from "styled-components";

const FooterContent = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 30px;
  align-items: center;
  gap: 1rem;

  ${({ theme }) => theme.footerHomeContent.style}
  ${({ theme }) => theme.device.tablet} {
    ${({ theme }) => theme.footerHomeContent.tabletStyle}
  }
  ${({ theme }) => theme.device.desktop} {
    ${({ theme }) => theme.footerHomeContent.desktopStyle}
  }
`;
export default FooterContent;
