import styled from "styled-components";

const Separator = styled.span`
  display: block;
  width: 1px;
  height: 16px;
  ${({ theme }) => theme.headerSeparator.style};
`;

export default Separator;
