import styled, { css } from "styled-components";

interface InputTextProps {
  error?: string;
  touched?: boolean;
  errorServer?: string;
  responsive?: boolean;
}
const inputDefaultStyle = css`
  position: relative;
  font-family: "robotoregular";
  color: rgba(115, 119, 125, 1);
  width: 100%;
  font-size: 1.6rem;
  padding: 1rem 2.5rem;
  line-height: 1;
  border-radius: 50px;
  outline: 0;
  border: 2px solid;
`;
const StyledInputText = styled.input<InputTextProps>`
  ${inputDefaultStyle}
  ${({ theme }) => theme.input.style}
  ${({ theme }) => theme.device.tablet} {
    ${({ theme, responsive }) => responsive && theme.input.tabletStyle}
  }
  ${({ theme }) => theme.device.desktop} {
    ${({ theme, responsive }) => responsive && theme.input.desktopStyle}
  }
  ${({ error, touched, errorServer = "" }) =>
    error && touched
      ? css`
          ${({ theme }) => theme.input.errorStyle};
        `
      : errorServer
      ? css`
          ${({ theme }) => theme.input.errorServerStyle};
        `
      : null}
`;

const InputText = ({
  field,
  form: { touched, errors },
  ...props
}: InputTextProps & { field: any; form: any }) => {
  return (
    <StyledInputText
      type="text"
      error={errors[field.name]}
      touched={touched[field.name]}
      {...field}
      {...props}
    />
  );
};
export default InputText;
