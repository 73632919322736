import { useMediaQuery } from "react-responsive";

const PhoneIcon = () => {
  const isTablet = useMediaQuery({ query: "(min-width:768px)" });
  const isDesktop = useMediaQuery({ query: "(min-width:992px)" });

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={isDesktop ? "36" : isTablet ? "30" : "25"}
      height={isDesktop ? "36" : isTablet ? "30" : "25"}
      fill="currentColor"
      className="bi bi-phone-fill"
      viewBox="0 0 16 16"
    >
      <path d="M3 2a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V2zm6 11a1 1 0 1 0-2 0 1 1 0 0 0 2 0z" />
    </svg>
  );
};
export default PhoneIcon;
