import { NavLink } from "react-router-dom";
import LoginIcon from "../../assets/icons/LoginIcon";

const MyLoginButton = () => {
  return (
    <NavLink to="/login">
      <LoginIcon />
    </NavLink>
  );
};
export default MyLoginButton;
