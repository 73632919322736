import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  html {
    font-size: 62.5%;
  }
  body {
    color: #FFFFFF;
    font-family: 'robotoregular';
  }
  a {
    text-decoration: none;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-bottom: 0;
    li {
      margin: 0;
      padding: 0;
    }
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
  margin: 0;
  margin-bottom: 0;
}
img {
    max-width: 100%;
}
`;

export default GlobalStyles;
