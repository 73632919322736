import styled from "styled-components";

const StyledIcon = styled.span<{ id?: any }>`
  display: block;
  margin-left: auto;
  cursor: pointer;
  color: #73777d;
  text-align: end;
  svg {
    width: 13px;
  }
`;

export default StyledIcon;
