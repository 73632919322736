import styled from "styled-components";

const StyledImage = styled.img`
  width: 100%;
`;
type MyImageProps = {
  src: string;
  alt: string;
};
function MyImage({ src, alt }: MyImageProps) {
  return <StyledImage src={src} alt={alt} />;
}
export default MyImage;
