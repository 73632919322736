import styled from "styled-components";

const ButtonEditMode = styled.button`
font-family: "be_vietnammedium",
text-align:center;
cursor: pointer;
text-decoration: none;
border: 1.5px solid;
border-color: transparent;
padding: .8rem;
font-size: 1rem;
line-height: 1;
border-radius: 0.38rem;
transition: all 0.2s;
${({ theme }) => theme.buttonSecondary.style};
`;
export default ButtonEditMode;
