const translations = {
  en: {
    greeting: "Hello!",
    goodbye: "Goodbye!",
    comments: {
      title: "%{count} comments",
      title_0: "%{count} comments",
      title_1: "%{count} comment",
    },
    french: "French",
    english: "English",
    arabic: "Arabic",
    webdesign: "WEB DESIGN",

    pages: {
      home: "HOME",
      services: "SERVICES",
      portfolio: "PORTFOLIO",
      testimonials: "TESTIMONIALS",
      contact: "CONTACT",
    },
    banner: {
      title: "CREATION OF CUSTOM WEBSITES AND MOBILE APPLICATIONS",
      content:
        "Do you want to promote your project and improve your online Visibility? Would you like to create a Website or develop a Mobile Application? Webify employs an experienced and specialized team of motivated experts mastering the latest technologies to create Websites and Mobile Applications that are easy to use and attractive for visitors.",
      btn: "I discover",
    },
    expertises: {
      title: "OUR EXPERTISES",
      btn: "I discover",
      responsive_design: "RESPONSIVE DESIGN",
      advice: "TIPS",
      cms: "CMS",
      developpement: "DEVELOPPEMENT",
      web_design: "WEB DESIGN",
    },
    packs: {
      title: "OUR SERVICES AND PACKS",
      btn: "I discover",
      pack_vitrine: "SHOWCASE WEBSITE PACK",
      pack_vitrine_content:
        "Webify can create permanent Internet Strategies through the vast modularity of its developments. Via the showcase Website Pack that we offer, your website gives online users a clear idea of your brand offerings. ",
      pack_ecommerce: "E-COMMERCE SITE PACK",
      pack_ecommerce_content:
        "We aim to provide you with the best solution according to your needs and expectations with regard to web reference, and the promotion of your e-commerce website. Through the multiple functionalities that we will integrate into your site, you will be able to manage the front-end and the back-end.",
      pack_app_mobile: "MOBILE APP PACK",
      pack_app_mobile_content:
        "Do you want to launch your own app? Webify is a real reference in the creation and development of mobile applications. We offer you a multitude of features which aim to provide your application with great interactivity.",
      pack_custom_made: "TAILOR-MADE PACK",
      pack_custom_made_content:
        "A whole team supports you in choosing the best digital strategies to get a perfect harmony between your project and your objectives.",
    },
    portfolio: {
      title: "OUR PORTFOLIO",
      title2: "LATEST CREATIONS",
    },
    filter: {
      ALL: "ALL",
      PROGRESS: "In progress",
      FINISHED: "Finished",
      FILTERS: "Filters",
      TYPE_SITE: "WEBSITE TYPE",
    },
    newsletter: {
      title: "Subscribe to our newsletter",
      titleSecondary: "NEWSLETTER",
      placeholder: "ENTER YOUR EMAIL",
      placeholderError: "Are you already registered",
    },
    contact: {
      title: "CONTACT US",
      text: "A project on the web? Want to create a website? Need a social media strategy? Contact us!",
      btn: "Send",
      successSend: {
        title: "PACK VITRINE",
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ncididunt ut labore et dolore magna aliqua. Ut enim ad Lorem i !",
        msg: "Your quote request has been sent Thank you for your interest, we will get back to you by email!",
      },
    },
    compagnyDetails: {
      address_compagny: "Building jaziri Boulevard 14 January, Hammam Sousse",
      phone_compagny: "00216 25 23 43 69",
    },
    input: {
      yourname: "Your name",
      youremail: "Your email",
      yourpassword: "Your password",
      yourmsg: "Your message",
      choose_picture: "Choose a picture",
    },
    inputError: {
      tooshort: "too short",
      toolong: "too long",
      required: "This field is required",
      wrongemail: "Wrong email",
    },
    testimonials: {
      title: "REVIEWS FROM OUR CUSTOMERS",
      add_comment: "add a comment",
      last_comments: "Last comments",
      btn: "Send",
      discover_us: "I discover",
      successSend: "Your comment has been sent Thank you for your interest !",
    },
    services: {
      card_title: "SHOWCASE PACK",
      card_text:
        "Since we live in an ultra-connected world, the creation of a website is no longer an advantage but rather an obligation to increase your brand visitors. We are committed to manage your entire project at competitive pricing. By creating your own showcase site, we improve the image of your company as well as your business.",
      stepper_title: "SHOWCASE PACK",
      type_website: "Which pack do you want to choose?",
      design_website:
        "What are your expectations about the design of your website ?",
      payment_website: "Will your site allow payments ?",
      features_website: "What features do you want to have ?",
      hosting_website: "How to host your website ?",
      maintenance_website: "How will you maintain your site ?",
      budget_website: "What’s Your Budget for Your Website ?",
      services_website: "Do you want to add other services ?",
      languages_website:
        "Do you want to make your website available in multiple languages ?",
      last_question: "Fill in this form to get a price estimate ?",
      input: {
        fullname: "Name",
        phone: "Phone number",
        email: "E-mail",
        compagnyType: "Type of your business",
        compagnyName: "Compagny Name",
        postalCode: "Postal code",
        description: "Please describe Briefly your project",
      },
      submitBtn: "Send quote request",
      successSend: {
        title: "PACK VITRINE",
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ncididunt ut labore et dolore magna aliqua. Ut enim ad Lorem i !",
        msg: "Your quote request has been sent Thank you for your interest, we will get back to you by email!",
      },
    },
    login: {
      log_in: "LOG IN",
      btn: "Login",
    },
    signout: "Sign out",
    dashboard: {
      title: "Dashboard",
      showsite: "Show site",
      oneweek: "One week",
      threeweeks: "3 weeks",
      onemonth: "1 month",
      inprogress: "In progress",
      valid: "Valid",
      treat: "Treat",
      projects: "Projects",
      newsletters: "Newsletters",
      estimates: "Estimates",
      comments: "Comments",
      contacts: "Contacts",
      seeprojects: "See projects",
      seenewsletters: "See newsletters",
      seeestimates: "See estimates",
      seecomments: "See comments",
      seecontacts: "See contacts",
    },
    sidebardashboard: {
      dashboard: "DASHBOARD",
      quoteRequest: "QUOTE REQUEST",
      portfolio: "PORTFOLIO",
      comments: "COMMENTS",
      newsletters: "NEWSLETTERS",
      contacts: "CONTACTS",
      accounts: "ACCOUNTS",
      settings: "SETTINGS",
    },
    actionsdashboard: {
      show: "Show",
      validate: "Validate",
      delete: "Delete",
      edit: "Edit",
      publish: "Publish",
    },
    devisDashboard: {
      title: "Quote list",
      subtitle: "Quote",
      add_devis: "Add",
      name: "Full name",
      email: "Email",
      phone: "Phone",
      type: "Type",
      actions: "Actions",
      quote_request: "Quote request n°",
      reference: "Reference",
      compagny_name: "Compagny name",
      compagny_type: "Compagny type",
      yes: "Yes",
      no: "No",
      date_request: "Date request",
      description: "description",
      type_website: "type of website",
      budget: "budget",
      design: "design",
      payment: "payment",
      features: "Requested features",
      maintenance: "maintenance",
      hosting: "hosting",
      multiple_languages: "multiple languages",
      additional_features: "additional features",
      done: "Done",
      in_progress: "In progress",
    },
    newslettersDashboard: {
      title: "Newsletters",
      subtitle: "List of subscribers",
      email: "Email",
      date: "send date",
    },
    contactsDashboard: {
      title: "Contact",
      subtitle: "Contact list",
      name: "Name",
      email: "Email",
      message: "Message",
      date: "send date",
    },
    commentsDashboard: {
      title: "Comments",
      subtitle: "comments list",
      name: "Name",
      email: "Email",
      message: "Message",
      thumbnail: "Thumbnail",
      statut: "Statut",
      skill: "skill",
      date: "send date",
      actions: "Actions",
    },
    portfolioDashboard: {
      title: "Projects",
      subtitle: "List projects",
      name: "Name",
      description: "Description",
      statut: "Statut",
      date: "start date",
      actions: "Actions",
      reference: "Reference",
      projectName: "Project name",
      projectType: "Project type",
      featuredImage: "Featured image",
      galleries: "Galleries",
      listProjects: "List projects",
      addProject: "Add project",
      editProject: "Edit project",
      nameProject: "Name project",
      descriptionProject: "Description project",
      typeProject: "Type project",
      send: "Send",
      successSend: "project has been successfully added!",
    },
    accountDashboard: {
      title: "Accounts",
      subtitle: "List accounts",
      email: "Email",
      roles: "Roles",
      actions: "Actions",
      add: "Add",
      listAccounts: "List accounts",
      addAccount: "Add account",
      youremail: "Your email",
      yourpassword: "Your password",
      send: "Send",
    },
    packsDashboard: {
      title: "Packs",
      subtitle: "List packs",
      name: "Name",
      description: "Description",
      thumbnail: "Thumbnail",
    },
    online: "Online",
    offline: "Offline",
    nodata: "no data",
    modalDelete: {
      title: "Do you confirm the deletion ?",
      msg: "If you confirm, your file will be permanently erased",
      delete: "Delete",
      cancel: "Cancel",
    },
    button: {
      delete: "Delete",
      cancel: "Cancel",
      add: "Add",
    },
    alert: {
      delete: "Are you sure you want to delete:",
    },
    COPYRIGHT: "© 2022 WEBIFY TECHNOLOGY. All rights reserved.",
  },
  fr: {
    greeting: "Bonjour!",
    goodbye: "Au revoir!",
    comments: {
      title: "%{count} commentaires",
      title_0: "%{count} commentaires",
      title_1: "%{count} commentaire",
    },
    french: "Français",
    english: "Anglais",
    arabic: "Arabe",
    pages: {
      home: "ACCUEIL",
      services: "SERVICES",
      portfolio: "PORTFOLIO",
      testimonials: "TÉMOIGNAGES",
      contact: "CONTACT",
    },
    banner: {
      title: "CRÉATION DE SITES WEB ET APPLICATION MOBILES SUR MESURE",
      content:
        "Vous voulez promouvoir votre projet et augmenter votre visibilité en ligne? Vous souhaitez créer un site web ou développer une application mobile? Webify met à votre disposition toute une équipe d’experts talentueux et motivés maîtrisant les dernières technologies pour prendre en charge cette mission.",
      btn: "Je découvre",
    },
    expertises: {
      title: "NOS EXPERTISES",
      btn: "Voir tous !",
      responsive_design: "RESPONSIVE DESIGN",
      advice: "CONSEILS",
      cms: "CMS",
      developpement: "DÉVELOPPEMENT",
      web_design: "WEB DESIGN",
    },
    packs: {
      title: "NOS SERVICES ET PACKS",
      btn: "Je découvre",
      pack_vitrine: "PACK VITRINE",
      pack_vitrine_content:
        "Webify assure la création des stratégies Internet permanentes par le biais de la vaste modularité de ses développements. Via le pack site vitrine que nous vous proposons, votre site web sera capable de diffuser l’image de votre société dans les quatre coins du monde. ",
      pack_ecommerce: "PACK E-COMMERCE",
      pack_ecommerce_content:
        "Nous visons à vous apporter la meilleure solution selon vos besoins et attentes en ce qui concerne le référencement web, et la promotion de votre site e-commerce. Par les multiples fonctionnalités que nous allons intégrer à votre site, vous serez apte à assurer la gestion de tout ce qui concerne le front-end et le back-end.",
      pack_app_mobile: "PACK APPLICATION MOBILE",
      pack_app_mobile_content:
        "Voulez-vous lancer votre propre application? Webify est une véritable référence  au niveau de la création et du développement des applications mobiles. Nous vous offrons une multitude de fonctionnalités dont le but est de doter votre application d’une très grande interactivité.",
      pack_custom_made: "PACK SUR MESURE",
      pack_custom_made_content:
        "Toute une équipe vous accompagne dans le choix des meilleures stratégies numériques à appliquer pour une parfaite harmonie entre votre projet et vos objectifs.",
    },
    portfolio: {
      title: "NOTRE PORTFOLIO",
      title2: "NOS DERNIERS RÉALISATIONS",
    },
    filter: {
      ALL: "TOUS",
      PROGRESS: "En cours",
      FINISHED: "Fini",
      FILTERS: "Filtres",
      TYPE_SITE: "TYPE DU SITE",
    },

    newsletter: {
      title: "INSCRIVEZ-VOUS À NOTRE",
      titleSecondary: "NEWSLETTER",
      placeholder: "SAISISSEZ VOTRE E-MAIL",
      placeholderError: "Vous êtes déjà inscrit",
    },
    contact: {
      title: "CONTACTEZ-NOUS",
      text: "Un projet sur le web? Une envie de création de site web ? Besoin d’une stratégie sur les réseaux sociaux? Contactez nous !",
      btn: "Envoyer",
      successSend: {
        title: "PACK VITRINE",
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ncididunt ut labore et dolore magna aliqua. Ut enim ad Lorem i !",
        msg: "Votre demande de devis a été bien envoyée Merci de votre intéret nous reviendrons vers vous par mail !",
      },
    },
    compagnyDetails: {
      address_compagny: "Immeuble jaziri Boulevard 14 janvier, Hammam Sousse",
      phone_compagny: "00216 25 23 43 69",
    },
    input: {
      yourname: "Votre nom",
      youremail: "Votre email",
      yourpassword: "Votre mot de passe",
      yourmsg: "Votre message",
      choose_picture: "Choisir une image",
    },
    submitBtn: "Envoyer la demande de devis",
    inputError: {
      tooshort: "trop court",
      toolong: "trop long",
      required: "Ce champ est obligatoire",
      wrongemail: "mauvais email",
    },
    testimonials: {
      title: "AVIS DE NOS CLIENTS",
      add_comment: "Ajouter un avis",
      last_comments: "Nos derniers avis",
      choose_picture: "Choisir une image",
      btn: "Envoyer",
      discover_us: "Voir plus !",
      successSend:
        "Votre commentaire a été bien envoyée Merci de votre intéret !",
    },
    services: {
      card_title: "PACK VITRINE",
      card_text:
        "Puisque nous vivons dans un monde ultra-connecté, la création d’un site web n’est plus un avantage mais plutôt une obligation pour pouvoir s’imposer dans l’univers digital. Nous nous engageons à gérer l’intégralité de votre projet à des tarifs compétitifs. En vous créant votre propre site vitrine, nous vous garantissons une amélioration de l’image de votre société tout comme votre business.",
      stepper_title: "PACK VITRINE",
      type_website: "Quel type de pack souhaitez-vous choisir ?",
      design_website:
        "Quelles sont vos attentes par rapport au design de votre site ?",
      payment_website: "Votre site permettra t-il  d’effectuer des paiements?",
      features_website: "Quelles fonctionnalités voulez-vous avoir ?",
      hosting_website: " Comment héberger votre site web ?",
      maintenance_website:
        "Comment voulez-vous assurer la maintenance de votre site ?",
      budget_website:
        "Quel budget avez-vous consacré à la création de votre site web?",
      services_website: "Voulez-vous ajouter d’autres prestations?",
      languages_website:
        "Voulez-vous que votre site soit disponible en plusieurs langues ?",
      last_question:
        "Remplissez ce formulaire avant de passer à l’estimation de prix:",
      input: {
        fullname: "Nom et Prénom",
        phone: "Téléphone",
        email: "Adresse e-mail",
        compagnyType: "Type de votre entreprise",
        compagnyName: "Nom de l'entreprise",
        postalCode: "Code postal",
        description: "Présentez brièvement votre projet",
      },
      submitBtn: "Envoyer la demande de devis",
      successSend: {
        title: "PACK VITRINE",
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ncididunt ut labore et dolore magna aliqua. Ut enim ad Lorem i !",
        msg: "Votre demande de devis a été bien envoyée Merci de votre intéret nous reviendrons vers vous par mail !",
      },
    },
    login: {
      log_in: "SE CONNECTER",
      btn: "Se connecter",
    },
    signout: "Se déconnecter",
    dashboard: {
      title: "Tableau de bord",
      showsite: "Voir site",
      oneweek: "Une semaine",
      threeweeks: "3 semaines",
      onemonth: "1 mois",
      inprogress: "En cours",
      valid: "Valider",
      treat: "À traiter",
      projects: "Projets",
      newsletters: "Newsletters",
      estimates: "Devis",
      comments: "Commentaires",
      contacts: "Contacts",
      seeprojects: "Voir les projets",
      seenewsletters: "Voir les newsletters",
      seeestimates: "Voir les devis",
      seecomments: "Voir les commentaires",
      seecontacts: "Voir les contacts",
    },
    sidebardashboard: {
      dashboard: "TABLEAU DE BORD",
      quoteRequest: "DEMANDE DE DEVIS",
      portfolio: "PORTFOLIO",
      comments: "COMMENTAIRES",
      newsletters: "NEWSLETTERS",
      contacts: "CONTACTS",
      accounts: "COMPTES",
      settings: "PARAMÉTRES",
    },
    actionsdashboard: {
      show: "Afficher",
      validate: "Valider",
      delete: "Supprimer",
      edit: "Editer",
      publish: "Publier",
    },
    devisDashboard: {
      title: "List de devis",
      subtitle: "devis",
      add_devis: "Ajouter",
      name: "Nom et Prénom",
      email: "Email",
      phone: "Téléphone",
      type: "Type",
      actions: "Actions",
      quote_request: "Demande de devis n°",
      reference: "Référence",
      compagny_name: "Nom d'entreprise",
      compagny_type: "Type d'entreprise",
      yes: "Oui",
      no: "Non",
      date_request: "Date d'envoie",
      description: "description",
      type_website: "type de site",
      budget: "budget",
      design: "design",
      payment: "paiement",
      features: "Fonctionnalités demandées",
      maintenance: "maintenance",
      hosting: "Hébergement",
      multiple_languages: "Multilangues",
      additional_features: "Prestations",
      done: "Valider",
      in_progress: "En cours",
    },
    newslettersDashboard: {
      title: "Newsletters",
      subtitle: "Liste des abonnés",
      email: "Email",
      date: "date d'envoie",
    },
    contactsDashboard: {
      title: "Contact",
      subtitle: "liste des contacts",
      name: "Nom",
      email: "Email",
      message: "Message",
      date: "date d'envoie",
    },
    commentsDashboard: {
      title: "Commentaires",
      subtitle: "liste de commentaires",
      name: "Nom",
      email: "Email",
      message: "Message",
      thumbnail: "vignette",
      statut: "Statut",
      skill: "skill",
      date: "date d'envoie",
      actions: "Actions",
    },
    portfolioDashboard: {
      title: "Projets",
      subtitle: "list de projets",
      name: "Nom",
      description: "Description",
      statut: "Statut",
      date: "Date de début",
      actions: "Actions",
      reference: "Référence",
      projectName: "Nom du projet",
      projectType: "Type du projet",
      featuredImage: "Image de mise en avant",
      galleries: "Galleries",
      listProjects: "Liste des projets",
      addProject: "Ajouter un projet",
      editProject: "Editer un projet",
      nameProject: "Nom du project",
      descriptionProject: "Description du projet",
      typeProject: "Type du projet",
      send: "Envoyer",
      successSend: "Votre projet a été ajouté avec succès !",
    },
    accountDashboard: {
      title: "Comptes",
      subtitle: "Liste des comptes",
      email: "Email",
      roles: "Roles",
      actions: "Actions",
      add: "Ajouter",
      listAccounts: "List des comptes",
      addAccount: "Ajouter un compte",
      youremail: "Votre email",
      yourpassword: "Votre mot de passe",
      send: "Envoyer",
    },
    packsDashboard: {
      title: "Packs",
      subtitle: "List des packs",
      name: "Nom",
      description: "Description",
      thumbnail: "Thumbnail",
    },
    online: "En ligne",
    offline: "Hors ligne",
    modalDelete: {
      title: "Confirmez-vous la suppression ?",
      msg: "Si vous confirmez, votre dossier sera définitivement effacé",
      delete: "Supprimer",
      cancel: "Annuler",
    },
    nodata: "pas de données",
    button: {
      delete: "Supprimer",
      cancel: "Annuler",
      add: "Ajouter",
    },
    alert: {
      delete: "Etes-vous sûr que vous voulez supprimer:",
    },
    COPYRIGHT: "© 2022 WEBIFY TECHNOLOGY. Tous droits réservés.",
  },
  ar: {
    greeting: "مرحبًا!",
    goodbye: "مع السلامة!",
    comments: {
      title: "%{count} تعليقات",
      title_0: "%{count} تعليقات",
      title_1: "%{count} تعليقات",
    },
    french: "الفرنسية",
    english: "الإنجليزية",
    arabic: "العربية",
    pages: {
      home: "الصفحة الرئيسية",
      services: "خدماتنا",
      portfolio: "أعمالنا",
      testimonials: "شهادات عملائنا",
      contact: "تواصل معنا",
    },
    banner: {
      title: "إنشاء مواقع الويب المخصصة وتطبيقات الهاتف المحمول",
      content:
        "هل ترغب في الترويج لمشروعك وزيادة ظهورك على الإنترنت؟ هل تريد إنشاء موقع على شبكة الإنترنت أو تطوير تطبيق الهاتف المحمول؟ توفر لك شركة  Webify فريقًا كاملاً من المطورين من ذوي الخبرات العالية الذين يتقنون التعامل مع أحدث التقنيات لتنفيذ مشروعك. ",
      btn: "اكتشفنا !",
    },
    expertises: {
      title: "خبرتنا",
      btn: "اكتشفنا !",
      responsive_design: "التصميم المستجيب",
      advice: "النصيحة",
      cms: "نظام إدارة المحتوى",
      developpement: "تطوير",
      web_design: "تصميم مواقع الويب",
    },
    packs: {
      title: "خدماتنا وباقاتنا",
      btn: "قراءة المزيد",
      pack_vitrine: "باقة خدمات إنشاء واجهة موقع إلكتروني",
      pack_vitrine_content:
        "تضمن لك شركة Webify إنشاء استراتيجيات إلكترونية دائمة من خلال معايير تطويرية متنوعة. من خلال باقة خدمات إنشاء واجهة إلكترونية لموقعك، سيمكن موقع الويب الخاص بك من نشر صورة شركتك في جميع أنحاء العالم.",
      pack_ecommerce: "باقة خدمات إنشاء موقع للتجارة الإلكترونية",
      pack_ecommerce_content:
        "نحن نهدف إلى تقديم أفضل الحلول بما يتناسب مع احتياجاتك وتوقعاتك فيما يتعلق بتحسين نتائج محركات البحث، فضلا عن الترويج لموقع التجارة الإلكترونية الخاص بك. من خلال الوظائف المتعددة التي سنقوم بإدراجها في موقعك، ستكون قادرًا على إدارة كل ما يتعلق يالجهتان الأمامية والخلفية للموقع. ",
      pack_app_mobile: "باقة خدمات تطوير تطبيقات الهاتف المحمول",
      pack_app_mobile_content:
        "هل تريد إطلاق تطبيقك الخاص؟ شركة Webify تعتبر مرجعا حقيقيا في مجال إنشاء وتطوير تطبيقات الهاتف المحمول. نقدم لك العديد من الميزات التي تهدف إلى تزويد تطبيقك بتفاعلية رائعة.",
      pack_custom_made: "باقة خدمات حسب الطلب",
      pack_custom_made_content:
        "فريق Webify على ذمتك من أجل اختيار أفضل الاستراتيجيات الرقمية بُغية تحقيق انسجام تام بين مشروعك وأهدافك.",
    },
    portfolio: {
      title: "أعمالنا",
      title2: "أحدث إنجازاتنا",
    },
    filter: {
      ALL: "الكل",
      PROGRESS: "قيد المعالجة",
      FINISHED: "انتهى",
      FILTERS: "تصفية البيانات ",
      TYPE_SITE: "نوع الموقع",
    },
    newsletter: {
      title: "اشترك في نشرتنا الإخبارية",
      titleSecondary: "رسالة إخبارية",
      placeholder: "أدخل بريدك الإلكتروني",
      placeholderError: "انت مسجل",
    },
    contact: {
      title: "اتصل بنا",
      text: "مشروع على الويب؟ تريد إنشاء موقع على شبكة الإنترنت؟ بحاجة الى استراتيجية وسائل الاعلام الاجتماعية؟ اتصل بنا!",
      btn: "إرسال",
      successSend: {
        title: "عرض حزمة",
        text: "لا يجوز تعريض أحد لتدخل تعسفي في حياته الخاصة أو في شؤون أسرته أو مسكنه أو مراسلاته، ولا لحملات تمس شرفه وسمعته. ولكل شخص حق في أن يحميه القانون من مثل ذلك التدخل أو تلك الحملات.",
        msg: "تم إرسال طلب عرض الأسعار الخاص بك شكرًا لك على اهتمامك ، وسنعاود الاتصال بك عبر البريد الإلكتروني!",
      },
    },
    compagnyDetails: {
      address_compagny: "عمارة الجزيري بوليفارد 14 يناير ، حمام سوسة",
      phone_compagny: "٠٠٢١٦٢٥٢٣٤٣٦٩",
    },
    input: {
      yourname: "اسمك",
      youremail: "بريدك الالكتروني",
      yourpassword: "كلمة السر",
      yourmsg: "رسالتك",
      choose_picture: "اختيار صورة",
    },
    inputError: {
      tooshort: "قصير جدا",
      toolong: "طويل جدا",
      required: "هذه الخانة مطلوبه",
      wrongemail: "بريد إلكتروني خاطئ",
    },
    testimonials: {
      title: "آراء عملائنا",
      add_comment: "إضافة تعليق",
      last_comments: "آخر التعليقات",
      choose_picture: "اختيار صورة",
      btn: "إرسال",
      discover_us: "مشاهدةالكل !",
      successSend: "تم إرسال تعليقك شكرا لك على اهتمامك!",
    },
    services: {
      card_title: "باقة خدمات إنشاء واجهة موقع الويب",
      card_text:
        "نظرًا لأننا نعيش في عالم شديد الاتصال، فإن إنشاء موقع ويب لم يعد امتيازا، وإنما ضرورة من أجل القدرة على التموقع في العالم الرقمي. نحن ملتزمون بإدارة مشروعك بالكامل بأسعار تنافسية. من خلال إنشاء موقع واجهة موقعك الإلكتروني، فإننا نضمن تحسين صورة شركتك وكذلك عملك وبالتالي مداخيلك.",
      stepper_title: "باقة خدمات إنشاء واجهة موقع الويب",
      type_website: "أي نوع من باقات الخدمات تريد أن تختار?",
      design_website: "كيف تريد أن يكون تصميم موقعك؟",
      payment_website: "هل سيسمح موقعك بسداد المدفوعات?",
      features_website: "ما هي الخصائص التشغيلية التي تريد دمجها في موقعك؟",
      hosting_website:
        "ما هي طريقة استضافة موقع الويب  الخاص بك أو تخزين البيانات التي تريد اعتمادها؟",
      maintenance_website: "كيف تنوي صيانة موقعك؟",
      budget_website: "ما الميزانية التي خصصتها لإنشاء موقع الويب الخاص بك؟",
      services_website: "هل تحتاج أيضًا إلى الخدمات التالية؟",
      languages_website: "هل تريد أن يكون موقعك متاحًا بلغات متعددة؟",
      last_question: "املأ بيانات هذه الاستمارة قبل الحصول على سعر تقديري:",
      input: {
        fullname: "الاسم و اللقب",
        phone: "رقم الهاتف",
        email: "البريد الإلكتروني",
        compagnyType: "نوع الشركة",
        compagnyName: "اسم الشركة",
        postalCode: "الرمز بريدي",
        description: "تحدّث عن مشروعك بإيجاز",
      },
      submitBtn: "إرسال طلب عرض الأسعار",
      successSend: {
        title: "عرض حزمة",
        text: "لا يجوز تعريض أحد لتدخل تعسفي في حياته الخاصة أو في شؤون أسرته أو مسكنه أو مراسلاته، ولا لحملات تمس شرفه وسمعته. ولكل شخص حق في أن يحميه القانون من مثل ذلك التدخل أو تلك الحملات.",
        msg: "تم إرسال طلب عرض الأسعار الخاص بك شكرًا لك على اهتمامك ، وسنعاود الاتصال بك عبر البريد الإلكتروني!",
      },
    },
    login: {
      log_in: "تسجيل الدخول",
      btn: "تسجيل ",
    },
    signout: "تسجيل الخروج",
    dashboard: {
      title: "لوحة القيادة",
      showsite: "انظر الموقع",
      oneweek: "أسبوع",
      threeweeks: "3 أسبوع",
      onemonth: "1 شهر",
      inprogress: "الجاري",
      valid: "منتهي",
      treat: "للتحليل",
      projects: "المشاريع",
      newsletters: "النشرات الإخبارية",
      estimates: "التسعير",
      comments: "تعليقات",
      contacts: "الاتصال",
      seeprojects: "شاهد المشاريع",
      seenewsletters: "انظر النشرة الإخبارية",
      seeestimates: "انظر الاقتباسات",
      seecomments: "انظر تعليقات",
      seecontacts: "انظر الاتصال",
    },
    sidebardashboard: {
      dashboard: "لوحة القيادة",
      quoteRequest: "طلب اقتباس",
      portfolio: "مَلَفّ",
      comments: "تعليقات",
      newsletters: "النشرات الإخبارية",
      contacts: "جهات الاتصال",
      accounts: "حسابات",
      settings: "إصلاحات",
    },
    actionsdashboard: {
      show: "عرض",
      validate: "موافق",
      delete: "يمسح",
      edit: "تعديل",
      publish: "ينشر",
    },
    devisDashboard: {
      title: "قائمة الاقتباس",
      subtitle: "الاقتباس",
      add_devis: "أضف",
      name: "الإسم واللقب",
      email: "بريد إلكتروني",
      phone: "هاتف",
      type: "نوع الخدمة",
      actions: "أجراءات",
      quote_request: "رقم طلب الاقتباس",
      reference: "مرجع",
      compagny_name: "اسم الشركة",
      compagny_type: "نوع الشركة",
      yes: "نعم",
      no: "لا",
      date_request: "تاريخ إرسالها",
      description: "وصف",
      type_website: "نوع الموقع",
      budget: "ميزانية",
      design: "تصميم",
      payment: "قسط",
      features: "الميزات المطلوبة",
      maintenance: "صيانة",
      hosting: "موقع استضافة",
      multiple_languages: "متعدد اللغات",
      additional_features: "الخدمات المطلوبة",
      done: "موافق",
      in_progress: "الجاري",
    },
    newslettersDashboard: {
      title: "النشرات الإخبارية",
      subtitle: "قائمة المشتركين",
      email: "بريد إلكتروني",
      date: "تاريخ إرسال",
    },
    contactsDashboard: {
      title: "الإتصال",
      subtitle: "قائمة الإتصال",
      name: "اسم",
      email: "بريد إلكتروني",
      message: "رسالة",
      date: "تاريخ إرسال",
    },
    commentsDashboard: {
      title: "تعليقات",
      subtitle: "قائمة التعليقات",
      name: "اسم",
      email: "بريد إلكتروني",
      message: "رسالة",
      thumbnail: "صورة",
      statut: "ينشر",
      skill: "ردود الفعل",
      date: "تاريخ إرسال",
      actions: "أجراءات",
    },
    portfolioDashboard: {
      title: "المشاريع",
      subtitle: "قائمة المشاريع",
      name: "اسم",
      description: "وصف",
      statut: "ينشر",
      date: "تاريخ البدء",
      actions: "أجراءات",
      reference: "مرجع",
      projectName: "اسم المشروع",
      projectType: "نوع المشروع",
      featuredImage: "الصورة المميزة",
      galleries: "معارض الصور",
      listProjects: "قائمة المشاريع",
      addProject: "أضف مشروعا",
      editProject: "تعديل المشروع",
      nameProject: "اسم المشروع",
      descriptionProject: "وصف المشروع",
      typeProject: "نوع المشروع",
      send: "أرسل",
      successSend: "تمت إضافة المشروع الجديد بنجاح!",
    },
    accountDashboard: {
      title: "حسابات شخصية",
      subtitle: "قائمة الحسابات الشخصية",
      email: "بريد إلكتروني",
      roles: "الأدوار",
      actions: "أجراءات",
      add: "يضيف",
      listAccounts: "قائمة الحسابات الشخصية",
      addAccount: "أضف حسابًا شخصيًا",
      youremail: "بريدك الإلكتروني",
      yourpassword: "كلمة السر الخاصة بك",
      send: "أرسل",
    },
    packsDashboard: {
      title: "باقاتنا",
      subtitle: "خدماتنا وباقاتنا",
      name: "اسم",
      description: "وصف",
      thumbnail: "صورة",
    },
    online: "متصل",
    offline: "غير متصل",
    nodata: "لايوجد بيانات",
    modalDelete: {
      title: "هل تؤكد الحذف؟",
      msg: "إذا قمت بالتأكيد ، فسيتم مسح ملفك نهائيًا",
      delete: "يمسح",
      cancel: "يلغي",
    },
    button: {
      delete: "يمسح",
      cancel: "يلغي",
      add: "يضيف",
    },
    alert: {
      delete: "هل أنت متأكد أنك تريد حذف:",
    },
    COPYRIGHT: "© وبيفاي للتكنلوجيا. جميع الحقوق محفوضة 2022",
  },
};
export default translations;
