import { AuthenticatedUser } from "src/interfaces";
import { AxiosRequestHeaders } from "axios";

export default function authHeader(): AxiosRequestHeaders {
  const user: AuthenticatedUser | null = JSON.parse(
    localStorage.getItem("user") || "null"
  );
  if (user && user.token) {
    return { Authorization: "Bearer " + user.token };
  } else {
    return {} as AxiosRequestHeaders;
  }
}
