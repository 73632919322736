import styledComponents from "styled-components";
import CheckCircleIcon from "../../assets/icons/CheckCircleIcon";
import { FieldProps } from "formik";

const StyledInput = styledComponents.input`
display:none;
`;

interface LabelProps {
  errorInput?: boolean;
  touchedInput?: boolean;
}

const StyledLabel = styledComponents.label<LabelProps>`
  position: relative;
  display: inline-block;
  font-family: "robotoregular";
  font-size: 1.2rem;
  padding: 1rem 2.5rem;
  line-height: 1;
  outline: 0;
  border: 1px solid #4988dd;
  cursor:pointer;
  color:#4988dd;
  ${({ errorInput, touchedInput, theme }) =>
    errorInput && touchedInput ? theme.inputFile.errorStyle : null}
`;

const Wrapper = styledComponents.div`
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
  gap:2rem;
  margin:2rem 6rem;
  padding:2rem;
  border:1px dotted #a5b8c0;
`;

const FiledName = styledComponents.p`
  font-family: "robotoregular";
  font-size: 1.2rem;
  color:#3b5066;
`;

const StyledDoneIcon = styledComponents.div`
  max-width:30px;
  color:#1bc2c2;
`;

const AcceptFormat = styledComponents.p`
  color:#818f9d;
  font-size:1rem;
`;

interface FileInputProps extends FieldProps {
  id: string;
  label: string;
}

const FileInput = ({ field, form, id, label, ...props }: FileInputProps) => {
  return (
    <Wrapper>
      {field.value && (
        <>
          <StyledDoneIcon>
            <CheckCircleIcon />
          </StyledDoneIcon>
          <FiledName>{field.value.name}</FiledName>
        </>
      )}
      <StyledLabel htmlFor={id}>{label}</StyledLabel>
      <StyledInput
        type="file"
        id={id}
        onChange={(event) => {
          form.setFieldValue(field.name, event.currentTarget.files![0]);
        }}
        {...props}
      />
      <AcceptFormat>Formats acceptés: png, jpg</AcceptFormat>
    </Wrapper>
  );
};

export default FileInput;
