import styled from "styled-components";

const Card = styled.div`
  flex: 0 0 calc(95% - 5px);
  display: grid;
  grid-template-columns: 60px 1fr 80px;
  align-items: center;
  gap: 1rem;
  padding: 1rem 2rem 2rem 2rem;
  cursor: pointer;
  transition: all ease 0.1s;
  @media (min-width: 768px) {
    flex: 0 0 calc(45% - 15px);
  }
  @media (min-width: 992px) {
    flex: 0 0 calc(33.33% - 15px);
  }
  ${({ theme }) => theme.cardTestimonial.style}
`;
export default Card;
