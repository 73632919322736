import styled from "styled-components";

const Divider = styled.hr`
  ${({ theme }) => theme.device.tablet} {
    ${({ theme }) => theme.divider.tabletStyle}
  }
  ${({ theme }) => theme.device.desktop} {
    ${({ theme }) => theme.divider.desktopStyle}
  }
  ${({ theme }) => theme.divider.style};
`;

export default Divider;
