import { FacebookIcon, InstagramIcon, LinkedinIcon } from "../assets/icons";
import styledComponents from "styled-components";
import { Container } from ".";
const Wrapper = styledComponents.div`
`;
const SocialMedia = styledComponents.a`
${({ theme }) => theme.footerIcon.style}

`;
const MyContent = styledComponents.div`
display:grid;
grid-template-columns:20px 20px 20px 1fr;
align-items:center;
gap:.5rem;
padding:1.5rem 0;
border-top: 1px solid #eee;
${({ theme }) => theme.footer.style}
${({ theme }) => theme.device.tablet}{
${({ theme }) => theme.footer.tabletStyle}
}
${({ theme }) => theme.device.desktop}{
  ${({ theme }) => theme.footer.desktopStyle}
  }

`;
const MyCopyright = styledComponents.p`
${({ theme }) => theme.text.style}
justify-self:end;
font-size:1em;

`;
type FooterProps = {
  facebook: string;
  linkedin: string;
  instagram: string;
  copyright: string;
};
const Footer = ({ facebook, linkedin, instagram, copyright }: FooterProps) => {
  return (
    <Wrapper>
      <Container>
        <MyContent>
          <SocialMedia href={facebook}>
            <FacebookIcon />
          </SocialMedia>
          <SocialMedia href={linkedin}>
            <LinkedinIcon />
          </SocialMedia>
          <SocialMedia href={instagram}>
            <InstagramIcon />
          </SocialMedia>
          <MyCopyright>{copyright}</MyCopyright>
        </MyContent>
      </Container>
    </Wrapper>
  );
};
export default Footer;
