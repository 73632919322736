import { User, AuthenticatedUser, UsersResponse } from "src/interfaces";
import apiWebify from "../api.webify";
import authHeader from "./auth-header";

export const apiAuthMap = (a: any) => ({
  id: a.id,
  roles: a.roles.map((r: any) => r),
  email: a.email,
  password: a.password,
});

const authenticate = (user: any): Promise<User> => {
  return apiWebify.post("login_check", user).then((res) => res.data);
};
const logout = () => {
  return localStorage.removeItem("user");
};
const register = (user: any): Promise<User> => {
  return apiWebify
    .post("admins", user)
    .then((res) => res.data)
    .then(apiAuthMap);
};
const getCurrentUser = (): AuthenticatedUser | null => {
  return JSON.parse(localStorage.getItem("user") || "null");
};

const getToken = (): string => {
  const user: string = JSON.parse(localStorage.getItem("user") ?? "{}").token;
  return user || "";
};

const getUsers = (): Promise<{ data: User[]; totalRows: number }> => {
  return apiWebify
    .get("admins", { headers: authHeader() })
    .then((res) => res.data as UsersResponse)
    .then((users) => {
      return {
        data: users["hydra:member"].map(apiAuthMap),
        totalRows: users["hydra:totalItems"],
      };
    });
};
const removeUser = (id: number) => {
  return apiWebify.delete(`admins/${id}`, { headers: authHeader() });
};
const editUser = (id: number, user: User) => {
  return apiWebify
    .put(`admins/${id}`, user, { headers: authHeader() })
    .then((res) => res.data)
    .then(apiAuthMap);
};
const AuthService = {
  authenticate,
  logout,
  getToken,
  getCurrentUser,
  register,
  getUsers,
  removeUser,
  editUser,
};
export default AuthService;
