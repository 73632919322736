import { I18n } from "react-redux-i18n";
import { NavLink, Route } from "react-router-dom";
import { Page } from "src/interfaces";
import styled from "styled-components";

const NavbarNav = styled.ul`
  grid-column: span 6;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem 0;
  text-align: center;
  @media (min-width: 992px) {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    flex-direction: row;
    padding: 0;
  }
`;
const MyNavLink = styled(NavLink)`
  padding: 1rem 2rem;
  border: 1px solid transparent;
  border-radius: 10px;
  ${({ theme }) => theme.headerNavLink.style}
  &.${(props) => props.activeClassName} {
    ${({ theme }) => theme.headerNavLink.styleActive}
    ${({ theme }) => theme.device.desktop} {
      ${({ theme }) => theme.headerNavLink.desktopStyleActive}
    }
  }
`;
type ListItemLinkProps = {
  to: string;
  name: string;
  index?: number;
};
const ListItemLink = ({ to, name, index, ...rest }: ListItemLinkProps) => {
  return (
    <Route
      path={to}
      children={() => (
        <MyNavLink to={to} {...rest} activeClassName="active">
          {name}
        </MyNavLink>
      )}
    />
  );
};
const HeaderMenu = ({ pages }: { pages: Page[] }) => {
  return (
    <NavbarNav>
      {pages.map((p, i) => (
        <ListItemLink
          key={i}
          to={p.path}
          name={I18n.t("pages." + p.name)}
        ></ListItemLink>
      ))}
    </NavbarNav>
  );
};
export default HeaderMenu;
