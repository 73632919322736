import styled from "styled-components";
import CloseCircle from "../../../../assets/icons/CloseCircle";

const Wrapper = styled.div`
  margin: 0 1rem;
  padding: 0.5rem 1.4rem;
  gap: 2rem;
  border-radius: 30px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  ${({ theme }) => theme.successAddNewsletter.style}
  ${({ theme }) => theme.device.tablet} {
    ${({ theme }) => theme.successAddNewsletter.tabletStyle}
  }
  ${({ theme }) => theme.device.desktop} {
    ${({ theme }) => theme.successAddNewsletter.desktopStyle}
  }
`;
const MyText = styled.p`
  font-size: 1.6rem;
  text-align: center;
  ${({ theme }) => theme.successAddNewsletter.text}
`;
type SuccessMsgProps = {
  setSuccessMsg: () => void;
};
const SuccessMsg = ({ setSuccessMsg }: SuccessMsgProps) => {
  return (
    <Wrapper
      onClick={(e) => {
        e.stopPropagation();
        setSuccessMsg();
      }}
    >
      <MyText>Votre demande a bien été envoyée Merci de votre intéret.</MyText>
      <CloseCircle />
    </Wrapper>
  );
};
export default SuccessMsg;
