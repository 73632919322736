import { useMemo, useState } from "react";
import styledComponents from "styled-components";
import StarIcon from "../assets/icons/StarIcon";

const StyledStarIcon = styledComponents.span<{ isFilled: boolean }>`
display:inline-block;
width:25px;
height:25px;
margin-right:.2rem;
color: ${({ isFilled }) =>
  isFilled ? "rgba(250, 171, 0, 0.87)" : "rgba(115, 119, 125, 1)"};
${({ theme }) => theme.star.style}
${({ theme }) => theme.device.tablet}{
${({ theme }) => theme.star.tabletStyle}
}
${({ theme }) => theme.device.desktop}{
  ${({ theme }) => theme.star.desktopStyle}
  }
`;
const LabelRate = styledComponents.p<{ responsive: string }>`
    color: rgba(115, 119, 125, 1);
    margin-bottom: 1rem;
    font-family: "robotoregular";
    font-size: 1.6rem;
    line-height: 1;
    ${({ theme }) => theme.labelFeedback.style}
    ${({ theme }) => theme.device.tablet} {
      ${({ theme, responsive }) =>
        responsive && theme.labelFeedback.tabletStyle}
    }
    ${({ theme }) => theme.device.desktop} {
      ${({ theme, responsive }) =>
        responsive && theme.labelFeedback.desktopStyle}
    }
`;
const RateWrapper = styledComponents.div`
`;
const StarRating = styledComponents.span`
`;
type RateProps = {
  totalStars?: number;
  rating?: number;
  color?: string;
  onRating?: (rating: number) => void;
};
const Rate = ({ totalStars = 5, rating = 0, onRating }: RateProps) => {
  const [hoverRating, setHoverRating] = useState(0);

  const starRating = useMemo(() => {
    return Array(totalStars)
      .fill(0)
      .map((_, i) => i + 1)
      .map((starIndex) => (
        <StyledStarIcon
          key={starIndex}
          isFilled={hoverRating >= starIndex || rating >= starIndex}
          onClick={() => onRating?.(starIndex)}
          onMouseEnter={() => setHoverRating(starIndex)}
          onMouseLeave={() => setHoverRating(0)}
        >
          <StarIcon />
        </StyledStarIcon>
      ));
  }, [totalStars, rating, hoverRating, onRating]);

  return (
    <RateWrapper>
      <LabelRate responsive="true">Ajouter un feedback</LabelRate>
      <StarRating>{starRating}</StarRating>
    </RateWrapper>
  );
};

export default Rate;
