import styled from "styled-components";
const NavbarContent = styled.div`
  font-family: "be_vietnamextrabold";
  font-size: 1.6rem;
  position: relative;
  display: grid;
  grid-template-columns: 1fr auto 1px auto 1px auto;
  grid-template-rows: 60px auto;
  align-items: center;
  column-gap: 2rem;
  ${({ theme }) => theme.header.style}
  ${({ theme }) => theme.device.tablet} {
    ${({ theme }) => theme.header.tabletStyle}
  }
  ${({ theme }) => theme.device.desktop} {
    ${({ theme }) => theme.header.desktopStyle}
  }
`;
export default NavbarContent;
