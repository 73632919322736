import { ResponsiveProps } from "src/interfaces";
import styledComponents from "styled-components";

const Button = styledComponents.button<ResponsiveProps>`
font-family: "be_vietnammedium",
text-align:center;
cursor: pointer;
text-decoration: none;
border: 1.5px solid;
border-color: transparent;
padding: .8rem 2.6rem;
font-size: 1.6rem;
line-height: 1;
border-radius: 0.38rem;
transition: all 0.2s;

${({ theme }) => theme.button.style}
${({ theme }) => theme.device.tablet} {
  ${({ theme, responsive }) => responsive && theme.button.tabletStyle}
}
${({ theme }) => theme.device.desktop} {
  ${({ theme, responsive }) => responsive && theme.button.desktopStyle}
}
`;
export default Button;
