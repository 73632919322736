import imgExpertise1 from "./assets/images/icon3.png";
import imgExpertise2 from "./assets/images/icon4.png";
import imgExpertise3 from "./assets/images/icon5.png";
import imgExpertise4 from "./assets/images/icon6.png";
import imgExpertise5 from "./assets/images/icon7.png";

import imgPack1 from "./assets/images/vitrine.png";
import imgPack2 from "./assets/images/ecommerce.png";
import imgPack3 from "./assets/images/appmobile.png";
import imgPack4 from "./assets/images/surmesure.png";
import {
  CommentIcon,
  CompteIcon,
  ContactIcon,
  HouseIcon,
  NewsletterIcon,
  PortfolioIcon,
  QuoteRequestIcon,
  SettingsIcon,
} from "./assets/icons";
import { RootState } from "./interfaces";

const initialState: RootState = {
  auth: {
    isLoggedin: false,
    isLoading: false,
    error: null,
    user: null,
    redirectToReferrer: false,
  },
  compagnyDetails: {
    data: {
      address: "address_compagny",
      phone: "phone_compagny",
      email: "admin@webify.pro",
      linkedin: "https://www.linkedin.com/company/webify-technology/mycompany/",
      instagram: "https://www.instagram.com/webify/",
      facebook: "https://www.facebook.com/Webify.Tech",
    },
  },
  pages: {
    data: [
      {
        name: "home",
        path: "/home",
      },
      {
        name: "services",
        path: "/service",
      },
      {
        name: "portfolio",
        path: "/portfolio",
      },
      {
        name: "testimonials",
        path: "/testimonial",
      },
      {
        name: "contact",
        path: "/contact",
      },
    ],
  },
  loginInputs: {
    data: [
      { name: "username", type: "text", placeholder: "youremail" },
      {
        name: "password",
        type: "password",
        placeholder: "yourpassword",
      },
    ],
  },
  expertises: {
    data: [
      {
        name: "responsive_design",
        img: imgExpertise1,
      },
      {
        name: "advice",
        img: imgExpertise2,
      },
      {
        name: "cms",
        img: imgExpertise3,
      },
      {
        name: "developpement",
        img: imgExpertise4,
      },
      {
        name: "web_design",
        img: imgExpertise5,
      },
    ],
  },
  packs: {
    data: [
      {
        name: "pack_vitrine",
        content: "pack_vitrine_content",
        img: imgPack1,
        path: "/",
      },
      {
        name: "pack_ecommerce",
        content: "pack_ecommerce_content",
        img: imgPack2,
        path: "/",
      },
      {
        name: "pack_app_mobile",
        content: "pack_app_mobile_content",
        img: imgPack3,
        path: "/",
      },
      {
        name: "pack_custom_made",
        content: "pack_custom_made_content",
        img: imgPack4,
        path: "/",
      },
    ],
  },
  dashboardPages: {
    data: [
      {
        name: "dashboard",
        path: "/dashboard/index",
        icon: <HouseIcon />,
      },
      {
        name: "quoteRequest",
        path: "/dashboard/devis",
        icon: <QuoteRequestIcon />,
      },
      {
        name: "portfolio",
        path: "/dashboard/portfolio",
        icon: <PortfolioIcon />,
      },
      {
        name: "comments",
        path: "/dashboard/comments",
        icon: <CommentIcon />,
      },
      {
        name: "newsletters",
        path: "/dashboard/newsletters",
        icon: <NewsletterIcon />,
      },
      {
        name: "contacts",
        path: "/dashboard/contacts",
        icon: <ContactIcon />,
      },
      {
        name: "accounts",
        path: "/dashboard/accounts",
        icon: <CompteIcon />,
      },
      {
        name: "settings",
        path: "/dashboard/settings",
        icon: <SettingsIcon />,
      },
    ],
  },
  questionsType: {
    data: [],
    isLoading: false,
    isSend: false,
    error: null,
  },

  questionsDevis: {
    filter: "pack-site-vitrine",
    sort: "asc",
    order: 1,
    data: [],
    totalRows: 0,
    isLoading: false,
    error: null,
  },
  compagniesInputs: {
    data: [],
    isLoading: false,
    error: null,
  },
  projectCategories: {
    isLoading: false,
    data: [],
    error: null,
  },
  publishProjects: {
    isLoading: false,
    data: [],
    error: null,
  },
  comments: {
    isLoading: false,
    isSend: {},
    data: [],
    perPage: 10,
    totalRows: 0,
    error: null,
  },
  filter: "ALL",
};

export default initialState;
