import styled from "styled-components";

const Head6 = styled.h6`
  font-size: 1.7rem;
  font-family: "robotoregular";
  color: rgb(145, 169, 201, 0.87);
  ${({ theme }) => theme.head6.style}
`;

export default Head6;
