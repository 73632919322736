import styled, { keyframes } from "styled-components";

const rotate = keyframes`
0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Loader = styled.div`
  ${({ theme }) => theme.loader.style}
  animation: ${rotate} ${({ theme }) => theme.loader.animation}
`;
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Loading = () => {
  return (
    <Wrapper>
      <Loader />
    </Wrapper>
  );
};
export default Loading;
