import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Separator from "./Separator";
import NavbarToggler from "./NavbarToggler";
import { useMediaQuery } from "react-responsive";
import HeaderMenu from "./HeaderMenu";
import { Container } from "..";
import LogoImg from "../../assets/images/LogoImg";
import LoginButton from "./LoginButton";
import LanguageSwitcher from "../LanguageSwitcher";
import DropdownProfil from "../DropdownProfil";
import Navbar from "./Navbar";
import NavbarContent from "./NavbarContent";
import NavbarBrand from "./NavbarBrand";
import { AuthState, Page } from "src/interfaces";

const Header = ({ pages }: { pages: Page[] }) => {
  const [showHeaderMenu, setShowHeaderMenu] = useState(false);
  const [showInternationalMenu, setShowInternationalMenu] = useState(false);
  const [openProfil, setOpenProfil] = useState(false);
  const isLoggedin = useSelector(
    (state: { auth: AuthState }) => state.auth.isLoggedin
  );
  const userString = localStorage.getItem("user");
  const username =
    isLoggedin && userString ? JSON.parse(userString).username : "";

  const forDesktop = useMediaQuery({ query: "(min-width: 992px)" });
  const resetForDesktop = useCallback(() => {
    forDesktop ? setShowHeaderMenu(true) : setShowHeaderMenu(false);
  }, [forDesktop]);

  useEffect(() => {
    resetForDesktop();
  }, [forDesktop, resetForDesktop]);

  const handleToggleShowHeaderMenu = (e: React.MouseEvent) => {
    e.preventDefault();
    setShowHeaderMenu(!showHeaderMenu);
  };

  const toggleShowIternationalMenu = () => {
    setShowInternationalMenu(!showInternationalMenu);
    setOpenProfil(false);
  };
  const handleOpenProfil = () => {
    setOpenProfil(!openProfil);
    setShowInternationalMenu(false);
  };

  return (
    <Navbar>
      <Container>
        <NavbarContent>
          <NavbarBrand>
            <LogoImg />
          </NavbarBrand>
          <LanguageSwitcher
            open={showInternationalMenu}
            toggleShowMenu={toggleShowIternationalMenu}
          />
          <Separator />
          {isLoggedin ? (
            <DropdownProfil
              username={username}
              open={openProfil}
              handleOpen={handleOpenProfil}
            />
          ) : (
            <LoginButton />
          )}

          {!forDesktop ? (
            <>
              <Separator />
              <NavbarToggler
                show={showHeaderMenu}
                handleToggle={handleToggleShowHeaderMenu}
              />
            </>
          ) : null}
          {showHeaderMenu ? (
            <>
              <HeaderMenu pages={pages} />
            </>
          ) : null}
        </NavbarContent>
      </Container>
    </Navbar>
  );
};
export default Header;
