import { Action, Devis } from "src/interfaces";
import { DevisActionType } from "src/types";
import initialState from "../initialState";
import * as actionsList from "./actions";

export const auth = (state = initialState.auth, action: Action) => {
  switch (action.type) {
    case actionsList.REQUEST_LOGIN_USER: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case actionsList.LOGIN_USER_ERROR: {
      const error = action.payload;
      return {
        ...state,
        isLoading: false,
        error: error,
        isLoggedin: false,
      };
    }
    case actionsList.LOGIN_USER_SUCCESS: {
      const user = action.payload;
      return {
        ...state,
        isLoading: false,
        isLoggedin: true,
        user: user,
        redirectToReferrer: true,
      };
    }
    case actionsList.LOGOUT_USER_ERROR: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case actionsList.LOGOUT_USER_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isLoggedin: false,
        user: null,
        redirectToReferrer: false,
      };
    }
    default: {
      return state;
    }
  }
};
export const compagnyDetails = (
  state = initialState.compagnyDetails,
  action: Action
) => {
  switch (action.type) {
    default:
      return state;
  }
};
export const pages = (state = initialState.pages, action: Action) => {
  switch (action.type) {
    default:
      return state;
  }
};
export const loginInputs = (
  state = initialState.loginInputs,
  action: Action
) => {
  switch (action.type) {
    default:
      return state;
  }
};
export const expertises = (state = initialState.expertises, action: Action) => {
  switch (action.type) {
    default:
      return state;
  }
};
export const packs = (state = initialState.packs, action: Action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export const dashboardPages = (
  state = initialState.dashboardPages,
  action: Action
) => {
  switch (action.type) {
    default:
      return state;
  }
};

export const questionsType = (
  state = initialState.questionsType,
  action: Action
) => {
  switch (action.type) {
    case actionsList.REQUEST_FETCH_TYPE_QUESTIONS: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case actionsList.FETCH_TYPE_QUESTIONS_ERROR: {
      const error = action.payload;
      return {
        ...state,
        isLoading: false,
        error: error,
      };
    }
    case actionsList.FETCH_TYPE_QUESTIONS_SUCCESS: {
      const questionsType = action.payload;
      return {
        ...state,
        isLoading: false,
        isSend: true,
        data: [...questionsType.map((d: any) => ({ ...d, editMode: false }))],
      };
    }
    case actionsList.REQUEST_EDIT_TYPE_QUESTION_IMG: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case actionsList.EDIT_TYPE_QUESTION_IMG_ERROR: {
      const error = action.payload;
      return {
        ...state,
        isLoading: false,
        error: error,
      };
    }
    case actionsList.EDIT_TYPE_QUESTION_IMG_SUCCESS: {
      const { id, img } = action.payload;
      return {
        ...state,
        isLoading: false,
        data: state.data.map((d: any) => (d.id === id ? img : d)),
      };
    }
    case actionsList.TOGGLE_TYPE_QUESTIONS_EDIT_MODE: {
      const id = action.payload;
      return {
        ...state,
        data: state.data.map((d: any) =>
          d.id === id ? { ...d, editMode: !d.editMode } : d
        ),
      };
    }
    case actionsList.EDIT_TYPE_QUESTIONS_ERROR: {
      const error = action.payload;
      return {
        ...state,
        isLoading: false,
        error: error,
      };
    }
    case actionsList.EDIT_TYPE_QUESTIONS_SUCCESS: {
      const { id, values } = action.payload;
      return {
        ...state,
        isLoading: false,
        data: state.data.map((d: any) =>
          d.id === id ? { ...values, editMode: false } : d
        ),
      };
    }
    case actionsList.REQUEST_REMOVE_TYPE_QUESTION: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case actionsList.REMOVE_TYPE_QUESTION_ERROR: {
      const error = action.payload;
      return {
        ...state,
        error: error,
      };
    }
    case actionsList.REMOVE_TYPE_QUESTION_SUCCESS: {
      const id = action.payload;
      return {
        ...state,
        data: state.data.filter((d: any) => d.id !== id),
      };
    }
    case actionsList.REQUEST_ADD_TYPE_QUESTION: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case actionsList.ADD_TYPE_QUESTION_SUCCESS: {
      const typequestion = action.payload;
      return {
        ...state,
        isLoading: false,
        data: [...state.data, typequestion],
      };
    }
    case actionsList.ADD_TYPE_QUESTION_ERROR: {
      const error = action.payload;
      return {
        ...state,
        error: error,
      };
    }
    case actionsList.RESET_TYPE_QUESTION: {
      return {
        ...state,
        isSend: false,
      };
    }
    default:
      return state;
  }
};

export const questionsDevis = (
  state = initialState.questionsDevis,
  action: Action
) => {
  switch (action.type) {
    case actionsList.REQUEST_FETCH_QUESTIONS_DEVIS: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case actionsList.SET_TYPE_DEVIS: {
      const filter = action.payload;
      return {
        ...state,
        filter: filter,
      };
    }
    case actionsList.FETCH_QUESTIONS_DEVIS_ERROR: {
      const error = action.payload;
      return {
        ...state,
        isLoading: false,
        error: error,
      };
    }
    case actionsList.FETCH_QUESTIONS_DEVIS_SUCCESS: {
      const questions = action.payload;
      return {
        ...state,
        isLoading: false,
        data: [
          ...questions.map((d: any) => ({
            ...d,
            options: d.options.map((option: any) => ({
              ...option,
              editMode: false,
            })),
            editMode: false,
          })),
        ],
      };
    }
    case actionsList.SET_QUESTION_TOTAL_ITEMS: {
      const total = action.payload;
      return {
        ...state,
        isLoading: false,
        totalRows: total,
      };
    }
    case actionsList.TOGGLE_QUESTIONS_EDIT_MODE: {
      const id = action.payload;
      return {
        ...state,
        isLoading: false,
        data: state.data.map((d: any) =>
          d.id === id ? { ...d, editMode: !d.editMode } : d
        ),
      };
    }
    case actionsList.TOGGLE_OPTION_EDIT_MODE: {
      const id = action.payload;
      return {
        ...state,
        isLoading: false,
        data: state.data.map((d: any) => ({
          ...d,
          options: d.options.map((option: any) =>
            option.id === id
              ? { ...option, editMode: !option.editMode }
              : option
          ),
        })),
      };
    }
    case actionsList.REQUEST_EDIT_QUESTION: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case actionsList.EDIT_QUESTION_ERROR: {
      const error = action.payload;
      return {
        ...state,
        isLoading: false,
        error: error,
      };
    }
    case actionsList.EDIT_QUESTION_SUCCESS: {
      const { id, values } = action.payload;
      return {
        ...state,
        data: state.data.map((d: any) =>
          d.id === id ? { ...values, editMode: false } : d
        ),
      };
    }
    case actionsList.REQUEST_EDIT_OPTION: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case actionsList.EDIT_OPTION_ERROR: {
      const error = action.payload;
      return {
        ...state,
        isLoading: false,
        error: error,
      };
    }
    case actionsList.EDIT_OPTION_SUCCESS: {
      const { id, values } = action.payload;
      return {
        ...state,
        data: state.data.map((q: any) => ({
          ...q,
          options: q.options.map((o: any) =>
            o.id === id ? { ...values, editMode: false } : o
          ),
        })),
      };
    }
    case actionsList.REQUEST_ADD_OPTION: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case actionsList.ADD_OPTION_ERROR: {
      const error = action.payload;
      return {
        ...state,
        isLoading: false,
        error: error,
      };
    }
    case actionsList.ADD_OPTION_SUCCESS: {
      const option = action.payload;
      const newData = state.data.map((question: any) => {
        if (question.name === option.name || question.name === "lastStep") {
          return {
            ...question,
            options: [...question.options, { ...option, editMode: false }],
          };
        }
        return question;
      });
      return {
        ...state,
        isLoading: false,
        data: newData,
      };
    }
    case actionsList.REQUEST_REMOVE_OPTION: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case actionsList.REMOVE_OPTION_ERROR: {
      const error = action.payload;
      return {
        ...state,
        isLoading: false,
        error: error,
      };
    }
    case actionsList.REMOVE_OPTION_SUCCESS: {
      const { idQuestion, idOption } = action.payload;
      return {
        ...state,
        isLoading: false,
        data: state.data.map((q: any) => {
          if (q.id === idQuestion) {
            return {
              ...q,
              options: q.options.filter((o: any) => o.id !== idOption),
            };
          }
          return q;
        }),
      };
    }
    case actionsList.REQUEST_ADD_QUESTION: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case actionsList.ADD_QUESTION_ERROR: {
      const error = action.payload;
      return {
        ...state,
        isLoading: false,
        error: error,
      };
    }
    case actionsList.ADD_QUESTION_SUCCESS: {
      const question = action.payload;
      return {
        ...state,
        isLoading: false,
        data: [...state.data, { ...question, editMode: false }],
        totalRows: state.totalRows + 1,
        order: state.order + 1,
      };
    }
    case actionsList.SET_ORDER_QUESTION: {
      const order = action.payload;
      return {
        ...state,
        order: order,
      };
    }
    case actionsList.REQUEST_REMOVE_QUESTION: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case actionsList.REMOVE_QUESTION_ERROR: {
      const error = action.payload;
      return {
        ...state,
        isLoading: false,
        error: error,
      };
    }
    case actionsList.REMOVE_QUESTION_SUCCESS: {
      const id = action.payload;
      return {
        ...state,
        isLoading: false,
        data: state.data.filter((q: any) => q.id !== id),
        order: state.order - 1,
      };
    }
    default:
      return state;
  }
};
export const compagniesInputs = (
  state = initialState.compagniesInputs,
  action: Action
) => {
  switch (action.type) {
    case actionsList.REQUEST_COMPAGNIES:
      return {
        ...state,
        isLoading: true,
      };
    case actionsList.FETCH_COMPAGNIES_SUCCESS: {
      const compagnies = action.payload;
      if (compagnies) {
        return {
          ...state,
          data: compagnies,
          isLoading: false,
          error: null,
        };
      } else {
        return {
          ...state,
          isLoading: false,
        };
      }
    }
    case actionsList.FETCH_COMPAGNIES_ERROR: {
      const error = action.payload;
      return {
        ...state,
        error: error,
      };
    }
    default:
      return state;
  }
};
interface DevisState {
  data: Devis[];
  filter: string;
  totalRows: number;
  perPage: number;
  isSend: boolean;
  isLoading: boolean;
  error: string | null;
}
export const devis = (
  state: DevisState = {
    filter: "ALL",
    data: [],
    totalRows: 0,
    perPage: 10,
    isSend: false,
    isLoading: false,
    error: null,
  },
  action: DevisActionType
) => {
  switch (action.type) {
    case actionsList.REQUEST_ADD_DEVIS: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case actionsList.ADD_DEVIS_SUCCESS: {
      const devis = action.payload;
      return {
        ...state,
        data: [...state.data, { ...devis, editMode: false }],
        totalRows: state.totalRows + 1,
        isLoading: false,
        isSend: true,
      };
    }
    case actionsList.SET_IS_SEND_DEVIS: {
      return {
        ...state,
        isSend: false,
      };
    }
    case actionsList.ADD_DEVIS_ERROR: {
      const error = action.payload;
      return {
        ...state,
        error: error,
        isLoading: false,
        isSend: false,
      };
    }
    case actionsList.REQUEST_DELETE_DEVI: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case actionsList.DELETE_DEVI_SUCCESS: {
      const id = action.payload;
      return {
        ...state,
        data: state.data.filter((t, i) => t.id !== id),
        totalRows: state.totalRows - 1,
        isLoading: false,
      };
    }

    case actionsList.DELETE_DEVI_ERROR: {
      const error = action.payload;
      return {
        ...state,
        error: error,
        isLoading: false,
      };
    }

    case actionsList.REQUEST_DELETE_DEVIS: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case actionsList.DELETE_DEVIS_SUCCESS: {
      const { devis, listID } = action.payload;
      return {
        ...state,
        data: devis,
        totalRows: state.totalRows - listID.length,
        isLoading: false,
      };
    }

    case actionsList.DELETE_DEVIS_ERROR: {
      const error = action.payload;
      return {
        ...state,
        error: error,
        isLoading: false,
      };
    }

    case actionsList.TOGGLE_DEVIS_EDIT_MODE: {
      const id = action.payload;
      return {
        ...state,
        data: state.data.map((d, i) =>
          d.id === id ? { ...d, editMode: !d.editMode } : d
        ),
      };
    }
    case actionsList.REQUEST_EDIT_DEVI: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case actionsList.EDIT_DEVI_SUCCESS: {
      const { id, devi } = action.payload;
      return {
        ...state,
        isLoading: false,
        isSend: true,
        data: state.data.map((d, i) =>
          d.id === id ? { ...devi, editMode: false } : d
        ),
      };
    }
    case actionsList.EDIT_DEVI_ERROR: {
      const error = action.payload;
      return {
        ...state,
        error: error,
        isLoading: false,
      };
    }
    case actionsList.REQUEST_DEVIS: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case actionsList.FETCH_DEVIS_SUCCESS: {
      const devis = action.payload;
      return {
        ...state,
        data: [...devis.map((d: any) => ({ ...d, editMode: false }))],
        isLoading: false,
        error: null,
      };
    }
    case actionsList.FETCH_DEVIS_ERROR: {
      const error = action.payload;
      return {
        ...state,
        error: error,
        isLoading: false,
      };
    }
    case actionsList.SET_DEVIS_TOTAL_ITEMS: {
      const total = action.payload;
      return {
        ...state,
        totalRows: total,
      };
    }
    case actionsList.SET_DEVIS_FILTER: {
      const filter = action.payload;
      return {
        ...state,
        filter,
      };
    }
    case actionsList.SET_DEVIS_PER_PAGE: {
      const perPage = action.payload;
      return {
        ...state,
        perPage: perPage,
        isLoading: false,
        error: null,
      };
    }
    default:
      return state;
  }
};

export const projectCategories = (
  state = initialState.projectCategories,
  action: Action
) => {
  switch (action.type) {
    case actionsList.REQUEST_FETCH_PROJECT_CATEGORIES: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case actionsList.FETCH_PROJECT_CATEGORIES_ERROR: {
      const error = action.payload;
      return {
        ...state,
        isLoading: false,
        error: error,
      };
    }
    case actionsList.FETCH_PROJECT_CATEGORIES_SUCCESS: {
      const categories = action.payload;
      return {
        ...state,
        isLoading: false,
        data: categories,
        error: null,
      };
    }
    default:
      return state;
  }
};
export const publishProjects = (
  state = initialState.publishProjects,
  action: Action
) => {
  switch (action.type) {
    case actionsList.REQUEST_FETCH_PROJECTS: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case actionsList.FETCH_PROJECTS_ERROR: {
      const error = action.payload;
      return {
        ...state,
        isLoading: false,
        error: error,
      };
    }
    case actionsList.FETCH_PROJECTS_SUCCESS: {
      const projects = action.payload;
      return {
        ...state,
        isLoading: false,
        data: projects,
        error: null,
      };
    }
    default:
      return state;
  }
};

export const comments = (state = initialState.comments, action: Action) => {
  switch (action.type) {
    case actionsList.REQUEST_FETCH_COMMENTS: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case actionsList.FETCH_COMMENTS_ERROR: {
      const error = action.payload;
      return {
        ...state,
        isLoading: false,
        erro: error,
      };
    }
    case actionsList.FETCH_COMMENTS_SUCCESS: {
      const comments = action.payload;
      return {
        ...state,
        isLoading: false,
        data: comments,
        error: null,
      };
    }
    case actionsList.SET_TOTAL_COMMENTS: {
      const total = action.payload;
      return {
        ...state,
        totalRows: total,
      };
    }
    case actionsList.SET_PER_PAGE_COMMENTS: {
      const perPage = action.payload;
      return {
        ...state,
        perPage: perPage,
      };
    }
    case actionsList.REQUEST_ADD_COMMENT: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case actionsList.ADD_COMMENT_ERROR: {
      const error = action.payload;
      return {
        ...state,
        isLoading: false,
        error: error,
      };
    }
    case actionsList.ADD_COMMENT_SUCCESS: {
      const comment = action.payload;
      return {
        ...state,
        isSend: comment,
        isLoading: false,
      };
    }
    case actionsList.REQUEST_DELETE_COMMENT: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case actionsList.DELETE_COMMENT_ERROR: {
      const error = action.payload;
      return {
        ...state,
        isLoading: false,
        error: error,
      };
    }
    case actionsList.DELETE_COMMENT_SUCCESS: {
      const id = action.payload;
      return {
        ...state,
        isLoading: false,
        data: state.data.filter((c) => c.id !== id),
        totalRows: state.totalRows - 1,
      };
    }
    case actionsList.REQUEST_DELETE_COMMENTS: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case actionsList.DELETE_COMMENTS_ERROR: {
      const error = action.payload;
      return {
        ...state,
        isLoading: false,
        error: error,
      };
    }
    case actionsList.DELETE_COMMENTS_SUCCESS: {
      const { comments, listID } = action.payload;
      return {
        ...state,
        isLoading: false,
        data: comments,
        totalRows: state.totalRows - listID.length,
      };
    }
    case actionsList.REQUEST_EDIT_COMMENT: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case actionsList.EDIT_COMMENT_ERROR: {
      const error = action.payload;
      return {
        ...state,
        isLoading: false,
        error: error,
      };
    }
    case actionsList.EDIT_COMMENT_SUCCESS: {
      const { id, comment } = action.payload;
      return {
        ...state,
        isLoading: false,
        data: state.data.map((d, i) => (d.id === id ? comment : d)),
      };
    }
    default:
      return state;
  }
};

export const filter = (state = initialState.filter, action: Action) => {
  const filter = action.payload;
  switch (action.type) {
    case actionsList.SET_PROJECT_FILTER:
      return filter;
    default:
      return state;
  }
};
