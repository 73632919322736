import { rootDirectory } from "../../conf/api.webify";
import AuthorImg from "./AuthorImg";
import Card from "./Card";
import QuoteText from "./QuoteText";
import AuthorName from "./AuthorName";
import styledComponents from "styled-components";
import StarIcon from "../../assets/icons/StarIcon";
import ProfilIcon from "../../assets/icons/ProfilIcon";
import QuoteFadeIcon from "../../assets/icons/QuoteFadeIcon";

const StyledStarActive = styledComponents.span`
display:inline-block;
width:16px;
height:16px;
margin-right:2px;
${({ theme }) => theme.starFilled}
`;
const StyledStarInactive = styledComponents.span`
display:inline-block;
width:16px;
height:16px;
margin-right:2px;
${({ theme }) => theme.starUnfilled}
`;
const StyledFadeQuote = styledComponents.span`
${({ theme }) => theme.quoteUnFilled.style}
`;
const StyledImgNotFound = styledComponents.div`
${({ theme }) => theme.quoteUnFilled.style}

`;
type CardTestimonialProps = {
  content: string;
  contentUrl: string;
  fullname: string;
  skill: number;
};
const CardTestimonial = ({
  content,
  contentUrl,
  fullname,
  skill,
}: CardTestimonialProps) => {
  const feedback = (nb: number) => {
    const stars = [];
    for (let index = 0; index < 5; index++) {
      nb > index
        ? stars.push(
            <StyledStarActive key={index}>
              <StarIcon />
            </StyledStarActive>
          )
        : stars.push(
            <StyledStarInactive key={index}>
              <StarIcon />
            </StyledStarInactive>
          );
    }
    return stars;
  };
  return (
    <Card>
      <QuoteText>{content}</QuoteText>
      {contentUrl ? (
        <AuthorImg src={rootDirectory + contentUrl} alt={fullname} />
      ) : (
        <StyledImgNotFound>
          <ProfilIcon />
        </StyledImgNotFound>
      )}
      <div>
        <AuthorName>{fullname}</AuthorName>
        {feedback(skill)}
      </div>
      <StyledFadeQuote>
        <QuoteFadeIcon />
      </StyledFadeQuote>
    </Card>
  );
};
export default CardTestimonial;
