import { createGlobalStyle } from "styled-components";
import bevietnamExtraBoldWoff from "./assets/fonts/bevietnam-extra-bold/bevietnam-extrabold-webfont.woff";
import bevietnamMediumWoff from "./assets/fonts/bevietnam-medium/bevietnam-medium-webfont.woff";
import bevietnamSemiBoldWoff from "./assets/fonts/bevietnam-semi-bold/bevietnam-semibold-webfont.woff";
import openSansWoff from "./assets/fonts/opensans-semi-bold/opensans-semibold-webfont.woff";
import robotoWoff from "./assets/fonts/roboto-regular/roboto-regular-webfont.woff";

const FontStyles = createGlobalStyle`

@font-face {
    font-family: 'open_sanssemibold';
    src: url(${openSansWoff}) format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'robotoregular';
    src: url(${robotoWoff}) format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'be_vietnamextrabold';
    src: url(${bevietnamExtraBoldWoff}) format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;

}
@font-face {
    font-family: 'be_vietnammedium';
    src: url(${bevietnamMediumWoff}) format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;

}
@font-face {
    font-family: 'be_vietnamsemibold';
    src: url(${bevietnamSemiBoldWoff}) format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;

}

`;

export default FontStyles;
