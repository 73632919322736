import React from "react";
import { Container } from "../../../../components";
import FooterContent from "./FooterContent";
import FooterMenu from "./FooterMenu";
import WrapperFooter from "./WrapperFooter";

type FooterHomeProps = {
  pages: any;
};
const FooterHome = ({ pages }: FooterHomeProps) => {
  return (
    <WrapperFooter>
      <Container>
        <FooterContent>
          <FooterMenu pages={pages} />
        </FooterContent>
      </Container>
    </WrapperFooter>
  );
};
export default FooterHome;
