import styled from "styled-components";
import Text from "../Text";
import Title from "../Title";
import StyledText from "./StyledText";
import { useState } from "react";
import { ResponsiveProps } from "src/interfaces";
import { rootDirectory } from "../../conf/api.webify";
import StyledIcon from "src/features/dashboard/settings/StyledIcon";
import EditIcon from "src/assets/icons/EditIcon";
import { useDispatch } from "react-redux";
import {
  toggleTypeQuestionsEditMode,
  tryEditTypeQuestionImgAction,
} from "src/store/actions";
import UploadModal from "src/features/dashboard/UploadModal";
import EditCard from "src/features/dashboard/settings/EditCard";

const MyCard = styled.div<ResponsiveProps>`
  ${({ theme }) => theme.card.style}
  ${({ theme }) => theme.device.tablet} {
    ${({ theme, responsive }) => responsive && theme.card.tabletStyle}
  }
  ${({ theme }) => theme.device.desktop} {
    ${({ theme, responsive }) => responsive && theme.card.desktopStyle}
  }
`;
const MyImage = styled.div`
  grid-area: imgCard;
`;
const MyTitle = styled(Title)<{ responsive: string }>`
  grid-area: titleCard;
  text-align: center;
  display: flex;
  ${({ theme }) => theme.cardTitle.style}
  ${({ theme }) => theme.device.tablet} {
    ${({ theme, responsive }) => responsive && theme.cardTitle.tabletStyle}
  }
  ${({ theme }) => theme.device.desktop} {
    ${({ theme, responsive }) => responsive && theme.cardTitle.desktopStyle}
  }
`;

const MyText = styled(Text)<ResponsiveProps>`
  grid-area: textCard;
  display: flex;
  ${StyledText}
`;
type CardProps = {
  data: any;
  type: string;
  responsive: string;
};
const Card = ({ data, type, responsive }: CardProps) => {
  const [showUpload, setShowUpload] = useState(false);
  const dispatch = useDispatch();
  const handleToggleShowUpload = () => {
    setShowUpload(!showUpload);
  };
  const handleEditImg = (id: any, img: any) => {
    dispatch(tryEditTypeQuestionImgAction(id, img));
    setShowUpload(false);
  };
  const handleEditMode = (event: React.MouseEvent<HTMLDivElement>) => {
    const idd = event.currentTarget.id; // Assuming the id is set on the div element
    dispatch(toggleTypeQuestionsEditMode(parseInt(idd)));
  };
  return (
    <>
      <MyCard responsive={responsive}>
        <MyImage>
          {type && (
            <StyledIcon onClick={handleToggleShowUpload}>
              <EditIcon />
            </StyledIcon>
          )}
          <img src={rootDirectory + data.contentUrl} alt={data.name} />
        </MyImage>
        {data.editMode ? (
          <EditCard data={data} />
        ) : (
          <>
            <MyTitle responsive={responsive}>
              {data.name}
              {type && (
                <StyledIcon onClick={handleEditMode} id={data.id}>
                  <EditIcon />
                </StyledIcon>
              )}
            </MyTitle>
            <MyText responsive={responsive}>{data.description}</MyText>
          </>
        )}
      </MyCard>
      {showUpload ? (
        <UploadModal onUpload={handleEditImg} id={data.id} />
      ) : null}
    </>
  );
};
export default Card;
