import styled, { css } from "styled-components";

export const ToggleButton = styled.div<{ active: boolean }>`
  background: rgba(245, 245, 245, 0.6);
  font-family: "be_vietnamextrabold";
  width: 40px;
  height: 40px;
  border-radius: 100%;
  font-size: 1.2rem;
  line-height: 1;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.2rem;
  border: none;
  position: relative;
  cursor: pointer;
  &:hover {
    ${({ theme }) => theme.btnToggler.styleHover};
  }
  ${({ theme }) => theme.btnToggler.style};
  ${({ active }) =>
    active
      ? css`
          ${({ theme }) => theme.btnToggler.styleActive}
        `
      : css`
          ${({ theme }) => theme.btnToggler.styleInactive}
        `}
`;
