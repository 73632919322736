import { setLocale } from "react-redux-i18n";
import { Dispatch } from "redux";
import DevisService from "src/conf/services/devis.service";
import {
  AddDevisErrorAction,
  AddDevisSuccessAction,
  Comment,
  Compagny,
  DeleteDeviErrorAction,
  DeleteDevisErrorAction,
  DeleteDevisSuccessAction,
  DeleteDeviSuccessAction,
  Devis,
  EditDevisErrorAction,
  EditDevisSuccessAction,
  Estimate,
  FetchDevisErrorAction,
  FetchDevisSuccessAction,
  Media,
  Project,
  ProjectCategory,
  RequestAddDevisAction,
  RequestDeleteDeviAction,
  RequestDeleteDevisAction,
  RequestDevisAction,
  RequestEditDeviAction,
  RootState,
  SetDevisFilterAction,
  SetDevisPerPageAction,
  SetDevisTotalItemsAction,
  SetIsSendDevisAction,
  SetProjectFilterAction,
  ToggleDevisEditModeAction,
  User,
} from "src/interfaces";
import { DevisActionType } from "src/types";
import AuthService from "../conf/services/auth.service";
import CommentService from "../conf/services/comment.service";
import CompagnyService from "../conf/services/compagny.service";
import MediasService from "../conf/services/medias.service";
import CategoriesProjectService from "../conf/services/projectCategories.service";
import ProjectService from "../conf/services/projects.service";

import { fallbackLocale, supportedLocales } from "../i18next";

export const REQUEST_LOGIN_USER = "request login user";
export const TRY_LOGIN_USER = "try login user";
export const LOGIN_USER_SUCCESS = "login user success";
export const LOGIN_USER_ERROR = "login user error";

export const LOGOUT_USER = "logout user";
export const LOGOUT_USER_SUCCESS = "logout user success";
export const LOGOUT_USER_ERROR = "logout user error";

export const REQUEST_FETCH_PROJECT_CATEGORIES =
  "request fetch project categories";
export const FETCH_PROJECT_CATEGORIES = "fetch project categories";
export const FETCH_PROJECT_CATEGORIES_SUCCESS =
  "fetch project categories success";
export const FETCH_PROJECT_CATEGORIES_ERROR = "fetch project categories error";

export const REQUEST_FETCH_PROJECTS = "request fetch projects";
export const FETCH_PROJECTS = "fetch projects";
export const FETCH_PROJECTS_ERROR = "fetch projects errror";
export const FETCH_PROJECTS_SUCCESS = "fetch projects success";

export const REQUEST_FETCH_IMAGES = "request fetch images";
export const FETCH_IMAGES = "fetch images";
export const FETCH_IMAGES_SUCCESS = "fetch images success";
export const FETCH_IMAGES_ERROR = "fetch images error";

export const REQUEST_FETCH_COMMENTS = "request fetch comments";
export const FETCH_COMMENTS = "fetch comments";
export const FETCH_COMMENTS_SUCCESS = "fetch comments success";
export const FETCH_COMMENTS_ERROR = "fetch comments error";
export const SET_TOTAL_COMMENTS = "set total comments";
export const SET_PER_PAGE_COMMENTS = "set per page comments";

export const REQUEST_ADD_COMMENT = "request add comment";
export const ADD_COMMENT_SUCCESS = "add comment success";
export const ADD_COMMENT_ERROR = "add comment error";
export const TRY_ADD_COMMENT = "try add comment";

export const REQUEST_DELETE_COMMENT = "request delete comment";
export const TRY_DELETE_COMMENT = "try delete comment";
export const DELETE_COMMENT_SUCCESS = "delete comment success";
export const DELETE_COMMENT_ERROR = "delete comment error";

export const REQUEST_DELETE_COMMENTS = "request delete comments";
export const TRY_DELETE_COMMENTS = "try delete comments";
export const DELETE_COMMENTS_SUCCESS = "delete comments success";
export const DELETE_COMMENTS_ERROR = "delete comments error";

export const REQUEST_EDIT_COMMENT = "request edit comment";
export const TRY_EDIT_COMMENT = "try edit comment";
export const EDIT_COMMENT_SUCCESS = "edit comment success";
export const EDIT_COMMENT_ERROR = "edit comment error";

export const REQUEST_COMPAGNIES = "request compagnies";
export const FETCH_COMPAGNIES = "fetch compagnies";
export const FETCH_COMPAGNIES_SUCCESS = "fetch compagnies success";
export const FETCH_COMPAGNIES_ERROR = "fetch compagnies error";

export const REQUEST_FETCH_TYPE_QUESTIONS = "request fetch type questions";
export const FETCH_TYPE_QUESTIONS = "fetch type questions";
export const FETCH_TYPE_QUESTIONS_SUCCESS = "fetch type questions success";
export const FETCH_TYPE_QUESTIONS_ERROR = "fetch type questions error";
export const TOGGLE_TYPE_QUESTIONS_EDIT_MODE =
  "toggle type questtions edit mode";

export const REQUEST_ADD_TYPE_QUESTION = "request add type question";
export const ADD_TYPE_QUESTION_ERROR = "add type question error";
export const ADD_TYPE_QUESTION_SUCCESS = "add type question success";

export const REQUEST_EDIT_TYPE_QUESTION_IMG = "request edit type question img";
export const EDIT_TYPE_QUESTION_IMG_SUCCESS = "edit type question img success";
export const EDIT_TYPE_QUESTION_IMG_ERROR = "edit type question img error";
export const TRY_EDIT_TYPE_QUESTION_IMG = "edit type question img";

export const REQUEST_EDIT_TYPE_QUESTIONS = "request edit type questions";
export const EDIT_TYPE_QUESTIONS_ERROR = "edit type questions error";
export const EDIT_TYPE_QUESTIONS_SUCCESS = "edit type questions success";

export const REQUEST_REMOVE_TYPE_QUESTION = "request remove type question";
export const REMOVE_TYPE_QUESTION_ERROR = "remove type question error";
export const REMOVE_TYPE_QUESTION_SUCCESS = "remove type question success";

export const RESET_TYPE_QUESTION = "reset type question";

export const REQUEST_ADD_OPTION = "request add option";
export const ADD_OPTION_ERROR = "add option error";
export const ADD_OPTION_SUCCESS = "add option success";

export const REQUEST_EDIT_OPTION = "request edit option";
export const EDIT_OPTION_ERROR = "edit option error";
export const EDIT_OPTION_SUCCESS = "edit option success";

export const REQUEST_REMOVE_OPTION = "request remove option";
export const REMOVE_OPTION_ERROR = "remove option error";
export const REMOVE_OPTION_SUCCESS = "remove option success";

export const REQUEST_ADD_QUESTION = "request add question";
export const ADD_QUESTION_ERROR = "add question error";
export const ADD_QUESTION_SUCCESS = "add question success";
export const SET_ORDER_QUESTION = "set order question";

export const REQUEST_EDIT_QUESTION = "request edit question";
export const EDIT_QUESTION_ERROR = "edit question error";
export const EDIT_QUESTION_SUCCESS = "edit question success";

export const REQUEST_REMOVE_QUESTION = "request remove question";
export const REMOVE_QUESTION_ERROR = "remove question error";
export const REMOVE_QUESTION_SUCCESS = "remove question success";

export const REQUEST_FETCH_QUESTIONS_DEVIS = "request fetch questions devis";
export const FETCH_QUESTIONS_DEVIS = "fetch questions devis";
export const FETCH_QUESTIONS_DEVIS_SUCCESS = "fetch questions devis success";
export const FETCH_QUESTIONS_DEVIS_ERROR = "fetch questions devis error";
export const SET_QUESTION_TOTAL_ITEMS = "set question total item";
export const TOGGLE_QUESTIONS_EDIT_MODE = "toggle question edit mode";
export const TOGGLE_OPTION_EDIT_MODE = "toggle option edit mode";

export const REQUEST_GET_QUESTION_ID_BY_SORT =
  "request get question id by sort";
export const GET_QUESTION_ID_BY_SORT_ERROR = "get question id by sort error";
export const GET_QUESTION_ID_BY_SORT_SUCCESS =
  "get question id by sort success";

export const REQUEST_ADD_DEVIS = "request add devis";
export const TRY_ADD_DEVIS = "add devis";
export const ADD_DEVIS_SUCCESS = "add devis success";
export const ADD_DEVIS_ERROR = "add devis error";

export const REQUEST_DELETE_DEVI = "request delete devi";
export const TRY_DELETE_DEVI = "try delete devi";
export const DELETE_DEVI_SUCCESS = "delete devi success";
export const DELETE_DEVI_ERROR = "delete devi error";

export const REQUEST_DELETE_DEVIS = "request delete devis";
export const TRY_DELETE_DEVIS = "try delete devis";
export const DELETE_DEVIS_SUCCESS = "delete devis success";
export const DELETE_DEVIS_ERROR = "delete devis error";

export const REQUEST_DEVIS = "request devis";
export const FETCH_DEVIS = "fetch devis";
export const FETCH_DEVIS_SUCCESS = "fetch devis success";
export const FETCH_DEVIS_ERROR = "fetch devis error";
export const FILTRER_BY_ID = "filtrer by id";

export const REQUEST_EDIT_DEVI = "request edit devi";
export const TRY_EDIT_DEVI = "try edit devi";
export const EDIT_DEVI_SUCCESS = "edit devi success";
export const EDIT_DEVI_ERROR = "edit devi error";

export const SET_DEVIS_TOTAL_ITEMS = "set devis total items";
export const SET_DEVIS_PER_PAGE = "set devis per page";
export const TOGGLE_DEVIS_EDIT_MODE = "toggle devis edit mode";
export const SET_IS_SEND_DEVIS = "set is send devis";
export const SET_DEVIS_FILTER = "set devis filter";

export const SET_TYPE_DEVIS = "set type devis";

export const SET_PROJECT_FILTER = "set filter";

export const VisibilityFilter = {
  SHOW_ALL: "ALL",
  SHOW_SHOWCASE: "SHOWCASE",
  SHOW_ECOMMERCE: "ECOMMERCE",
  SHOW_TAILOR: "TAILOR",
  SHOW_MOBILE_APPS: "MOBILE APPS",
};

export const setLocaleWithFallback = (desiredLocale: any) => {
  const finalLocale = Object.keys(supportedLocales).includes(desiredLocale)
    ? desiredLocale
    : fallbackLocale;
  return (dispatch: Dispatch<any>) => {
    dispatch(setLocale(finalLocale));
  };
};

export const setFilterProjectsAction = (
  filter: any
): SetProjectFilterAction => {
  return {
    type: SET_PROJECT_FILTER,
    payload: filter,
  };
};

export const setTypeDevisAction = (filter: any) => {
  return {
    type: SET_TYPE_DEVIS,
    payload: filter,
  };
};

export const requestCompagnies = () => {
  return {
    type: REQUEST_COMPAGNIES,
  };
};
export const fetchCompagniesSuccess = (compagnies: Compagny[]) => {
  return {
    type: FETCH_COMPAGNIES_SUCCESS,
    payload: compagnies,
  };
};
export const fetchCompagniesError = (error: any) => {
  return {
    type: FETCH_COMPAGNIES_ERROR,
    payload: error,
  };
};
export const fetchCompagniesInput = (lang: string) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(requestCompagnies());
    try {
      const response = await CompagnyService.getCompagniesInput(lang);

      dispatch(fetchCompagniesSuccess(response));
    } catch (error) {
      dispatch(fetchCompagniesError(error));
    }
  };
};

export const requestLoginUserAction = () => {
  return {
    type: REQUEST_LOGIN_USER,
  };
};
export const loginUserSuccessAction = (user: User) => {
  return {
    type: LOGIN_USER_SUCCESS,
    payload: user,
  };
};
export const loginUserErrorAction = (error: any) => {
  return {
    type: LOGIN_USER_ERROR,
    payload: error,
  };
};
export const tryLoginUser = (user: User) => {
  return async (dispatch: Dispatch) => {
    dispatch(requestLoginUserAction());
    try {
      const token = await AuthService.authenticate(user);
      localStorage.setItem("user", JSON.stringify({ ...token, ...user }));
      dispatch(loginUserSuccessAction({ ...token, ...user }));
    } catch (error) {
      dispatch(loginUserErrorAction(error));
    }
  };
};

export const logoutUserError = (error: any) => {
  return {
    type: LOGOUT_USER_ERROR,
    payload: error,
  };
};
export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
  };
};
export const logoutUserAction = () => {
  return async (dispatch: Dispatch) => {
    try {
      AuthService.logout();
      dispatch(logoutUserSuccess());
    } catch (error) {
      dispatch(logoutUserError(error));
    }
  };
};

export const requestfetchProjectCategoriesAction = () => {
  return {
    type: REQUEST_FETCH_PROJECT_CATEGORIES,
  };
};
export const fetchProjectCategoriesSuccessAction = (
  categories: ProjectCategory[]
) => {
  return {
    type: FETCH_PROJECT_CATEGORIES_SUCCESS,
    payload: categories,
  };
};
export const fetchProjectCategoriesErrorAction = (error: any) => {
  return {
    type: FETCH_PROJECT_CATEGORIES_ERROR,
    payload: error,
  };
};
export const fetchProjectCategories = (lang: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(requestfetchProjectCategoriesAction());
    try {
      const response = await CategoriesProjectService.getProjectCategories(
        lang
      );
      dispatch(fetchProjectCategoriesSuccessAction(response));
    } catch (error) {
      dispatch(fetchProjectCategoriesErrorAction(error));
    }
  };
};
export const fetchProjectCategoriesInput = (lang: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(requestfetchProjectCategoriesAction());
    try {
      const response = await CategoriesProjectService.getProjectCategoriesInput(
        lang
      );
      dispatch(fetchProjectCategoriesSuccessAction(response));
    } catch (error) {
      dispatch(fetchProjectCategoriesErrorAction(error));
    }
  };
};

export const requestFetchProjectsAction = () => {
  return {
    type: REQUEST_FETCH_PROJECTS,
  };
};
export const fetchProjectsErrorAction = (error: any) => {
  return {
    type: FETCH_PROJECTS_ERROR,
    payload: error,
  };
};
export const fetchProjectsSuccessAction = (projects: Project[]) => {
  return {
    type: FETCH_PROJECTS_SUCCESS,
    payload: projects,
  };
};
export const fetchPublishProjectsAction = (
  page: number,
  per_page: number,
  lang: string
) => {
  return async (dispatch: Dispatch) => {
    dispatch(requestFetchProjectsAction());
    try {
      const response = await ProjectService.getPublishProjects(
        page,
        per_page,
        lang
      );
      dispatch(fetchProjectsSuccessAction(response));
    } catch (error) {
      dispatch(fetchProjectsErrorAction(error));
    }
  };
};
export const requestFetchImages = () => {
  return {
    type: REQUEST_FETCH_IMAGES,
  };
};
export const fetchImagesSuccess = (images: Media[]) => {
  return {
    type: FETCH_IMAGES_SUCCESS,
    payload: images,
  };
};
export const fetchImagesError = (error: any) => {
  return {
    type: FETCH_IMAGES_ERROR,
    payload: error,
  };
};
export const fetchImages = (ids: number[]) => {
  return async (dispatch: Dispatch) => {
    dispatch(requestFetchImages());
    try {
      const response = await MediasService.getMediasByIds(ids);
      dispatch(fetchImagesSuccess(response));
    } catch (error) {
      dispatch(fetchImagesError(error));
    }
  };
};

export const requestFetchCommentsAction = () => {
  return {
    type: REQUEST_FETCH_COMMENTS,
  };
};
export const fetchCommentsSuccessAction = (comments: Comment[]) => {
  return {
    type: FETCH_COMMENTS_SUCCESS,
    payload: comments,
  };
};
export const fetchCommentsErrorAction = (error: any) => {
  return {
    type: FETCH_COMMENTS_ERROR,
    payload: error,
  };
};
export const setTotalCommentsAction = (total: number) => {
  return {
    type: SET_TOTAL_COMMENTS,
    payload: total,
  };
};
export const fetchCommentsAction = (
  page: number,
  per_page: number,
  lang: string
) => {
  return async (dispatch: Dispatch) => {
    dispatch(requestFetchCommentsAction());
    try {
      const response = await CommentService.getComments(page, per_page, lang);
      dispatch(fetchCommentsSuccessAction(response.data));
      dispatch(setTotalCommentsAction(response.totalRows));
    } catch (error) {
      dispatch(fetchCommentsErrorAction(error));
    }
  };
};
export const requestEditCommentAction = () => {
  return {
    type: REQUEST_EDIT_COMMENT,
  };
};
export const editCommentErrorAction = (error: any) => {
  return {
    type: EDIT_COMMENT_ERROR,
    payload: error,
  };
};
export const editCommentSuccessAction = (comment: Comment, id: number) => {
  return {
    type: EDIT_COMMENT_SUCCESS,
    payload: {
      comment,
      id,
    },
  };
};
export const tryEditCommentAction = (comment: Comment, id: number) => {
  return async (dispatch: Dispatch) => {
    dispatch(requestEditCommentAction());
    try {
      const data = await CommentService.editComment(comment, id);
      let createdAt = new Date();
      dispatch(editCommentSuccessAction({ ...data, createdAt }, id));
    } catch (error) {
      dispatch(editCommentErrorAction(error));
    }
  };
};

export const fetchPublishCommentsAction = (
  page: number,
  per_page: number,
  lang: string
) => {
  return async (dispatch: Dispatch) => {
    dispatch(requestFetchCommentsAction());
    try {
      const response = await CommentService.getPublishComments(
        page,
        per_page,
        lang
      );
      dispatch(fetchCommentsSuccessAction(response));
    } catch (error) {
      dispatch(fetchCommentsErrorAction(error));
    }
  };
};
export const setPerPageCommentsAction = (perPage: number) => {
  return {
    type: SET_PER_PAGE_COMMENTS,
    payload: perPage,
  };
};
export const requestAddCommentAction = () => {
  return {
    type: REQUEST_ADD_COMMENT,
  };
};
export const addCommentSuccessAction = (comment: Comment) => {
  return {
    type: ADD_COMMENT_SUCCESS,
    payload: comment,
  };
};
export const addCommentErrorAction = (error: any) => {
  return {
    type: ADD_COMMENT_ERROR,
    payload: error,
  };
};
export const TryAddCommentAction = (comment: Comment) => {
  return async (dispatch: Dispatch) => {
    dispatch(requestAddCommentAction());
    try {
      const res = await CommentService.addComment(comment);
      dispatch(addCommentSuccessAction(res));
    } catch (error) {
      dispatch(addCommentErrorAction(error));
    }
  };
};

export const requestDeleteCommentAction = () => {
  return {
    type: REQUEST_DELETE_COMMENT,
  };
};
export const deleteCommentSuccessAction = (id: number) => {
  return {
    type: DELETE_COMMENT_SUCCESS,
    payload: id,
  };
};
export const deleteCommentErrorAction = (error: any) => {
  return {
    type: DELETE_COMMENT_ERROR,
    payload: error,
  };
};
export const tryDeleteCommentAction = (id: number) => {
  return async (dispatch: Dispatch) => {
    dispatch(requestDeleteCommentAction());
    try {
      await CommentService.removeComment(id);
      dispatch(deleteCommentSuccessAction(id));
    } catch (error) {
      dispatch(deleteCommentErrorAction(error));
    }
  };
};
export const requestDeleteCommentsAction = () => {
  return {
    type: REQUEST_DELETE_COMMENTS,
  };
};
export const deleteCommentsErrorAction = (error: any) => {
  return {
    type: DELETE_COMMENTS_ERROR,
    payload: error,
  };
};
export const deleteCommentsSuccessAction = (
  comments: Comment[],
  listID: number[]
) => {
  return {
    type: DELETE_COMMENTS_SUCCESS,
    payload: { comments, listID },
  };
};
export const tryDeleteCommentsAction = (
  comments: Comment[],
  listID: number[]
) => {
  return async (dispatch: Dispatch) => {
    dispatch(requestDeleteCommentsAction());
    try {
      await CommentService.removeSelectedComments(listID);
      dispatch(deleteCommentsSuccessAction(comments, listID));
    } catch (error) {
      dispatch(deleteCommentErrorAction(error));
    }
  };
};

export const requestFetchQuestionsDevisAction = () => {
  return {
    type: REQUEST_FETCH_QUESTIONS_DEVIS,
  };
};
export const fetchQuestionsDevisErrorAction = (error: any) => {
  return {
    type: FETCH_QUESTIONS_DEVIS_ERROR,
    payload: error,
  };
};
export const fetchQuestionsDevisSuccessAction = (questions: any) => {
  return {
    type: FETCH_QUESTIONS_DEVIS_SUCCESS,
    payload: questions,
  };
};
export const setQuestionTotalItemsAction = (total: number) => {
  return {
    type: SET_QUESTION_TOTAL_ITEMS,
    payload: total,
  };
};
export const toggleQuestionsEditModeAction = (id: number) => {
  return {
    type: TOGGLE_QUESTIONS_EDIT_MODE,
    payload: id,
  };
};
export const toggleOptionEditModeAction = (id: number) => {
  return {
    type: TOGGLE_OPTION_EDIT_MODE,
    payload: id,
  };
};
export const fetchQuestionsDevisAction = (
  filter: string,
  sort: string,
  lang: string
) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(requestFetchQuestionsDevisAction());
    try {
      const response = await DevisService.getQuestionsDevis(filter, sort, lang);

      dispatch(fetchQuestionsDevisSuccessAction(response.data));
      dispatch(setQuestionTotalItemsAction(response.totalRows));
    } catch (error) {
      dispatch(fetchQuestionsDevisErrorAction(error));
    }
  };
};

export const requestFetchTypeQuestionsAction = () => {
  return {
    type: REQUEST_FETCH_TYPE_QUESTIONS,
  };
};
export const fetchTypeQuestionsErrorAction = (error: any) => {
  return {
    type: FETCH_TYPE_QUESTIONS_ERROR,
    payload: error,
  };
};
export const fetchTypeQuestionsSuccessAction = (questionsType: any) => {
  return {
    type: FETCH_TYPE_QUESTIONS_SUCCESS,
    payload: questionsType,
  };
};
export const fetchTypeQuestionsAction = (lang: string) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(requestFetchTypeQuestionsAction());
    try {
      const response = await DevisService.getTypeQuestions(lang);
      dispatch(fetchTypeQuestionsSuccessAction(response));
    } catch (error) {
      dispatch(fetchTypeQuestionsErrorAction(error));
    }
  };
};

export const requestAddTypeQuestionAction = () => {
  return {
    type: REQUEST_ADD_TYPE_QUESTION,
  };
};
export const addTypeQuestionErrorAction = (error: any) => {
  return {
    type: ADD_TYPE_QUESTION_ERROR,
    payload: error,
  };
};
export const addTypeQuestionSuccessAction = (typequestion: any) => {
  return {
    type: ADD_TYPE_QUESTION_SUCCESS,
    payload: typequestion,
  };
};
export const tryAddTypeQuestionAction = (typequestion: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(requestAddTypeQuestionAction());
    try {
      const res = await DevisService.addTypeQuestion(typequestion);
      dispatch(addTypeQuestionSuccessAction(res));
      dispatch(resetTypeQuestionAction());
    } catch (error) {
      dispatch(addTypeQuestionErrorAction(error));
    }
  };
};

export const toggleTypeQuestionsEditMode = (id: number) => {
  return {
    type: TOGGLE_TYPE_QUESTIONS_EDIT_MODE,
    payload: id,
  };
};

export const requestEditTypeQuestionImgAction = () => {
  return {
    type: REQUEST_EDIT_TYPE_QUESTION_IMG,
  };
};
export const editTypeQuestionImgSuccessAction = (id: number, img: any) => {
  return {
    type: EDIT_TYPE_QUESTION_IMG_SUCCESS,
    payload: { id, img },
  };
};
export const editTypeQuestionImgErrorAction = (error: any) => {
  return {
    type: EDIT_TYPE_QUESTION_IMG_ERROR,
    payload: error,
  };
};
export const tryEditTypeQuestionImgAction = (id: number, img: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(requestEditTypeQuestionImgAction());
    try {
      const res = await DevisService.editTypeQuestionImg(id, img);
      dispatch(editTypeQuestionImgSuccessAction(id, res));
    } catch (error) {
      dispatch(editTypeQuestionImgErrorAction(error));
    }
  };
};

export const requestEditTypeQuestionsAction = () => {
  return {
    type: REQUEST_EDIT_TYPE_QUESTIONS,
  };
};
export const editTypeQuestionsErrorAction = (error: any) => {
  return {
    type: EDIT_TYPE_QUESTIONS_ERROR,
    payload: error,
  };
};
export const editTypeQuestionsSuccessAction = (id: number, values: any) => {
  return {
    type: EDIT_TYPE_QUESTIONS_SUCCESS,
    payload: { id, values },
  };
};
export const tryEditTypeQuestionsAction = (id: number, values: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(requestEditTypeQuestionImgAction());
    try {
      const res = await DevisService.editTypeQuestion(id, values);
      dispatch(editTypeQuestionsSuccessAction(id, res));
    } catch (error) {
      dispatch(editTypeQuestionsErrorAction(error));
    }
  };
};

export const requestRemovetypeQuestionAction = () => {
  return {
    type: REQUEST_REMOVE_TYPE_QUESTION,
  };
};
export const removeTypeQuestionErrorAction = (error: any) => {
  return {
    type: REMOVE_TYPE_QUESTION_ERROR,
    payload: error,
  };
};
export const removeTypeQuestionSuccessAction = (id: number) => {
  return {
    type: REMOVE_TYPE_QUESTION_SUCCESS,
    payload: id,
  };
};

export const tryRemoveTypeQuestionAction = (id: number) => {
  return async (dispatch: Dispatch) => {
    dispatch(requestRemovetypeQuestionAction());
    try {
      await DevisService.removeTypeQuestion(id);
      dispatch(removeTypeQuestionSuccessAction(id));
    } catch (error) {
      dispatch(removeTypeQuestionErrorAction(error));
    }
  };
};
export const resetTypeQuestionAction = () => {
  return {
    type: RESET_TYPE_QUESTION,
  };
};
export const requestAddOptionAction = () => {
  return {
    type: REQUEST_ADD_OPTION,
  };
};
export const addOptionErrorAction = (error: any) => {
  return {
    type: ADD_OPTION_ERROR,
    payload: error,
  };
};
export const addOptionSuccessAction = (option: any) => {
  return {
    type: ADD_OPTION_SUCCESS,
    payload: option,
  };
};
export const tryAddOptionAction = (option: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(requestAddOptionAction());
    try {
      const res = await DevisService.addOption(option);
      dispatch(addOptionSuccessAction({ id: res.id, ...option }));
    } catch (error) {
      dispatch(addOptionErrorAction(error));
    }
  };
};

export const requestAddQuestionAction = () => {
  return {
    type: REQUEST_ADD_QUESTION,
  };
};
export const addQuestionErrorAction = (error: any) => {
  return {
    type: ADD_QUESTION_ERROR,
    payload: error,
  };
};
export const addQuestionSuccessAction = (question: any) => {
  return {
    type: ADD_QUESTION_SUCCESS,
    payload: question,
  };
};
export const tryAddQuestionAction = (question: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(requestAddQuestionAction());
    try {
      const res = await DevisService.addQuestion(question);
      dispatch(addQuestionSuccessAction(res));
    } catch (error) {
      dispatch(addQuestionErrorAction(error));
    }
  };
};
export const setOrderQuestionAction = (order: number) => {
  return {
    type: SET_ORDER_QUESTION,
    payload: order,
  };
};

export const requestEditQuestionAction = () => {
  return {
    type: REQUEST_EDIT_QUESTION,
  };
};
export const editQuestionErrorAction = (error: any) => {
  return {
    type: EDIT_QUESTION_ERROR,
    payload: error,
  };
};
export const editQuestionSuccessAction = (id: number, values: any) => {
  return {
    type: EDIT_QUESTION_SUCCESS,
    payload: { id, values },
  };
};
export const tryEditQuestionAction = (id: number, values: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(requestEditQuestionAction());
    try {
      const res = await DevisService.editQuestion(id, values);
      dispatch(editQuestionSuccessAction(id, res));
    } catch (error) {
      dispatch(editQuestionErrorAction(error));
    }
  };
};

export const requestRemoveQuestionAction = () => {
  return {
    type: REQUEST_REMOVE_QUESTION,
  };
};
export const removeQuestionErrorAction = (error: any) => {
  return {
    type: REMOVE_QUESTION_ERROR,
    payload: error,
  };
};
export const removeQuestionSuccessAction = (id: number) => {
  return {
    type: REMOVE_QUESTION_SUCCESS,
    payload: id,
  };
};
export const tryRemoveQuestionAction = (id: number) => {
  return async (dispatch: Dispatch) => {
    dispatch(requestRemoveQuestionAction());
    try {
      await DevisService.removeQuestion(id);
      dispatch(removeQuestionSuccessAction(id));
    } catch (error) {
      dispatch(removeQuestionErrorAction(error));
    }
  };
};

export const requestEditOptionAction = () => {
  return {
    type: REQUEST_EDIT_OPTION,
  };
};
export const editOptionErrorAction = (error: any) => {
  return {
    type: EDIT_OPTION_ERROR,
    payload: error,
  };
};
export const editOptionSuccessAction = (id: number, values: any) => {
  return {
    type: EDIT_OPTION_SUCCESS,
    payload: { id, values },
  };
};
export const tryEditOptionAction = (id: number, values: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(requestEditOptionAction());
    try {
      const res = await DevisService.editOption(id, values);
      dispatch(editOptionSuccessAction(id, res));
    } catch (error) {
      dispatch(editOptionErrorAction(error));
    }
  };
};
export const requestRemoveOptionAction = () => {
  return {
    type: REQUEST_REMOVE_OPTION,
  };
};
export const removeOptionErrorAction = (error: any) => {
  return {
    type: REMOVE_OPTION_ERROR,
    payload: error,
  };
};
export const removeOptionSuccessAction = (
  idQuestion: number,
  idOption: number
) => {
  return {
    type: REMOVE_OPTION_SUCCESS,
    payload: { idQuestion, idOption },
  };
};
export const tryRemoveOptionAction = (idQuestion: number, idOption: number) => {
  return async (dispatch: Dispatch) => {
    dispatch(requestRemoveOptionAction());
    try {
      await DevisService.removeOption(idOption);
      dispatch(removeOptionSuccessAction(idQuestion, idOption));
    } catch (error) {
      dispatch(removeOptionErrorAction(error));
    }
  };
};

export const requestAddDevisAction = (): RequestAddDevisAction => {
  return {
    type: REQUEST_ADD_DEVIS,
  };
};
export const addDevisSuccessAction = (devis: any): AddDevisSuccessAction => {
  return {
    type: ADD_DEVIS_SUCCESS,
    payload: devis,
  };
};
export const addDevisErrorAction = (error: any): AddDevisErrorAction => {
  return {
    type: ADD_DEVIS_ERROR,
    payload: error,
  };
};
export const tryAddDevisAction = (devis: Devis) => {
  return async (dispatch: Dispatch<DevisActionType>) => {
    dispatch(requestAddDevisAction());
    try {
      const res = await DevisService.addDevis(devis);
      dispatch(addDevisSuccessAction(res));
    } catch (error) {
      dispatch(addDevisErrorAction(error));
    }
  };
};

export const setIsSendDevisAction = (): SetIsSendDevisAction => {
  return {
    type: SET_IS_SEND_DEVIS,
  };
};

export const setFilterAction = (filter: string): SetDevisFilterAction => {
  return {
    type: SET_DEVIS_FILTER,
    payload: filter,
  };
};

export const requestDeleteDeviAction = (): RequestDeleteDeviAction => {
  return {
    type: REQUEST_DELETE_DEVI,
  };
};
export const deleteDeviSuccessAction = (
  id: number
): DeleteDeviSuccessAction => {
  return {
    type: DELETE_DEVI_SUCCESS,
    payload: id,
  };
};
export const deleteDeviErrorAction = (error: any): DeleteDeviErrorAction => {
  return {
    type: DELETE_DEVI_ERROR,
    payload: error,
  };
};
export const tryDeleteDeviAction = (id: number) => {
  return async (dispatch: Dispatch<DevisActionType>) => {
    dispatch(requestDeleteDeviAction());
    try {
      await DevisService.removeDevis(id);
      dispatch(deleteDeviSuccessAction(id));
    } catch (error) {
      dispatch(deleteDeviErrorAction(error));
    }
  };
};

export const requestDeleteDevisAction = (): RequestDeleteDevisAction => {
  return {
    type: REQUEST_DELETE_DEVIS,
  };
};
export const deleteDevisSuccessAction = (
  devis: Devis[],
  listID: number[]
): DeleteDevisSuccessAction => {
  return {
    type: DELETE_DEVIS_SUCCESS,
    payload: { devis, listID },
  };
};
export const deleteDevisErrorAction = (error: any): DeleteDevisErrorAction => {
  return {
    type: DELETE_DEVIS_ERROR,
    payload: error,
  };
};

export const tryDeleteDevisAction = (devis: Devis[], listID: number[]) => {
  return async (dispatch: Dispatch<DevisActionType>) => {
    dispatch(requestDeleteDevisAction());
    try {
      await DevisService.removeSelectedDevis(listID);
      dispatch(deleteDevisSuccessAction(devis, listID));
    } catch (error) {
      dispatch(deleteDevisErrorAction(error));
    }
  };
};

export const toggleEditModeAction = (id: number): ToggleDevisEditModeAction => {
  return {
    type: TOGGLE_DEVIS_EDIT_MODE,
    payload: id,
  };
};

export const requestEditDeviAction = (): RequestEditDeviAction => {
  return {
    type: REQUEST_EDIT_DEVI,
  };
};
export const editDevisSuccessAction = (
  devi: any,
  id: number
): EditDevisSuccessAction => {
  return {
    type: EDIT_DEVI_SUCCESS,
    payload: {
      devi,
      id,
    },
  };
};
export const editDevisErrorAction = (error: any): EditDevisErrorAction => {
  return {
    type: EDIT_DEVI_ERROR,
    payload: error,
  };
};
export const tryEditDeviAction = (id: number, data: any) => {
  return async (dispatch: Dispatch<DevisActionType>) => {
    dispatch(requestEditDeviAction());
    try {
      const res = await DevisService.editDevis(id, data);
      dispatch(editDevisSuccessAction(res, id));
    } catch (error) {
      dispatch(editDevisErrorAction(error));
    }
  };
};

export const setDevisPerPageAction = (
  perPage: number
): SetDevisPerPageAction => ({
  type: SET_DEVIS_PER_PAGE,
  payload: perPage,
});

export const requestDevisAction = (): RequestDevisAction => {
  return {
    type: REQUEST_DEVIS,
  };
};
export const fetchDevisSuccessAction = (
  devis: Estimate[]
): FetchDevisSuccessAction => {
  return {
    type: FETCH_DEVIS_SUCCESS,
    payload: devis,
  };
};
export const fetchDevisErrorAction = (error: any): FetchDevisErrorAction => {
  return {
    type: FETCH_DEVIS_ERROR,
    payload: error,
  };
};

export const setDevisTotalItemsAction = (
  total: number
): SetDevisTotalItemsAction => {
  return {
    type: SET_DEVIS_TOTAL_ITEMS,
    payload: total,
  };
};

export const fetchDevis = (
  page: number,
  perPage: number,
  currentlang: string,
  filter: string
) => {
  return async (dispatch: Dispatch<DevisActionType>) => {
    dispatch(requestDevisAction());
    try {
      const response = await DevisService.getAllDevis(
        page,
        perPage,
        currentlang,
        filter
      );
      dispatch(fetchDevisSuccessAction(response.data));
      dispatch(setDevisTotalItemsAction(response.totalRows));
    } catch (error) {
      dispatch(fetchDevisErrorAction(error));
    }
  };
};
